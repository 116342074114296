import { useEffect } from 'react';
import { useSessionStorage } from '../../../hooks';

/**
 * Custom hook for generating a breadcrumb trail based on the given folder.
 * @param {string} id - The unique identifier for the breadcrumb trail.
 * @param {import('@').StorageProviderItem} folder - The folder to generate the breadcrumb trail from.
 * @param {boolean} [disableCache] - Flag to disable caching data to session storage.
 * @returns {import('@').StorageProviderItem[]} The breadcrumb trail.
 */
export function useBreadcrumbTrail(id, folder, disableCache = false) {
  const [breadcrumbTrail, setBreadcrumbTrail] = useSessionStorage({
    noun        : 'breadcrumb-trail',
    id,
    initialValue: [],
    disableCache,
  });

  // Effect to update breadcrumb trail when folder changes
  useEffect(() => {
    // Clear breadcrumbs if folder is null and breadcrumbs exist
    if (!folder && breadcrumbTrail.length) {
      setBreadcrumbTrail([]);
      return;
    }

    // Update breadcrumbs if folder id changes
    if (folder?.id && folder?.id !== breadcrumbTrail[breadcrumbTrail.length - 1]?.id) {
      setBreadcrumbTrail((prevBreadcrumbTrail = []) => {
        const breadcrumbIndex = prevBreadcrumbTrail.findIndex(breadcrumb => breadcrumb?.id === folder?.id);
        // If folder is not found in the breadcrumb trail, add it; otherwise, truncate the trail
        const newBreadcrumbTrail =
          breadcrumbIndex === -1
            ? [...prevBreadcrumbTrail, folder]
            : prevBreadcrumbTrail.slice(0, breadcrumbIndex + 1);
        return newBreadcrumbTrail;
      });
    }
  }, [folder, breadcrumbTrail, setBreadcrumbTrail]);

  return breadcrumbTrail;
}
