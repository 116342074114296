import type { StorageProvider, StorageProviderKey } from './types';
import Storage from '../storage';

const STORAGE_PROVIDERS_KEY = 'mtb-cloud-storage-providers';

type ProviderStorageSlice = {
  defaultProvider: StorageProviderKey | null;
  providers: Record<StorageProviderKey, StorageProvider>;
};

export class ProviderStore {
  #storage = new Storage();

  private migrateOldStorage() {
    // TODO
  }

  constructor() {
    this.migrateOldStorage();
  }

  private getSlice(): ProviderStorageSlice {
    return (
      this.#storage.getItem(STORAGE_PROVIDERS_KEY) || {
        defaultProvider: null,
        providers      : {} as Record<StorageProviderKey, StorageProvider>,
      }
    );
  }

  private setSlice(slice: ProviderStorageSlice) {
    this.#storage.setItem(STORAGE_PROVIDERS_KEY, slice);
  }

  getProvider(key: StorageProviderKey): StorageProvider | null {
    const slice = this.getSlice();
    return slice.providers[key] || null;
  }

  setProvider(key: StorageProviderKey, provider: StorageProvider) {
    const slice = this.getSlice();
    slice.providers[key] = provider;
    this.setSlice(slice);
  }

  removeProvider(key: StorageProviderKey) {
    const slice = this.getSlice();
    delete slice.providers[key];
    this.setSlice(slice);
  }

  setDefaultProvider(key: StorageProviderKey) {
    const slice = this.getSlice();
    slice.defaultProvider = key;
    this.setSlice(slice);
  }

  getDefaultProvider(): StorageProviderKey | null {
    const slice = this.getSlice();
    return slice.defaultProvider || null;
  }

  removeDefaultProvider() {
    const slice = this.getSlice();
    slice.defaultProvider = null;
    this.setSlice(slice);
  }

  getProviders(): Record<StorageProviderKey, StorageProvider> {
    return this.getSlice().providers;
  }
}

const providerStorage = new ProviderStore();

export default providerStorage;
