// @ts-nocheck deepMerge is a highly-optimized function that ignores a lot of best practices in favor of performance.

import deepClone from './deepClone';

const DEFAULT_OPTIONS = {
  clone                     : true,
  replaceConflictsWithSource: true,
  verbose                   : false,
};

/**
 * @template Target, Source
 * @param {Target} target
 * @param {Source} source
 * @param {DEFAULT_OPTIONS} options
 * @returns {import('./types').MergeDeep<Target, Source, { recurseIntoArrays: true }>}
 */
export const deepMerge = (target, source, options = {}) => {
  if (!options._stack) {
    options = Object.assign({}, DEFAULT_OPTIONS, options);
    options._stack = new WeakMap();
  }
  // Protect against circular objects
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
  if (target && typeof target === 'object' && options._stack.has(target)) {
    return options._stack.get(target);
  }

  const targetType = Object.prototype.toString.call(target).slice(8, -1).toLowerCase();
  const sourceType = Object.prototype.toString.call(source).slice(8, -1).toLowerCase();
  if (targetType === sourceType) {
    switch (targetType) {
      case 'object':
      case 'array':
        const objectRet = options.clone ? deepClone(target, { verbose: options.verbose }) : target;
        if (target && typeof target === 'object' && !options._stack.has(target)) {
          options._stack.set(target, objectRet);
        }
        const targetKeys = [...Object.getOwnPropertyNames(target), ...Object.getOwnPropertySymbols(target)];
        const sourceKeys = [...Object.getOwnPropertyNames(source), ...Object.getOwnPropertySymbols(source)];
        const combinedObjKeys = Array.from(new Set([...targetKeys, ...sourceKeys]));
        combinedObjKeys.forEach(key => {
          if (sourceKeys.includes(key) && targetKeys.includes(key)) {
            objectRet[key] = deepMerge(target[key], source[key], options);
          } else if (sourceKeys.includes(key)) {
            objectRet[key] = options.clone ? deepClone(source[key], { verbose: options.verbose }) : source[key];
          }
        });
        return objectRet;
      case 'map':
        const mapRet = options.clone ? deepClone(target, { verbose: options.verbose }) : target;
        if (target && typeof target === 'object' && !options._stack.has(target)) {
          options._stack.set(target, mapRet);
        }
        const combinedMapKeys = Array.from(new Set([...target.keys(), ...source.keys()]));
        combinedMapKeys.forEach(key => {
          if (target.has(key) && source.has(key)) {
            mapRet.set(key, deepMerge(target.get(key), source.get(key), options));
          } else if (sourceKeys.includes(key)) {
            mapRet.set(
              key,
              options.clone ? deepClone(source.get(key), { verbose: options.verbose }) : source.get(key),
            );
          }
        });
        return mapRet;
      case 'set':
        const setRet = options.clone ? deepClone(target, { verbose: options.verbose }) : target;
        if (target && typeof target === 'object' && !options._stack.has(target)) {
          options._stack.set(target, setRet);
        }
        const targetArray = Array.from(setRet);
        const sourceArray = Array.from(options.clone ? deepClone(source, { verbose: options.verbose }) : source);
        setRet.clear();
        targetArray.forEach((value, index) => {
          const targetValueType = Object.prototype.toString.call(targetArray[index]).slice(8, -1).toLowerCase();
          const sourceValueType = Object.prototype.toString.call(sourceArray[index]).slice(8, -1).toLowerCase();
          if (targetValueType === sourceValueType) {
            setRet.add(deepMerge(targetArray[index], sourceArray[index], options));
          } else {
            setRet.add(targetArray[index]);
            setRet.add(sourceArray[index]);
          }
        });
        return setRet;
      default:
        break;
    }
  }

  if (!options.replaceConflictsWithSource) {
    return options.clone ? deepClone(target, { verbose: options.verbose }) : target;
  }
  return options.clone ? deepClone(source, { verbose: options.verbose }) : source;
};

export default deepMerge;
