export const STRING_FORMATS = /** @type {const} */ ({
  DATE           : 'date',
  DATETIME       : 'date-time',
  TIME           : 'time',
  EMAIL          : 'email',
  PASSWORD       : 'password',
  REGEX          : 'regex',
  UUID           : 'uuid',
  MATH_EXPRESSION: 'mathExpression',
  REFERENCE_REGEX: 'referenceRegex',
});

export const STRING_TRANSFORMS = /** @type {const} */ ({
  TRIM         : 'trim',
  TRIM_START   : 'trimStart',
  TRIM_LEFT    : 'trimLeft',
  TRIM_END     : 'trimEnd',
  TRIM_RIGHT   : 'trimRight',
  TO_LOWER_CASE: 'toLowerCase',
  TO_UPPER_CASE: 'toUpperCase',
});

/**
 * @typedef DefaultFieldOptions
 * @property {boolean} isHidden
 * @property {boolean} isRequired
 * @property {boolean} isReadOnly
 * @property {DefaultFieldOptions|StringFieldOptions|NumberFieldOptions} [not] Use this property to invert a conditon
 * @property {any} [const] Use this property for testing strict-equals
 * @property {any} [default]
 * @property {import('./keywords/dynamicDefaults').DynamicDefault} [dynamicDefault]
 * @property {import('./keywords/asyncDynamicDefaults').AsyncDynamicDefault} [asyncDynamicDefault]
 */

/** @type {DefaultFieldOptions} */
export const DEFAULT_FIELD_OPTIONS = {
  isHidden  : false,
  isRequired: false,
  isReadOnly: false,
};

/**
 * @typedef StringFieldOptions
 * @property {number|undefined} maxLength
 * @property {number|undefined} minLength
 * @property {string|undefined} pattern
 * @property {string|undefined} includes
 * @property {typeof STRING_FORMATS[keyof typeof STRING_FORMATS]|undefined} format
 * @property {string|undefined} formatMaximum
 * @property {string|undefined} formatMinimum
 * @property {string|undefined} formatExclusiveMaximum
 * @property {string|undefined} formatExclusiveMinimum
 * @property {string|undefined} stringMaximum
 * @property {string|undefined} stringMinimum
 * @property {string|undefined} stringExclusiveMaximum
 * @property {string|undefined} stringExclusiveMinimum
 * @property {(typeof STRING_TRANSFORMS[keyof typeof STRING_TRANSFORMS])[]|undefined} transform
 */

/**
 * @typedef {StringFieldOptions & DefaultFieldOptions} DefaultStringFieldOptions
 */

/** @type {DefaultStringFieldOptions} */
export const DEFAULT_STRING_OPTIONS = {
  ...DEFAULT_FIELD_OPTIONS,
  maxLength             : undefined,
  minLength             : undefined,
  pattern               : undefined,
  includes              : undefined,
  format                : undefined,
  formatMaximum         : undefined,
  formatMinimum         : undefined,
  formatExclusiveMaximum: undefined,
  formatExclusiveMinimum: undefined,
  stringMaximum         : undefined,
  stringMinimum         : undefined,
  stringExclusiveMaximum: undefined,
  stringExclusiveMinimum: undefined,
  transform             : undefined,
};

/**
 * @typedef NumberFieldOptions
 * @property {number|undefined} maximum
 * @property {number|undefined} minimum
 * @property {number|undefined} exclusiveMaximum
 * @property {number|undefined} exclusiveMinimum
 * @property {number|undefined} multipleOf
 * @property {boolean|undefined} strictNumeric
 */

/**
 * @typedef {NumberFieldOptions & DefaultFieldOptions} DefaultNumberFieldOptions
 */

/** @type {DefaultNumberFieldOptions} */
export const DEFAULT_NUMBER_OPTIONS = {
  ...DEFAULT_FIELD_OPTIONS,
  maximum         : undefined,
  minimum         : undefined,
  exclusiveMaximum: undefined,
  exclusiveMinimum: undefined,
  multipleOf      : undefined,
  strictNumeric   : true,
};

/**
 * @typedef DefaultFieldOptionsMap
 * @property {DefaultStringFieldOptions} string
 * @property {DefaultNumberFieldOptions} number
 * @property {DefaultNumberFieldOptions} integer
 * @property {DefaultFieldOptions} boolean
 * @property {DefaultFieldOptions} null
 * @property {DefaultFieldOptions} object
 * @property {DefaultFieldOptions} array
 */

/**
 * @typedef {keyof DefaultFieldOptionsMap} KeyOfDefaultFieldOptionsMap
 */

/**
 * @typedef {DefaultFieldOptionsMap[KeyOfDefaultFieldOptionsMap]} ValueOfDefaultFieldOptionsMap
 */

/** @type {DefaultFieldOptionsMap} */
export const DEFAULT_FIELD_OPTIONS_MAP = {
  string : DEFAULT_STRING_OPTIONS,
  number : DEFAULT_NUMBER_OPTIONS,
  integer: DEFAULT_NUMBER_OPTIONS,
  boolean: DEFAULT_FIELD_OPTIONS,
  null   : DEFAULT_FIELD_OPTIONS,
  object : DEFAULT_FIELD_OPTIONS,
  array  : DEFAULT_FIELD_OPTIONS,
};
