import platformCore from './core';

/**
 * The Platform class is the public interface we expose to consumers of the platform.
 * @deprecated createClient is exposed via the v3 init api using the given plan interface.
 * @todo Remove this class once everyone has switched to the v3 init-remote-module api or higher.
 */
class Platform {
  get Telemetry() {
    return {
      createClient: platformCore.createTelemetryClient.bind(platformCore),
    } as const;
  }
}

export const platform = new Platform();
export default platform;
