import { deepClone } from '@mtb/utilities';

/**
 * Rehydrate state is a class representing the rehydrate state a plane uses
 * to persist state between when a plane is unmounted and remounted.
 */
export class RehydrateState {
  #rehydrateState: unknown = undefined;

  /**
   * Get the value of a specific key from the state.
   * @returns The value of the key.
   */
  get(): unknown {
    return this.#rehydrateState;
  }

  /**
   * Set the rehydrate state.
   * @param value - The value to set.
   */
  set(value: unknown): void {
    if (value === undefined) {
      this.clear();
      return;
    }

    this.#rehydrateState = deepClone(value);
  }

  /**
   * Clears the rehydrate state.
   */
  clear() {
    this.#rehydrateState = undefined;
  }
}

export default RehydrateState;
