import React, { Children, useCallback, useMemo } from 'react';
import {
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mtb/ui';
import { useStorageExplorer } from '../../hooks';

export const CloudProviderCategoryButton = ({ children, title, category }) => {
  const child = Children.only(children);
  const { category: explorerCategory, setCategory } = useStorageExplorer();

  const isSelected = useMemo(() => category === explorerCategory, [category, explorerCategory]);
  const handleSetCategory = useCallback(() => setCategory(category), [category, setCategory]);

  return (
    <ListItemButton
      selected={isSelected}
      onClick={handleSetCategory}>
      <ListItemIcon>
        {child}
      </ListItemIcon>
      <ListItemText>
        <Typography
          noWrap
          variant="title-sm"
          weight="regular">
          {title}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};
