import type { AsyncUserStateThunkState } from './types';
import platformCore from '../../../..';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';

export const fetchSubscriptions = createAsyncThunk.withTypes<AsyncUserStateThunkState>()(
  'user/fetchSubscriptions',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const subscriptions = await platformCore.AuthenticationClient.getSubscriptions();
      return fulfillWithValue({ subscriptions });
    } catch (error) {
      return rejectWithValue({ error });
    }
  },
);
