import { createElement } from 'react';
import CenteredCircularProgress from '../../utils/centered-circular-progress';
import withErrorBoundary from '../../utils/error-boundary';
import lazy from '../../utils/lazy';
import createIntegratedRemoteModuleLoader from '../../utils/load-remote-module';
import remoteModuleUrlConfigs from '../../utils/remote-url-configs';
import withSuspense from '../../utils/with-suspense';

const loadRemoteMinitabOnline = createIntegratedRemoteModuleLoader('mtb_msso', 'MSSO', remoteModuleUrlConfigs.MSSO);

const LazyRemoteMinitabOnline = lazy(loadRemoteMinitabOnline);

const RemoteMinitabOnline = withErrorBoundary(
  withSuspense(LazyRemoteMinitabOnline, () => createElement(CenteredCircularProgress)),
  'RemoteMinitabOnline',
);

export { RemoteMinitabOnline, loadRemoteMinitabOnline };
