import type { OnChange, UnsubscribeFn } from './types';

export class StoreObservable {
  #listeners: Set<OnChange> = new Set();

  constructor() {
    window.addEventListener('storage', () => this.notify());
  }

  notify() {
    this.#listeners.forEach((listener) => listener());
  }

  subscribe(listener: OnChange): UnsubscribeFn {
    this.#listeners.add(listener);
    return () => {
      this.#listeners.delete(listener);
    };
  }
}

const observable = new StoreObservable();

export default observable;
