import React from 'react';
import { Popover, Stack } from '@mtb/ui';
import { useStorageProjectMenu } from '../hooks';

export function StorageProjectMenuContent({ children, ...other }) {
  const { menuProps } = useStorageProjectMenu();
  const {
    menuProps: { MenuListProps, ...otherMenuProps },
  } = menuProps;

  return (
    <Popover
      {...otherMenuProps}
      anchorOrigin={{
        vertical  : 'bottom',
        horizontal: 'center',
      }}
      SurfaceProps={{
        ...MenuListProps,
        rounded: true,
        sx     : {
          width: 320,
          p    : 2,
        },
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical  : 'top',
      }}
      {...other}>
      <Stack
        data-testid="storage-project-menu-content"
        gap={1}>
        {children}
      </Stack>
    </Popover>
  );
}
