import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { STRING_FORMATS } from '../constants';

dayjs.extend(customParseFormat);

const dateTimeSeperators = /** @type {const} */ ([' ', '[T]']);
const timeZomeFormats = /** @type {const} */ (['', '[Z]']);
const isoFormats = dateTimeSeperators.flatMap(sep =>
  timeZomeFormats.flatMap(
    tz => /** @type {const} */ ([`YYYY-MM-DD${sep}HH:mm:ss${tz}`, `YYYY-MM-DD${sep}HH:mm:ss.SSS${tz}`]),
  ),
);
const timeFormats = timeZomeFormats.flatMap(tz => /** @type {const} */ ([`HH:mm:ss${tz}`, `HH:mm:ss.SSS${tz}`, `H:mm:ss${tz}`, `H:mm:ss.SSS${tz}`]));
const validDateTimeFormats = /** @type {const} */ ({
  [STRING_FORMATS.DATE]    : ['YYYY-MM-DD', ...isoFormats],
  [STRING_FORMATS.DATETIME]: [...isoFormats],
  [STRING_FORMATS.TIME]    : ['HH:mm', 'H:mm', ...timeFormats, ...isoFormats],
});

const dateFormats = Object.entries(validDateTimeFormats).map(
  /**
   * @returns {[string, import('ajv').FormatDefinition<string>]} param0
   */
  ([format, validFormats]) => [
    format,
    {
      type: 'string',
      /**
       * @param {unknown} str
       */
      validate(str) {
        if (typeof str !== 'string') {
          return false;
        }
        return dayjs(str, [...validFormats], true).isValid();
      },
      /**
       * @param {string} a
       * @param {string} b
       */
      compare(a, b) {
        if (!a || !b) {
          return undefined;
        }
        if (dayjs(a, [...validFormats], true).isAfter(dayjs(b, [...validFormats], true))) {
          return 1;
        }
        if (dayjs(a, [...validFormats], true).isBefore(dayjs(b, [...validFormats], true))) {
          return -1;
        }
        return 0;
      },
    },
  ],
);

export default dateFormats;
