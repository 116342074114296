import { DEFAULT_FIELD_OPTIONS_MAP, DEFAULT_STRING_OPTIONS, DEFAULT_NUMBER_OPTIONS } from './constants';
import Field from './Field';
import FieldGroup from './FieldGroup';

export class FieldSet extends Field {
  /**
   *****************************************************************************
   * Protected Properties
   *****************************************************************************
   */
  _itemsLength = 0;

  /** @type {Map<number, FieldGroup>} */
  _fieldGroups = new Map();

  /** @type {Map<number, FieldSet>} */
  _fieldSets = new Map();

  /**
   *****************************************************************************
   * Public Methods
   *****************************************************************************
   */
  /**
   * Creates a new field set object
   * @param {import('./constants').ValueOfDefaultFieldOptionsMap} [options]
   */
  constructor(options = undefined) {
    const fieldOptions = Object.assign({ isFieldSet: true }, DEFAULT_FIELD_OPTIONS_MAP['array'], options ?? {});
    super('array', fieldOptions);
  }

  /**
   * Base function for adding any kind of field.
   * @param {import('./constants').KeyOfDefaultFieldOptionsMap} [type]
   * @param {import('./constants').ValueOfDefaultFieldOptionsMap} [options]
   */
  addField(type = 'object', options = undefined) {
    const field = new Field(type, options);
    field._root = this._root;
    if (this._schema?.items && field._schema) {
      this._schema.items[this._itemsLength++] = field._schema;
      this._schema.minItems = this._itemsLength;
    }
    return this;
  }

  /**
   * Add a single string field to the schema
   * @param {import('./constants').DefaultStringFieldOptions} [options]
   */
  addStringField(options) {
    return this.addField('string', Object.assign({}, DEFAULT_STRING_OPTIONS, options));
  }

  /**
   * Add a single numeric field to the schema
   * @param {boolean} integersOnly
   * @param {import('./constants').DefaultNumberFieldOptions} [options]
   */
  addNumericField(integersOnly, options) {
    return this.addField(integersOnly ? 'integer' : 'number', Object.assign({}, DEFAULT_NUMBER_OPTIONS, options));
  }

  /**
   * Add a single date/time field to the schema.
   * Date/Time/DateTime follows [RFC3339](https://www.rfc-editor.org/rfc/rfc3339#section-5.6),
   * e.g., a valid date-time string would be: `2022-11-30 18:45:55`. You can also
   * pass the result of `Date.prototype.toISOString` for a date-time value. Or
   * pass either the date (`Date.prototype.toISOString.split('T')[0]`)
   * or time (`Date.prototype.toISOString.split('T')[1]`).
   * @param {'date'|'date-time'|'time'} dateType
   * @param {import('./constants').DefaultStringFieldOptions} [options]
   */
  addDateTimeField(dateType = 'date', options) {
    return this.addStringField(Object.assign({}, DEFAULT_STRING_OPTIONS, options, { format: dateType }));
  }

  /**
   * Adds a new field group to the schema
   * @param {import('./constants').DefaultFieldOptions} [options]
   */
  addFieldGroup(options) {
    const fieldGroup = new FieldGroup(options);
    fieldGroup._root = this._root;
    if (this._schema?.items && fieldGroup._schema) {
      this._schema.items[this._itemsLength++] = fieldGroup._schema;
      this._schema.minItems = this._itemsLength;
      this._fieldGroups.set(this._itemsLength - 1, fieldGroup);
    }
    return this;
  }

  /**
   * Gets a field group by index
   * @param {number} index
   * @returns {FieldGroup|undefined}
   */
  getFieldGroup(index) {
    if (!this._fieldGroups.has(index)) {
      return;
    }
    return this._fieldGroups.get(index);
  }

  /**
   * Adds a new field set to the schema
   * @param {import('./constants').DefaultFieldOptions} [options]
   */
  addFieldSet(options) {
    const fieldSet = new FieldSet(options);
    fieldSet._root = this._root;
    if (this._schema?.items && fieldSet._schema) {
      this._schema.items[this._itemsLength++] = fieldSet._schema;
      this._schema.minItems = this._itemsLength;
      this._fieldSets.set(this._itemsLength - 1, fieldSet);
    }
    return this;
  }

  /**
   * Gets a field set by index
   * @param {number} index
   * @returns {FieldSet|undefined}
   */
  getFieldSet(index) {
    if (!this._fieldSets.has(index)) {
      return;
    }
    return this._fieldSets.get(index);
  }
}

export default FieldSet;
