import React from 'react';
import { TableBody } from '@mtb/ui';
import { useStorageExplorerTable } from '../context';
import { StorageExplorerTableRow } from '../StorageExplorerTableRow';

export function StorageExplorerTableBody() {
  const { rows } = useStorageExplorerTable();
  return (
    <TableBody data-testid="storage-explorer-table-body">
      {rows.map(row => (
        <StorageExplorerTableRow
          key={row.id}
          row={row} />
      ))}
    </TableBody>
  );
}
