import { Fragment, useCallback, useRef } from 'react';
import { Fade, Skeleton, TableCell, TableRow, useEventCallback, useInView } from '@mtb/ui';
import { useStorageExplorerItem } from '../../hooks';
import { useStorageExplorerTable } from '../context';

export const StorageExplorerTableRow = ({ row, children, ...other }) => {
  const { columns, tableContainerRef, isLoading } = useStorageExplorerTable();
  const item = useStorageExplorerItem(row);
  const tableRowRef = useRef(null);
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    root       : tableContainerRef.current,
  });
  const actions = useRef();
  const SkeletonRoot = useCallback(
    ({ disableSkeleton = false, ...props }) =>
      isLoading && !disableSkeleton ? (
        <Skeleton
          data-testid={`storage-explorer-table-row-skeleton-${row.id}`}
          variant="rect"
          {...props} />
      ) : (
        <Fragment {...props} />
      ),
    [isLoading, row.id],
  );

  const handleTableRowRef = useEventCallback(node => {
    if (!tableRowRef.current) {
      inViewRef(node);
      tableRowRef.current = node;
    }
    return node;
  });

  const handleOnKeyDown = useCallback(
    event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        item.open();
      }
    },
    [item],
  );

  const handleOnClick = useCallback(
    event => {
      switch (event.detail) {
        // Single Click
        case 1:
          item.select();
          break;
        // Double Click
        default:
          item.open();
          break;
      }
    },
    [item],
  );

  return (
    <Fade in={inView}>
      <TableRow
        ref={handleTableRowRef}
        data-testid="storage-explorer-table-row"
        hover
        selected={item?.isSelected}
        tabIndex={0}
        onClick={handleOnClick}
        onKeyDown={handleOnKeyDown}
        {...other}>
        {children}
        {!children &&
          columns.map(column => (
            <TableCell
              key={column.id}
              align={column.align}
              sx={{
                height: 40,
                ...(isLoading && { pointerEvents: 'none' }),
                ...(column.width && { width: column.width }),
              }}>
              <SkeletonRoot disableSkeleton={column.disableSkeleton}>
                {typeof column.render === 'function'
                  ? column.render({
                    ...item,
                    actions,
                    inView,
                  })
                  : column.render}
              </SkeletonRoot>
            </TableCell>
          ))}
      </TableRow>
    </Fade>
  );
};
