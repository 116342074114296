import React from 'react';
import { Button } from '@mtb/ui';
import { useCloudStorageStore } from '../../hooks/useCloudStorageStore';
import { useTranslation } from '../../services/i18n';

export const SignOutButton = () => {
  const [t] = useTranslation('connection');
  const { removeProvider } = useCloudStorageStore();

  return (
    <Button
      data-testid="sign-out-button"
      variant="highlight"
      onClick={removeProvider}>
      {t('connection.signOut')}
    </Button>
  );
};
