import { v4 as uuid, v5 as namespaced_uuid } from 'uuid';
import ajv from './ajv';
import FieldGroup from './FieldGroup';
import FieldSet from './FieldSet';
import applyI18nKeys from './i18nUtilities';

export class Schema extends FieldGroup {
  /**
   *****************************************************************************
   * Protected Properties
   *****************************************************************************
   */

  /** @type {string} */
  _id = '';

  /** @type {boolean} */
  _isAsync = false;

  /** @type {boolean} */
  _hasDynamicDefaults = false;

  /** @type {import('ajv').AnySchemaObject} */
  get _options() {
    return {
      $async: this._isAsync,
      $id   : this._validatorId,
    };
  }

  get _validatorId() {
    return namespaced_uuid(JSON.stringify(this._schema), this._id);
  }

  get _jsonSchema() {
    return { ...this._options, ...this._schema };
  }

  /** @type {Map<string, import('ajv/dist/core').AnyValidateFunction<"object">>} */
  _validators = new Map();

  /**
   *****************************************************************************
   * Public Methods
   *****************************************************************************
   */

  constructor() {
    super();
    this._root = this;
    this._id = uuid();
    this._validators = new Map();
  }

  /**
   * Validate a set of values against the schema.
   * @param {{}} values
   * @returns {{ valid: boolean, errors: import('./i18nUtilities').ErrorObject[] }}
   */
  validate(values) {
    /** @type {any} */
    const validatorInner = (() => {
      let validatorInner = this._validators.get(this._validatorId);
      if (validatorInner) {
        return validatorInner;
      }
      validatorInner = ajv.compile(this._jsonSchema);
      this._validators.set(this._validatorId, validatorInner);
      return validatorInner;
    })();

    validatorInner.errors = [];
    let valid = validatorInner(values);
    if (this._hasDynamicDefaults && !valid) {
      valid = validatorInner(values);
    }
    if (validatorInner.errors) {
      applyI18nKeys(validatorInner.errors);
    }

    return {
      valid : valid ?? false,
      errors: validatorInner.errors ?? [],
    };
  }

  /**
   * Creates a new schema
   * @returns {Schema}
   */
  static create() {
    return new Schema();
  }

  /**
   * Add a custom dynamic default function to be used in the schema.
   *
   * **WARNING** Dynamic defaults have to be added prior to validation,
   * meaning that if loading a schema from JSON, you will have to also re-add
   * the dynamic default function. Also, there can only ever be one function
   * registered to a name **GLOBALLY**, so make sure your names do not conflict.
   * @param {string} name
   * @param {import('./keywords/dynamicDefaults').DynamicDefaultFunc} func
   * @example
   * schema.addDynamicDefaultForField(
   *   'my_function_identifier',
   *   ({ data }) => () => {
   *     return getValue(data);
   *   },
   * );
   *
   * ...
   *
   * schema.addStringField('user_name', {
   *   dynamicDefault: {
   *     func: 'my_function_identifier',
   *     args: { data: 'somedata' },
   *   },
   * });
   */
  addDynamicDefaultForField(name, func) {
    ajv.DynamicDefaults.DEFAULTS[name] = func;
  }

  /**
   * Serializes the schema to JSON
   * @param {boolean} indent
   * @returns {string}
   */
  toJSON(indent = false) {
    let transform = (/** @type {{}} */obj) => obj;
    // Undocumented argument for transforming the JSON
    if (typeof arguments[1] === 'function') {
      transform = arguments[1];
    }
    return JSON.stringify(transform(this._jsonSchema), null, indent ? 2 : 0);
  }

  /**
   * Deserializes a schema from JSON
   * @param {string} schemaString
   * @returns {Schema}
   */
  static fromJSON(schemaString) {
    const parsed = JSON.parse(schemaString);
    const schema = new Schema();
    schema._isAsync = parsed.$async ?? false;

    /**
     * @param {FieldGroup|FieldSet|undefined} fieldGroup
     * @param {any} parsed
     */
    const recursivelyAddFieldGroups = (fieldGroup, parsed) => {
      if (!fieldGroup) {
        return;
      }
      if (fieldGroup instanceof FieldSet) {
        for (let index = 0; index < parsed.items.length; index++) {
          const { type, $comment, ...options } = parsed.items[index];
          const metaProperties = $comment ? JSON.parse($comment) : {};
          if (type !== 'object' && type !== 'array' && type !== 'null') {
            fieldGroup.addField(type, { ...options, ...metaProperties });
          } else if (type === 'array') {
            fieldGroup.addFieldSet({ ...metaProperties });
            recursivelyAddFieldGroups(fieldGroup.getFieldSet(index), options);
          } else if (type === 'object') {
            fieldGroup.addFieldGroup({ ...metaProperties });
            recursivelyAddFieldGroups(fieldGroup.getFieldGroup(index), options);
          }
        }
      } else {
        for (const [key, { type, $comment, ...options }] of Object.entries(parsed.properties)) {
          const metaProperties = $comment ? JSON.parse($comment) : {};
          if (type !== 'object' && type !== 'array') {
            fieldGroup.addField(key, type, { ...options, ...metaProperties });
          } else if (type === 'array') {
            fieldGroup.addFieldSet(key, { ...metaProperties });
            recursivelyAddFieldGroups(fieldGroup.getFieldSet(key), options);
          } else {
            fieldGroup.addFieldGroup(key, { ...metaProperties });
            recursivelyAddFieldGroups(fieldGroup.getFieldGroup(key), options);
          }
        }
      }
    };

    recursivelyAddFieldGroups(schema, parsed);
    return schema;
  }
}

export default Schema;
