import React, { useMemo, createElement } from 'react';
import { Stack, Typography } from '@mtb/ui';
import { STORAGE_PROVIDERS, SIZE_MAP } from '../../constants';
import { useCloudStorageStore } from '../../hooks/useCloudStorageStore';
import { useTranslation } from '../../services/i18n';

/**
 * `StorageProviderLogo` component.
 *
 * @param {Object} props - Component props.
 * @param {string} [props.size='lg'] - Size of the logo. Can be 'sm', 'md', 'lg'.
 * @param {string} props.provider - The provider of the storage.
 *
 * @returns {import('react').ReactElement} The rendered `StorageProviderLogo` component.
 */
export const StorageProviderLogo = ({ size: sizeProp = 'lg', provider }) => {
  const [t] = useTranslation('connection');
  const { type } = useCloudStorageStore();
  const storageProvider = useMemo(() => STORAGE_PROVIDERS[provider || type], [provider, type]);
  const size = useMemo(() => SIZE_MAP[sizeProp] || SIZE_MAP.lg, [sizeProp]);
  const gap = useMemo(() => (size === 'small' ? 1 : 2), [size]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      flexWrap="nowrap"
      gap={gap}
      mr={1}>
      {createElement(storageProvider.icon, { size, 'data-testid': 'storage-provider-icon' })}
      <Typography
        data-testid="storage-provider-name"
        noWrap
        variant={`title-${sizeProp}`}
        weight="regular">
        {t(storageProvider.name)}
      </Typography>
    </Stack>
  );
};
