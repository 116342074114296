import { useCallback, useMemo, useRef } from 'react';
import { useCloudStorageStore } from './useCloudStorageStore';
import { useSessionStorage } from './useSessionStorage';

/**
 * Custom hook for handling multiple fetches, but only using the latest one.
 * @param {import('@').UseLatestFetchParams} params
 * @returns {import('@').UseLatestFetchResult}
 */
export function useLatestFetch({ noun, id, initialValue: initialValueProp, fetchFn, disableCache = false } = {}) {
  const cloudStorage = useCloudStorageStore();
  const requestController = useRef(new AbortController());
  const requestProviderType = useRef(cloudStorage.type);
  const initialValue = useRef(initialValueProp);
  const initialState = useMemo(
    () => ({
      isFetching: false,
      hasFetched: false,
      data      : initialValue.current,
    }),
    [],
  );

  const [state, setState] = useSessionStorage({
    noun,
    id,
    initialValue: initialState,
    disableCache,
  });
  const clear = useCallback(() => {
    requestController.current.abort();
    setState(initialState);
  }, [initialState, setState]);
  const cancel = useCallback(() => {
    requestController.current.abort();
  }, []);

  const fetch = useCallback(
    async (...args) => {
      requestController.current.abort();
      const thisRequestController = new AbortController();
      requestController.current = thisRequestController;
      requestProviderType.current = cloudStorage.type;
      setState(prevState => {
        return { ...prevState, isFetching: true };
      });
      const result = await fetchFn(...args, requestController.current.signal);

      if (cloudStorage.type !== requestProviderType.current || thisRequestController.signal.aborted) {
        return;
      }

      setState(prevState => {
        return {
          ...prevState,
          isFetching: false,
          hasFetched: true,
          type      : cloudStorage.type,
          data      : result,
        };
      });
    },
    [cloudStorage.type, fetchFn, setState],
  );

  return {
    fetch,
    clear,
    cancel,
    type      : state.type,
    isFetching: state.isFetching,
    hasFetched: state.hasFetched,
    data      : state.data,
  };
}
