import React, { useCallback, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CloseIcon,
  Button,
  Spacer,
  Tooltip,
  Stack,
  Typography,
  Link,
  useEventCallback,
} from '@mtb/ui';
import { LoadingButton } from '../../components';
import { useCloudStorageStore, useCloudStorageProject } from '../../hooks';
import configStore from '../../services/config';
import { Trans, useTranslation } from '../../services/i18n';

/**
 * @param {Object} props
 * @param {import('@').CloudStorageProjectId} props.projectId
 * @param {() => void} props.onReopenProject Callback to reopen the project in the web app
 * @param {boolean} props.open Whether the dialog is open
 * @param {(tryAgain?: boolean) => void} props.onClose Callback to close the dialog
 */
export function OpenInDesktopDialog({ projectId, open, onClose, onReopenProject }) {
  const [t] = useTranslation();
  const cloudStorage = useCloudStorageStore();
  const project = useCloudStorageProject(projectId);

  const [isTryingReopen, setIsTryingReopen] = useState(false);

  const fileName = useMemo(() => project?.displayName, [project?.displayName]);

  const handleOnClose = useCallback(() => onClose(false), [onClose]);
  const handleOnReopen = useEventCallback(
    async () => {
      setIsTryingReopen(true);
      const checkoutUser = await project.getCheckoutUser();
      if (!checkoutUser) {
        onReopenProject();
      } else {
        // don't await the reopen failed dialog
        cloudStorage.reopenProjectFailed({ projectId, onReopenProject });
      }
      setIsTryingReopen(false);
      onClose(false);
    },
    [project.getCheckoutUser, setIsTryingReopen, onClose, onReopenProject],
  );

  const titleText = useMemo(
    () => (
      configStore.config.feature_flag_edit_readonly_projects ? t('connection.openInDesktopAppDialog.title') : t('connection.openInDesktopAppDialog.titleV1', { fileName })
    ), [t, fileName],
  );
  const closeButtonText = useMemo(
    () => (
      configStore.config.feature_flag_edit_readonly_projects ? t('connection.close') : t('connection.ok')
    ), [t],
  );
  const closeButtonColor = useMemo(
    () => (
      configStore.config.feature_flag_edit_readonly_projects ? 'default' : 'primary'
    ), [],
  );

  return (
    <Dialog
      data-testid="open-in-desktop-dialog"
      maxWidth="sm"
      open={open}
      onClose={handleOnClose}>
      <DialogTitle>
        {titleText}
        <Spacer />
        <Tooltip
          placement="bottom"
          title={t('connection.close')}>
          <Button
            color="default"
            icon={<CloseIcon />}
            variant="text"
            onClick={handleOnClose} />
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Typography>
            <Trans
              components={[
                <Link
                  href='https://support.minitab.com/installation/frequently-asked-questions/other/determine-latest-version-desktop-software/'
                  rel="noopener noreferrer"
                  sx={{ display: 'inline', verticalAlign: 'inherit', whiteSpace: 'normal' }}
                  target="_blank" />,
              ]}
              i18nKey="connection.openInDesktopAppDialog.installationDescription" />
          </Typography>
          <Typography>
            <Typography>{t('connection.openInDesktopAppDialog.downloadDescription')}</Typography>
            <Link
              href='https://www.minitab.com/support/downloads/'
              rel="noopener noreferrer"
              sx={{ width: 'min-content', justifyContent: 'flex-start', verticalAlign: 'inherit' }}
              target="_blank">
              {t('connection.openInDesktopAppDialog.downloadLinkText')}
            </Link>
          </Typography>
          {configStore.config.feature_flag_edit_readonly_projects && (
            <Typography>{t('connection.openInDesktopAppDialog.reopenDescription')}</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {configStore.config.feature_flag_edit_readonly_projects && (
          <LoadingButton
            loading={isTryingReopen}
            variant="contained"
            onClick={handleOnReopen}>
            {t('connection.reopenInWebApp')}
          </LoadingButton>
        )}
        <Button
          color={closeButtonColor}
          variant="contained"
          onClick={handleOnClose}>
          {closeButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OpenInDesktopDialog;
