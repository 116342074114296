import ajv from './ajv';
import applyI18nKeys from './i18nUtilities';
import Schema from './Schema';

// The inheritance here is just to inform type definitions
export class AsyncSchema extends Schema {
  /** @type {Map<string, import('ajv/dist/core').AnyValidateFunction<"object">>} */
  // @ts-ignore
  _validators = new Map();

  constructor() {
    super();
    this._isAsync = true;
  }

  /**
   * Validate a set of values against the schema.
   * @param {{}} values
   * @returns {Promise<{ valid: boolean, errors: import('./i18nUtilities').ErrorObject[] }>}
   */
  // @ts-ignore
  async validate(values) {
    /** @type {any} */
    const validatorInner = (() => {
      let validatorInner = this._validators.get(this._validatorId);
      if (validatorInner) {
        return validatorInner;
      }
      validatorInner = ajv.compile(this._jsonSchema);
      this._validators.set(this._validatorId, validatorInner);
      return validatorInner;
    })();

    let valid;
    /** @type {any} */
    let errors;
    try {
      await validatorInner(values).catch((/** @type {any} */ error) => {
        if (error.errors && this._hasDynamicDefaults) {
          return validatorInner(values);
        }
        throw error;
      });
      valid = true;
      errors = [];
    } catch (/** @type {any} */ error) {
      if (error.errors) {
        valid = false;
        applyI18nKeys(error.errors);
        errors = error.errors;
      } else {
        throw error;
      }
    }
    return { valid, errors };
  }

  /**
   * Creates a new async schema
   * @returns {AsyncSchema}
   */
  static create() {
    return new AsyncSchema();
  }

  /**
   * Add a custom async dynamic default function to be used in the schema.
   *
   * **WARNING** Dynamic defaults have to be added prior to validation,
   * meaning that if loading a schema from JSON, you will have to also re-add
   * the dynamic default function. Also, there can only ever be one function
   * registered to a name **GLOBALLY**, so make sure your names do not conflict.
   * @param {string} name
   * @param {import('./keywords/asyncDynamicDefaults').AsyncDynamicDefaultFunc} func
   * @example
   * asyncSchema.addAsyncDynamicDefaultForField(
   *   'my_function_identifier',
   *   ({ data, options }) => async () => {
   *     return await getUserNameFromApi(data, options);
   *   },
   * );
   *
   * ...
   *
   * asyncSchema.addStringField('user_name', {
   *   asyncDynamicDefault: {
   *     func: 'my_function_identifier',
   *     args: { data: 'somedata', options: 'someoptions' },
   *   },
   * });
   */
  addAsyncDynamicDefaultForField(name, func) {
    ajv.AsyncDynamicDefaults.ASYNC_DEFAULTS[name] = func;
  }
}

export default AsyncSchema;
