import {
  FileSearch,
  FileUploadButton,
  SaveToButton,
  SignOutButton,
  StorageProviderLogo,
  useStorageExplorer,
  useStorageExplorerItem,
  useStorageExplorerItems,
  StorageProjectMenu,
  StorageProjectMenuButton,
  StorageProjectMenuContent,
  StorageProjectMenuName,
  StorageProjectMenuAutoSave,
  StorageProjectMenuOpenInDesktop,
  StorageProjectMenuReadonlyInfo,
  useStorageProjectMenu,
} from './components';
import {
  StorageExplorer,
  StorageExplorerProviderList,
  StorageExplorerProviderSettings,
  StorageExplorerBreadcrumbs,
  StorageExplorerToolbar,
  StorageExplorerTable,
  StorageExplorerTableContainer,
} from './components/StorageExplorer';
import {
  StorageExplorer as StorageExplorerV1,
  StorageExplorerBackButton as StorageExplorerBackButtonV1,
  StorageExplorerBreadcrumbs as StorageExplorerBreadcrumbsV1,
  StorageExplorerFooter as StorageExplorerFooterV1,
  StorageExplorerHeader as StorageExplorerHeaderV1,
  StorageExplorerTable as StorageExplorerTableV1,
  StorageExplorerTableBody as StorageExplorerTableBodyV1,
  StorageExplorerTableHead as StorageExplorerTableHeadV1,
  StorageExplorerToolbar as StorageExplorerToolbarV1,
} from './components/StorageExplorerV1';
import {
  STORAGE_PROVIDERS,
  STORAGE_PROVIDER_KEYS,
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  PROJECT_STATUS,
  DEFAULT_PROVIDERS,
  DEFAULT_FILTER,
  DEFAULT_MAX_SIZE,
} from './constants';
import {
  useCloudStorageProject,
  useCloudStorageStore,
  useAllCloudStorageItems,
  useRecentCloudStorageItems,
  useSharedCloudStorageItems,
} from './hooks';
import { ChangeStorageProviderMenu } from './menus';
import i18n, { changeLanguage, init18n, useTranslation } from './services/i18n';
import providerService from './services/providers/index.ts';
import CloudStorage from './services/store';
import { getNameParts } from './utils';

const useCloudStorage = useCloudStorageStore;

const CloudStorageApi = {
  Provider: providerService,
};

export {
  // Constants
  STORAGE_PROVIDERS,
  STORAGE_PROVIDER_KEYS,
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  PROJECT_STATUS,
  DEFAULT_FILTER,
  DEFAULT_MAX_SIZE,
  DEFAULT_PROVIDERS,
  // Components
  ChangeStorageProviderMenu,
  FileSearch,
  FileUploadButton,
  SaveToButton,
  SignOutButton,
  StorageExplorerV1,
  StorageExplorerHeaderV1,
  StorageExplorerBackButtonV1,
  StorageExplorerBreadcrumbsV1,
  StorageExplorerFooterV1,
  StorageExplorerTableV1,
  StorageExplorerTableBodyV1,
  StorageExplorerTableHeadV1,
  StorageExplorerToolbarV1,
  StorageExplorer,
  StorageExplorerProviderSettings,
  StorageExplorerBreadcrumbs,
  StorageExplorerToolbar,
  StorageExplorerTable,
  StorageExplorerTableContainer,
  StorageExplorerProviderList,
  StorageProviderLogo,
  StorageProjectMenu,
  StorageProjectMenuButton,
  StorageProjectMenuContent,
  StorageProjectMenuName,
  StorageProjectMenuOpenInDesktop,
  StorageProjectMenuReadonlyInfo,
  StorageProjectMenuAutoSave,
  // i18n
  changeLanguage,
  i18n,
  init18n,
  useTranslation,
  // Hooks
  useCloudStorage,
  useCloudStorageProject,
  useAllCloudStorageItems,
  useRecentCloudStorageItems,
  useSharedCloudStorageItems,
  useStorageExplorer,
  useStorageExplorerItem,
  useStorageExplorerItems,
  useStorageProjectMenu,
  // Store
  CloudStorage,
  CloudStorageApi,
  // Utils
  getNameParts,
};
