/**
 * Example: `(args?: {data?: string}) => () => GetSomeValue(data);`
 * @callback DynamicDefaultFunc
 * @param {Record<string, any>} [args]
 * @returns {() => any}
 */

/**
 * @typedef PropertyDefaultSchema
 * @property {string} func
 * @property {Record<string, any>} args
 */

/**
 * @typedef {string | PropertyDefaultSchema | undefined} DynamicDefault
 */

/**
 * @typedef {Record<string, DynamicDefault>} DefaultSchema
 */

/**
 * @type {Record<string, DynamicDefaultFunc | undefined>}
 */
const DEFAULTS = {};

/**
 * @type {(() => import('ajv').KeywordDefinition) & { DEFAULTS: typeof DEFAULTS }}
 */
const getDef = Object.assign(_getDef, { DEFAULTS });

/**
 * @returns {import('ajv').KeywordDefinition}
 */
function _getDef() {
  return {
    keyword   : 'dynamicDefaults',
    type      : 'object',
    schemaType: ['string', 'object'],
    modifying : true,
    valid     : true,
    /**
     * @param {DefaultSchema} schema
     * @param {{}} _parentSchema
     * @param {import('ajv').SchemaCxt} it
     */
    compile(schema, _parentSchema, it) {
      if (!it.opts.useDefaults || it.compositeRule) {
        return () => true;
      }
      /** @type {Record<string, () => any>} */
      const fs = {};
      for (const key in schema) {

        fs[key] = getDefault(schema[key]);
      }
      const empty = it.opts.useDefaults === 'empty';
      return (/** @type {Record<string, any>} */data) => {
        for (const prop in schema) {
          if (data[prop] === undefined || (empty && (data[prop] === null || data[prop] === ''))) {
            data[prop] = fs[prop]();
          }
        }
        return true;
      };
    },
    metaSchema: {
      type                : 'object',
      additionalProperties: {
        anyOf: [
          { type: 'string' },
          {
            type                : 'object',
            additionalProperties: false,
            required            : ['func', 'args'],
            properties          : {
              func: { type: 'string' },
              args: { type: 'object' },
            },
          },
        ],
      },
    },
  };
}

/**
 * @param {DynamicDefault} d
 */
const getDefault = (d) => {
  return typeof d == 'object' ? getObjDefault(d) : getStrDefault(d);
};

/**
 * @param {PropertyDefaultSchema} param0
 */
const getObjDefault = ({ func, args }) => {
  const def = DEFAULTS[func];
  assertDefined(func, def);
  return def(args);
};

/**
 * @param {string} d
 */
const getStrDefault = (d = '') => {
  const def = DEFAULTS[d];
  assertDefined(d, def);
  return def();
};

/**
 * @param {string} name
 * @param {typeof DEFAULTS[keyof typeof DEFAULTS]} def
 * @returns {asserts def is DynamicDefaultFunc}
 */
const assertDefined = (name, def) => {
  if (!def) {
    throw new Error(`invalid "dynamicDefaults" keyword property value: ${name}`);
  }
};

export default getDef;
