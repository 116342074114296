import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Input, useEventCallback, useForkRef, useTheme } from '@mtb/ui';
import { FileSizeTooLargeDialog, InvalidFileTypeDialog } from '../../dialogs';
import { useStorageExplorer } from '../StorageExplorerV1';
import { ERRORS, isFileImage, validateFile } from './utils';

export const FileUploader = forwardRef(({ children, actions, ...other }, ref) => {
  const theme = useTheme();
  const inputRef = useRef();
  const handleRef = useForkRef(inputRef, ref);
  const [error, setError] = useState(null);
  const { filter, maxSize, openFile } = useStorageExplorer();

  const handleOnDialogClose = useEventCallback(() => setError(null));
  const handleOnChange = useCallback(
    async event => {
      const updatedFiles = [];
      const selectedFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];

        const { isValid, error } = validateFile(file, { accept: filter, maxSize });

        if (!isValid) {
          setError(error);
          continue;
        }

        if (isFileImage(file)) {
          file.objectURL = window.URL.createObjectURL(file);
        }

        updatedFiles.push(file);
      }

      if (updatedFiles.length) {
        openFile(updatedFiles[0]);
      }
    },
    [filter, maxSize, openFile],
  );

  const handleOnClick = useEventCallback(({ onClick } = {}) => event => {
    if (onClick) {
      handleOnClick(event);
    }

    if (inputRef.current) {
      inputRef.current.click();
    }
  });

  useImperativeHandle(
    actions,
    () => ({
      open: handleOnClick(),
    }),
    [handleOnClick],
  );

  return (
    <>
      <Input
        aria-hidden
        inputProps={{ tabIndex: -1, 'aria-hidden': true, 'data-testid': 'file-uploader-input', accept: filter }}
        inputRef={handleRef}
        style={theme.mixins.visuallyHidden()}
        type="file"
        onChange={handleOnChange} />
      {React.cloneElement(children, {
        'data-testid': 'file-uploader-button',
        ...children.props,
        ...other,
        onClick      : handleOnClick(children.props),
      })}
      <InvalidFileTypeDialog
        open={error === ERRORS.INVALID_FILE_TYPE}
        onClose={handleOnDialogClose} />
      <FileSizeTooLargeDialog
        open={error === ERRORS.FILE_TOO_LARGE}
        onClose={handleOnDialogClose} />
    </>
  );
});

export default FileUploader;
