import config from '../../config';
import BRAINSTORM from './brainstorm';
import DATACENTER from './datacenter';
import DISCOVER from './discover';
import LEARNING_CENTER from './learning-center';
import MSSO from './msso';
import PLATFORM from './platform';
import REPORTING_TOOL from './reportingtool';
import WSO from './wso';


/**
 * All the modules that are available in the platform.
 */
export const MODULES = {
  PLATFORM,
  MSSO,
  BRAINSTORM,
  WSO,
  DATACENTER,
  REPORTING_TOOL,
  DISCOVER,
  LEARNING_CENTER,
};

/**
 * Object of the module configuration in Platform that are feature flagged.
 */
export const FEATURE_FLAGGED_MODULES = {
  [MODULES.BRAINSTORM.key]     : config.feature_flag_brainstorm,
  [MODULES.WSO.key]            : config.feature_flag_wso,
  [MODULES.DATACENTER.key]     : config.feature_flag_data_center,
  [MODULES.REPORTING_TOOL.key] : config.feature_flag_reporting_tool,
  [MODULES.DISCOVER.key]       : config.feature_flag_ui_v2,
  [MODULES.LEARNING_CENTER.key]: config.feature_flag_ui_v2,
};

export default MODULES;
