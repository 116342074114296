import type ConfigBase from './ConfigBase';
import type { AppRailDrawerIds } from '../core/store/reducers/layout';
import type { PlaneId, NestedPlaneState } from '../core/store/reducers/planes';

/**
 * The loading state types for a plane.
 */
export const PLANE_STATUS_TYPES = {
  PENDING: 'pending' as const,
  LOADING: 'loading' as const,
  IDLE   : 'idle' as const,
  ERROR  : 'error' as const,
};

/**
 * The IDs of app rail drawers.
 */
export const PLATFORM_APPRAIL_DRAWER_IDS = {
  STORAGE_EXPLORER     : 'storage-explorer' as const,
  NAVIGATOR            : 'navigator' as const,
  WSO_NAVIGATOR        : 'wso-navigator' as const,
  WSO_TEMPLATES        : 'wso-templates' as const,
  WSO_SHAPES           : 'wso-shapes' as const,
  REPORTING_TOOL_ASSETS: 'reportingtool-assets' as const,
  REPORTING_TOOL_DATA  : 'reportingtool-data' as const,
  REPORTING_TOOL_PAGES : 'reportingtool-pages' as const,
};

/**
 * Represents the state of a platform module.
 */
class State {
  #protected: ConfigBase;

  /**
   * Creates an instance of a State.
   * @param configBase - A pointer to the protected config base
   */
  constructor(configBase: ConfigBase) {
    this.#protected = configBase;
  }

  /**
   * Creates an instance of a State.
   * @param configBase - A pointer to the protected config base
   */
  static Create(configBase: ConfigBase) {
    return new State(configBase);
  }

  /**
   * Get the loading state.
   */
  get loading() {
    return this.#protected.core.getState().layout.loading;
  }

  /**
   * Get the open states for the app rail drawers.
   */
  get appRailDrawers(): Record<AppRailDrawerIds, boolean> {
    return this.#protected.core.getState().layout.appRailDrawers;
  }

  /**
   * Sets the loading state.
   * @param planeId - The ID of the plane.
   * @param loading - The loading state to set.
   */
  setLoading = (planeId: PlaneId, loading: boolean): void => {
    this.#protected.core.actions.setPlaneLoading(planeId, loading);
  };

  /**
   * Sets the open state of an app rail drawer.
   * @param appRailDrawerId - The ID of the app rail drawer.
   * @param open - The open state to set.
   */
  setAppRailDrawerOpen = (appRailDrawerId: AppRailDrawerIds, open: boolean): void => {
    this.#protected.core.actions.setAppRailDrawerOpen(appRailDrawerId, open);
  };

  /**
   * Sets the error state.
   * @param planeId - The ID of the plane.
   * @param error - The error to set.
   */
  setError = (planeId: PlaneId, error: Error): void => {
    this.#protected.core.actions.setPlaneError(planeId, error);
  };

  /**
   * Sets the state of a specific plane.
   * @param planeId - The ID of the plane.
   * @param state - The state to set for the plane.
   */
  setState = (planeId: PlaneId, state: NestedPlaneState): void => {
    this.#protected.core.actions.updatePlaneState(planeId, state);
  };

  /**
   * Hooks into the loading state.
   * @param planeId - The ID of the plane.
   */
  useLoading = (planeId: PlaneId): boolean => {
    return this.#protected.core.useSelector(store => store.planes[planeId]?.status === PLANE_STATUS_TYPES.LOADING);
  };

  /**
   * Handler to close a plane using the given plane ID.
   * @param planeId
   */
  closePlane = (planeId: PlaneId): void => {
    this.#protected.core.Plane.closePlane(planeId);
  };
}

export default State;
