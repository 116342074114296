import type { Config } from './config';

const PLATFORM_CONFIG_STORE = 'platform-config-store' as const;

/**
 * Sets the configuration overrides in storage.
 * Which will then be used by the config proxy anytime a config value is accessed.
 * @param overrides - The configuration overrides to set.
 */
export function setStoredConfig(overrides: Partial<Config>) {
  const overridesJSON = JSON.stringify({ ...getStoredConfig(), ...overrides });
  localStorage.setItem(PLATFORM_CONFIG_STORE, overridesJSON);
}

/**
 * Clears the configuration overrides from storage.
 */
export function clearStoredConfig() {
  localStorage.removeItem(PLATFORM_CONFIG_STORE);
}

/**
 * @returns The configuration overrides from storage.
 */
export function getStoredConfig(): Partial<Config> {
  const storedConfig = localStorage.getItem(PLATFORM_CONFIG_STORE);
  return storedConfig ? JSON.parse(storedConfig) : {};
}
