import type { PlatformModuleSetRootStyles } from '../../module-config/ConfigBase';
import type { MtbPlatform } from '../../types';
import type { PropsOf } from '../types';
import type { Theme, CSSProperties } from '@mtb/ui/types/types';
import type { ComponentType } from 'react';
import { createElement, useCallback, useRef, useState, useMemo } from 'react';
import { Box, useTheme } from '@mtb/ui';
import getIdHash from '../../utilities/get-id-hash';
import { PLATFORM_DATA_ATTRIBUTES } from '../../utils';
import { createRemoteModuleTheme } from './create-remote-module-theme';

const rootStyle: CSSProperties = {
  width   : '100%',
  height  : '100%',
  position: 'absolute',
};

/**
 * Custom hook to get the remote module theme using the platform theme.
 * @returns {Theme}
 */
function useRemoteModuleTheme(): Theme {
  const platformTheme = useTheme();
  const remoteModuleTheme = useMemo(() => createRemoteModuleTheme(platformTheme), [platformTheme]);
  return remoteModuleTheme;
}

export const withCssScoping = (RemoteComponent: ComponentType<MtbPlatform.PlatformModuleProps>, id: string) => {
  return (props: MtbPlatform.PlatformModuleProps) => {
    const theme = useRemoteModuleTheme();
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [rootStyles, setRootStyles] = useState<CSSProperties>({});

    const handleSetRootStyles = useCallback(
      (styles =>
        typeof styles === 'function'
          ? setRootStyles(styles({ theme, rootRef }))
          : setRootStyles(styles)) as PlatformModuleSetRootStyles,
      [theme],
    );

    const remoteModuleComponent = useMemo(() => createElement(RemoteComponent, {
      key          : props.plane?.id,
      ...props,
      setRootStyles: handleSetRootStyles,
      theme,
    }), [RemoteComponent, props, theme, handleSetRootStyles]);

    const styleScopedRemoteModuleComponent = useMemo(() => createElement(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TSC is seeing an invalid error here
      Box,
      {
        ref                            : rootRef,
        className                      : `mtb-platform-remote-module-root ${id}-root ${getIdHash(id)}`,
        // These must be set to ensure the root is the correct size within Platform.
        style                          : rootStyle,
        // The styles a consumer can set on the root element.
        sx                             : rootStyles,
        [PLATFORM_DATA_ATTRIBUTES.ROOT]: 'true',
      } as PropsOf<typeof Box>,
      remoteModuleComponent,
    ), [rootRef, rootStyles, remoteModuleComponent, id]);

    return styleScopedRemoteModuleComponent;
  };
};

export default withCssScoping;
