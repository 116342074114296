import { useCallback, useMemo } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FileDownloadIcon,
  Spacer,
  Tooltip,
} from '@mtb/ui';
import { PROJECT_STATUS } from '../../constants';
import { useCloudStorageProject } from '../../hooks';
import { ChangeStorageProviderMenu } from '../../menus';
import { useTranslation } from '../../services/i18n';

/**
 * @param {{ projectId: import('@').CloudStorageProjectId, type: import('@').CloudStorageProjectConnectionStatus, open: boolean, onClose: (confirm?: boolean) => void }} props
 */
export function ConnectionDialog({ projectId, type, open, onClose }) {
  const [t] = useTranslation();
  const project = useCloudStorageProject(projectId);
  const handleOnConfirm = useCallback(() => onClose(true), [onClose]);
  const handleOnClose = useCallback(() => onClose(false), [onClose]);

  const titles = useMemo(
    () => ({
      [PROJECT_STATUS.DISCONNECTED]: t('connection.disconnectedTitle'),
      [PROJECT_STATUS.CONNECTED]   : t('connection.reconnectedTitle'),
    }),
    [t],
  );

  const messages = useMemo(
    () => ({
      [PROJECT_STATUS.DISCONNECTED]: t('connection.disconnectedMessage'),
      [PROJECT_STATUS.CONNECTED]   : t('connection.reconnectedMessage'),
    }),
    [t],
  );

  return (
    <Dialog
      data-testid="connection-dialog"
      open={open}>
      <DialogTitle>
        {titles[type]}
        <Spacer />
        <Tooltip
          placement="bottom"
          title={t('connection.close')}>
          <Button
            color="default"
            icon={<CloseIcon />}
            variant="text"
            onClick={handleOnClose} />
        </Tooltip>
      </DialogTitle>
      <DialogContent>{messages[type]}</DialogContent>
      <DialogActions>
        <Button
          color="default"
          variant="contained"
          onClick={project.download}>
          <FileDownloadIcon />
          {t('connection.download')}
        </Button>
        <Spacer />
        {type === PROJECT_STATUS.DISCONNECTED && (
          <ChangeStorageProviderMenu onClick={handleOnConfirm}>
            <Button variant="contained">{t('connection.signInToRepository')}</Button>
          </ChangeStorageProviderMenu>
        )}
        {type === PROJECT_STATUS.CONNECTED && (
          <Button onClick={handleOnConfirm}>{t('connection.turnOnAutoSave')}</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConnectionDialog;
