/**
 * Attempts to parse a string as JSON. Returns the object if parsing is successful,
 * or the original input if parsing fails or the input is not a string.
 *
 * @template T - The type of the input parameter.
 * @param str - The input that might be a JSON string.
 * @returns Either the parsed object if the input is a valid JSON string, or the original input.
 */
function parseIfJson<T>(str: T): T extends string ? object : T {
  if (typeof str === 'string') {
    try {
      const res = JSON.parse(str);
      if (res && typeof res === 'object') {
        return res as T extends string ? object : T;
      }
    } catch {
      // If parsing fails, return the original string
    }
  }
  // Return the input as is if it's not a string or if parsing fails
  return str as T extends string ? object : T;
}

export { parseIfJson };
