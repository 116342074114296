import type {
  LoggerErrorArguments,
  LoggerFatalArguments,
  LoggerInfoArguments,
  LoggerLogArguments,
  LoggerWarnArguments,
  LoggerDataArgs,
  LoggerLevel,
  LoggerError,
  LoggerName,
  LoggerTelemetryArgs,
  LoggerTelemetryClient,
} from './types';
import type { Values } from '../../core/types';
import TelemetryClient from '../telemetry';
import { LOG_LEVELS } from './constants';
import Logger from '.';

export class NamedLogger {
  #telemetryClient: LoggerTelemetryClient;
  #name: string;

  #parseArgs(...args: LoggerDataArgs) {
    // Return empty string if no arguments
    if (!args.length) {
      return '';
    }

    // Return single string argument as is
    if (args.length === 1) {
      if (typeof args[0] === 'string') {
        return args[0];
      }
      return args[0];
    }

    // Format objects as JSON
    for (let i = 0; i < args.length; i++) {
      if (typeof args[i] === 'object') {
        args[i] = JSON.stringify(args[i], null, 2);
      }
    }

    return args.join(' ');
  }

  #formatName(...args: string[]) {
    const nameParts = [this.#name, ...args];
    return `[${nameParts.filter(Boolean).join(':')}]`;
  }

  #createError(name: LoggerName, ...data: LoggerDataArgs): LoggerError {
    const exception = new Error(name);

    // Assuming we only need to handle the first error found
    for (const arg of data) {
      if (arg instanceof Error) {
        exception.message = arg.message;
        if (arg.stack) {
          exception.stack = arg.stack;
        }
        break;
      }
    }

    // If no Error instance was found, handle all arguments
    if (!exception.message) {
      exception.message = this.#parseArgs(...data);
    }

    return exception;
  }

  #createTelemetryProps(
    level: Values<typeof LOG_LEVELS>,
    name: LoggerName,
    ...data: LoggerDataArgs
  ): LoggerTelemetryArgs {
    const message = this.#parseArgs(...data);
    const telemetryProperties = {
      event: {
        name,
      },
      properties: {
        message,
        level,
      },
    };
    return telemetryProperties;
  }

  #invokeTelemetryEvent(level: LoggerLevel, name: LoggerName, ...data: LoggerDataArgs) {
    if (!this.#telemetryClient) {
      return;
    }
    const { event, properties } = this.#createTelemetryProps(level, name, ...data);
    this.#telemetryClient?.trackEvent?.(event, properties);
  }

  #invokeTelemetryException(
    level: LoggerLevel,
    name: LoggerName,
    ...data: LoggerDataArgs
  ) {
    const exception = this.#createError(name, ...data);
    if (!this.#telemetryClient) {
      return;
    }

    const { event, properties } = this.#createTelemetryProps(level, name, ...data);
    this.#telemetryClient.trackException({ exception }, {
      ...properties,
      ...event,
    });
  }

  #invoke(level: Values<typeof LOG_LEVELS>, ...data: LoggerDataArgs) {
    const name = this.#formatName(level);
    switch (level) {
      case LOG_LEVELS.DEBUG:
        Logger.log(name, ...data);
        break;
      case LOG_LEVELS.INFO:
        this.#invokeTelemetryEvent(LOG_LEVELS.INFO, name, ...data);
        Logger.info(name, ...data);
        break;
      case LOG_LEVELS.WARN:
        this.#invokeTelemetryException(LOG_LEVELS.WARN, name, ...data);
        Logger.warn(name, ...data);
        break;
      case LOG_LEVELS.ERROR:
        this.#invokeTelemetryException(LOG_LEVELS.ERROR, name, ...data);
        Logger.error(name, ...data);
        break;
      case LOG_LEVELS.FATAL:
        this.#invokeTelemetryException(LOG_LEVELS.FATAL, name, ...data);
        Logger.fatal(`${name} ${this.#parseArgs(...data).toString()}`);
        break;
      default:
        throw new Error(`${name} Unknown log level: ${level}`);
    }
  }

  constructor(name: string, telemetryClient?: LoggerTelemetryClient) {
    if (!name) {
      throw new Error('[NamedLoggerClient] name is required.');
    }
    this.#name = name;
    this.#telemetryClient = telemetryClient || TelemetryClient.createCoreClient();
  }

  log(...data: LoggerLogArguments) {
    this.#invoke(LOG_LEVELS.DEBUG, ...data);
  }

  info(...data: LoggerInfoArguments) {
    this.#invoke(LOG_LEVELS.INFO, ...data);
  }

  warn(...data: LoggerWarnArguments) {
    this.#invoke(LOG_LEVELS.WARN, ...data);
  }

  error(...data: LoggerErrorArguments) {
    this.#invoke(LOG_LEVELS.ERROR, ...data);
  }

  fatal(...data: LoggerFatalArguments) {
    this.#invoke(LOG_LEVELS.FATAL, ...data);
  }
}

export default NamedLogger;
