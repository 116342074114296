/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import type {
  LoggerLogArguments,
  LoggerInfoArguments,
  LoggerWarnArguments,
  LoggerErrorArguments,
  LoggerGroupArguments,
  LoggerGroupCollapsedArguments,
  InvokeConsoleHandler,
  InvokeConsoleGroupHandler,
  LoggerTelemetryClient,
} from './types';
import config from '../../config';
import NamedLogger from './named';

/**
 * Expose some setters for testing purposes, otherwise by default the logger is enabled
 * as long as dev tools are enabled and we're not in a test environment.
 */
const isTestEnvironment = process.env.NODE_ENV === 'test';
const isDevToolsEnabled = config.dev_tools_enabled;
let isEnabled = isDevToolsEnabled && !isTestEnvironment;

export const disableTestLogging = () => {
  isEnabled = isDevToolsEnabled && !isTestEnvironment;
};

export const enableTestLogging = () => {
  isEnabled = true;
};

/**
 * Invokes the console handler if logging is enabled.
 * @param consoleHandler
 * @param data
 */
function invokeConsole(consoleHandler: InvokeConsoleHandler, ...data: Parameters<InvokeConsoleHandler>): void {
  if (isEnabled) {
    consoleHandler(...data);
  }
}

/**
 * Invokes the console group handler if logging is enabled.
 * @param consoleHandler
 * @param label
 */
function invokeConsoleGroup(consoleHandler: InvokeConsoleGroupHandler, ...label: any[]): void {
  if (isEnabled) {
    consoleHandler(...label);
  }
}

/**
 * A static service for creating named loggers and logging to the console.
 */
class LoggerClient {
  static createNamedLogger(name: string, telemetryClient?: LoggerTelemetryClient): NamedLogger {
    return new NamedLogger(name, telemetryClient);
  }

  static log(...data: LoggerLogArguments) {
    invokeConsole(console.log, ...data);
  }

  static info(...data: LoggerInfoArguments) {
    invokeConsole(console.info, ...data);
  }

  static warn(...data: LoggerWarnArguments) {
    invokeConsole(console.warn, ...data);
  }

  static error(...data: LoggerErrorArguments) {
    invokeConsole(console.error, ...data);
  }

  static fatal (error: Error | string) {
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error(error);
    }
  }

  static group(...data: LoggerGroupArguments) {
    invokeConsoleGroup(console.group, ...data);
  }

  static groupCollapsed(...data: LoggerGroupCollapsedArguments) {
    invokeConsoleGroup(console.groupCollapsed, ...data);
  }

  static groupEnd() {
    invokeConsoleGroup(console.groupEnd);
  }
}

export default LoggerClient;
