import { useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { Stack } from '@mtb/ui';
import PropTypes from 'prop-types';
import {
  CLOUD_STORAGE_CATEGORIES,
  STORAGE_PROVIDER_KEYS,
  DEFAULT_FILTER,
  DEFAULT_MAX_SIZE,
  DEFAULT_PROVIDERS,
} from '../../constants';
import { useCloudStorageStore } from '../../hooks/useCloudStorageStore';
import { StorageExplorerContext } from './context';
import { useStorageExplorerItems } from './hooks';

/**
 * @typedef StorageExplorerProps
 * @property {string} [id] - An optional unique identifier to namespace the data set for the storage explorer.
 * @property {import('react').Ref<Object>} [actions] - A ref for imperative actions.
 * @property {import('react').ReactNode} [children] - The children to render.
 * @property {import('../../constants').CloudStorageCategories} [defaultCategory='recent'] - The default category for the storage explorer.
 * @property {import('../../constants').STORAGE_PROVIDER_KEYS.ONE_DRIVE | import('../../constants').STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE} [defaultType='msgraph'] - The default category for the storage explorer.
 * @property {import('@').StorageProviderItem} [defaultFolder] - The default folder for the storage explorer.
 * @property {boolean} [dense=false] - Whether or not to use dense mode.
 * @property {boolean} [disableCache=false] - Whether or not to disable caching.
 * @property {string[]} [filter=[]] - An array of file extensions to filter the file list by.
 * @property {number} [maxSize=Infinity] - The maximum size of files to be displayed.
 * @property {import('@').StorageProviderKey[]} [providers=import('@').UnsafeTuple<import('@').StorageProviderKey>] - An array of storage providers to be used. If not provided, all providers will be used.
 * // TODO: Update this type and the SaveToDialog to use the onChange pattern to be (event, reason, details) => void
 * @property {(params: { selected: import('@').StorageProviderItem | null, folder: import('@').StorageProviderItem | null, category: string | null }) => void} [onChange] - Handler called when selected, folder, category, or breadcrumbTrail changes.
 * @property {(error: Error) => void} [onError] - Handler called when an error occurs.
 * @property {(item: import('@').StorageProviderItem | File) => void} [onOpen] - Handler called when a file is opened.
 */

/**
 * @param {StorageExplorerProps} props
 */
export function StorageExplorer({
  id,
  actions,
  children,
  defaultCategory = CLOUD_STORAGE_CATEGORIES.NONE,
  defaultProvider = STORAGE_PROVIDER_KEYS.ONE_DRIVE,
  defaultFolder,
  dense = false,
  disableCache = false,
  disableProviderSettings = false,
  disableContextMenus = false,
  disableRowActions = false,
  defaultFilter = DEFAULT_FILTER,
  maxSize = DEFAULT_MAX_SIZE,
  onChange,
  onError,
  onOpen,
  renderOpenInMenuOptions,
  sx,
}) {
  const cloudStorage = useCloudStorageStore();
  const {
    folder,
    category,
    items,
    selected,
    isLoading,
    filter,
    provider,
    setProvider,
    setFilter,
    setIsLoading,
    setFolder,
    setCategory,
    setSelected,
    refresh,
    back,
    breadcrumbTrail,
  } = useStorageExplorerItems({
    id,
    defaultCategory,
    disableCache,
    defaultFolder,
    defaultFilter,
    defaultProvider,
  });

  useImperativeHandle(
    actions,
    () => ({
      back,
      setIsLoading,
      setSelected,
      setFolder,
    }),
    [back, setFolder, setIsLoading, setSelected],
  );

  useEffect(() => {
    onChange?.({
      selected,
      folder,
      category,
    });
  }, [onChange, selected, folder, category]);

  /**
   * Opens the given storage item.
   * If the item is a folder, it will be set as the current folder.
   */
  const handleOpenItem = useCallback(
    /**
     * @param {import('@').StorageProviderItem} item - The storage explorer item to open.
     */
    async (item) => {
      if (item.folder) {
        return setFolder(item);
      }

      try {
        if (!cloudStorage.verifyBeforeOpen(item)) {
          return false;
        }
        await onOpen?.(item);
      } catch (error) {
        console.error(error);
        onError?.(error);
      }
    },
    [cloudStorage, onError, onOpen, setFolder],
  );

  /**
   * Handles opening the given storage explorer file.
   */
  const handleOpenFile = useCallback(
    /**
     * @param {File} file - The file to open.
     */
    async (file) => {
      try {
        await onOpen?.(file);
      } catch (error) {
        console.error(error);
        onError?.(error);
      }
    },
    [onError, onOpen],
  );

  const value = useMemo(
    () => ({
      id,
      filter,
      setFilter,
      defaultFilter,
      maxSize,
      disableCache,
      disableProviderSettings,
      disableContextMenus,
      disableRowActions,
      folder,
      category,
      items,
      selected,
      isLoading,
      provider,
      setProvider,
      setIsLoading,
      setFolder,
      setCategory,
      setSelected,
      refresh,
      breadcrumbTrail,
      back,
      dense,
      openItem: handleOpenItem,
      openFile: handleOpenFile,
      renderOpenInMenuOptions,
    }),
    [
      id,
      filter,
      setFilter,
      defaultFilter,
      maxSize,
      disableCache,
      disableProviderSettings,
      disableContextMenus,
      disableRowActions,
      folder,
      category,
      items,
      selected,
      isLoading,
      provider,
      setProvider,
      setIsLoading,
      setFolder,
      setCategory,
      setSelected,
      refresh,
      breadcrumbTrail,
      back,
      dense,
      handleOpenItem,
      handleOpenFile,
      renderOpenInMenuOptions,
    ],
  );

  return (
    <StorageExplorerContext.Provider value={value}>
      <Stack
        direction="row"
        gap={3}
        sx={{
          flex     : '1 0 auto',
          height   : '100%',
          width    : '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          ...sx,
        }}>
        {children}
      </Stack>
    </StorageExplorerContext.Provider>
  );
}

StorageExplorer.propTypes = {
  /**
   * A ref for imperative actions.
   */
  actions        : PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * An array of file extensions to filter the file list by.
   */
  filter         : PropTypes.arrayOf(PropTypes.string),
  /**
   * The maximum size of files to be displayed.
   */
  maxSize        : PropTypes.number,
  /**
   * Whether or not to disable caching.
   */
  disableCache   : PropTypes.bool,
  /**
   * Handler called when selected, folder, category, or breadcrumbTrail changes.
   */
  onChange       : PropTypes.func,
  /**
   * Handler called when a file is opened.
   */
  onOpen         : PropTypes.func,
  /**
   * Handler called when an error occurs.
   */
  onError        : PropTypes.func,
  /**
   * Default category for the file explorer.
   */
  defaultCategory: PropTypes.string,
  /**
   * Default folder for the file explorer.
   */
  defaultFolder  : PropTypes.object,
};
