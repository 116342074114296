import { useSyncExternalStore } from '@mtb/ui';
import { cloudStorageStore } from '../services/store';
import CloudStorageStore from '../store/index.ts';
import StoreObservable from '../store/observable';

/**
 * @returns {ReturnType<import('../services/store').CloudStorageStoreType['getSnapshot']>}
 */

export function useCloudStorageStore() {
  return useSyncExternalStore(
    (listener) => cloudStorageStore.subscribe(listener),
    () => cloudStorageStore.getSnapshot(),
  );
}

export function useNewCloudStorageStore() {
  return useSyncExternalStore(
    (listener) => StoreObservable.subscribe(listener),
    () => CloudStorageStore.getSnapshot(),
  );
}
