/**
 * @typedef {{ key: string, params: { [key: string]: any }, pathToValue: string }} I18nErrorInfo
 */

/**
 * @typedef {import('ajv').ErrorObject & { i18nData: I18nErrorInfo }} ErrorObject
 */

/**
 * A utility that modifies the errors to include some basic i18n keys and
 * parameters. It also returns the array of just the i18n data.
 * @param {ErrorObject[]} errors
 * @returns {I18nErrorInfo[]}
 */
export const applyI18nKeys = errors => {
  if (!errors?.length) {
    return [];
  }
  for (const error of errors) {
    switch (error.keyword) {
      case 'additionalItems':
      case 'items':
      case 'maxItems':
        error.i18nData = {
          key        : 'tooManyItems',
          params     : { count: error.params.limit },
          pathToValue: '',
        };
        break;
      case 'maxLength':
        error.i18nData = {
          key        : 'tooManyCharacters',
          params     : { count: error.params.limit },
          pathToValue: '',
        };
        break;
      case 'maxProperties':
        error.i18nData = {
          key        : 'tooManyProperties',
          params     : { count: error.params.limit },
          pathToValue: '',
        };
        break;
      case 'minItems':
        error.i18nData = {
          key        : 'tooFewItems',
          params     : { count: error.params.limit },
          pathToValue: '',
        };
        break;
      case 'minLength':
        error.i18nData = {
          key        : 'tooFewCharacters',
          params     : { count: error.params.limit },
          pathToValue: '',
        };
        break;
      case 'minProperties':
        error.i18nData = {
          key        : 'tooFewProperties',
          params     : { count: error.params.limit },
          pathToValue: '',
        };
        break;
      case 'additionalProperties':
        error.i18nData = {
          key        : 'hasExtraProperties',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'anyOf':
        error.i18nData = {
          key        : 'doesNotMatchAnyOf',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'const':
        error.i18nData = {
          key        : 'isNotEqualToConstant',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'contains':
        error.i18nData = {
          key        : 'doesNotContainValidItem',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'dependencies':
      case 'dependentRequired':
        error.i18nData = {
          key        : 'mustHavePropertiesWhenOtherIsPresent',
          params     : { count: error.params.depsCount, properties: error.params.deps, other: error.params.property },
          pathToValue: '',
        };
        break;
      case 'unevaluatedItems':
        error.i18nData = {
          key        : 'tooManyItems',
          params     : { count: error.params.len },
          pathToValue: '',
        };
        break;
      case 'unevaluatedProperties':
        error.i18nData = {
          key        : 'cannotHaveUnevaluatedProperties',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'uniqueItems':
        error.i18nData = {
          key        : 'hasDuplicateItems',
          params     : { duplicateIndexes: [error.params.j, error.params.i] },
          pathToValue: '',
        };
        break;
      case 'discriminator':
        switch (error.params.error) {
          case 'tag':
            error.i18nData = {
              key        : 'tagIsNotString',
              params     : { tag: error.params.tag },
              pathToValue: '',
            };
            break;
          case 'mapping':
            error.i18nData = {
              key        : 'tagIsNotInOneOf',
              params     : { tag: error.params.tag },
              pathToValue: '',
            };
            break;
          default:
            error.i18nData = {
              key        : 'mustPassKeywordValidation',
              params     : { keyword: error.keyword },
              pathToValue: '',
            };
            break;
        }
        break;
      case 'enum':
        error.i18nData = {
          key        : 'isNotEqualToAllowedValues',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'false schema':
        error.i18nData = {
          key        : 'schemaIsFalse',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'format':
        error.i18nData = {
          key        : 'doesNotMatchFormat',
          params     : { format: error.params.format },
          pathToValue: '',
        };
        break;
      case 'formatMaximum':
      case 'formatExclusiveMaximum':
      case 'maximum':
      case 'exclusiveMaximum':
      case 'stringMaximum':
      case 'stringExclusiveMaximum':
      case 'formatMinimum':
      case 'formatExclusiveMinimum':
      case 'minimum':
      case 'exclusiveMinimum':
      case 'stringMinimum':
      case 'stringExclusiveMinimum':
        error.i18nData = {
          key: `mustBe${error.params.comparison?.startsWith('>') ? 'GreaterThan' : 'LessThan'}${
            error.params.comparison?.endsWith('=') ? 'OrEqualTo' : ''
          }Limit`,
          params     : { limit: error.params.limit },
          pathToValue: '',
        };
        break;
      case 'if':
        error.i18nData = {
          key        : 'mustMatchIfSchema',
          params     : { if: error.params.failingKeyword },
          pathToValue: '',
        };
        break;
      case 'multipleOf':
        error.i18nData = {
          key        : 'isNotMultipleOfValue',
          params     : { value: error.params.multipleOf },
          pathToValue: '',
        };
        break;
      case 'not':
        error.i18nData = {
          key        : 'mustNotBeValidAccordingTo',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'oneOf':
        error.i18nData = {
          key        : 'mustMatchExactlyOneOf',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'pattern':
        error.i18nData = {
          key        : 'mustMatchPattern',
          params     : { pattern: error.params.pattern },
          pathToValue: '',
        };
        break;
      case 'patternRequired':
        error.i18nData = {
          key        : 'mustHavePropertyMatchingPattern',
          params     : { pattern: error.params.missingPattern },
          pathToValue: '',
        };
        break;
      case 'includes':
        error.i18nData = {
          key        : 'mustIncludeString',
          params     : { includes: error.params.includes },
          pathToValue: '',
        };
        break;
      case 'propertyNames':
        error.i18nData = {
          key        : 'propertyNameIsInvalid',
          params     : {},
          pathToValue: '',
        };
        break;
      case 'required':
        error.i18nData = {
          key        : 'propertyIsRequired',
          params     : { property: error.params.missingProperty },
          pathToValue: '',
        };
        break;
      case 'strictNumeric':
      case 'type':
        error.i18nData = {
          key        : 'isNotType',
          params     : { type: error.params.type },
          pathToValue: '',
        };
        break;
      default:
        error.i18nData = {
          key        : 'mustPassKeywordValidation',
          params     : { keyword: error.keyword },
          pathToValue: '',
        };
    }
    const path = error.schemaPath.startsWith('#/properties/')
      ? `#/${error.schemaPath.slice('#/properties/'.length, error.schemaPath.length - error.keyword.length - 1)}`
      : error.schemaPath.slice(0, error.schemaPath.length - error.keyword.length - 1);
    const pathEnd =
      error.keyword === 'required' ? `${path.endsWith('/') ? '' : '/'}${error.params.missingProperty}` : '';
    error.i18nData.pathToValue = `${path}${pathEnd}`;
  }
  return errors.map(error => error.i18nData);
};

export default applyI18nKeys;
