import React from 'react';
import { Stack, Spacer } from '@mtb/ui';
import { StorageExplorerFileSearch } from '../StorageExplorerFileSearch';
import { StorageExplorerFileTypeFilter } from '../StorageExplorerFileTypeFilter';
import { StorageExplorerPickerAlert } from '../StorageExplorerPickerAlert';

export const StorageExplorerToolbar = () => {
  return (
    <Stack
      data-testid="storage-explorer-toolbar"
      gap={2}>
      <StorageExplorerPickerAlert />
      <Stack
        direction="row"
        gap={1}
        sx={{
          padding        : 1.25,
          borderRadius   : 1,
          backgroundColor: t => t.palette.background['200'],
        }}>
        <StorageExplorerFileSearch />
        <Spacer />
        <StorageExplorerFileTypeFilter />
      </Stack>
    </Stack>
  );
};
