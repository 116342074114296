import type { StorageProviderKey } from '../../types';
import { useCallback, useMemo } from 'react';
import providerService from '../services/providers';
import { useNewCloudStorageStore } from './useCloudStorageStore';

export function useProvider(key: StorageProviderKey) {
  const { defaultProvider, providers } = useNewCloudStorageStore();
  const isSignedIn = useMemo(() => Boolean(providers[key]), [providers, key]);
  const account = useMemo(() => providers[key]?.account, [providers, key]);
  const isDefault = useMemo(() => defaultProvider === key, [defaultProvider, key]);

  const handleToggleAuth = useCallback(() => {
    isSignedIn ? providerService.signOut(key) : providerService.signIn(key);
  }, [isSignedIn, key]);

  const handleToggleDefault = useCallback(() => {
    providerService.toggleDefaultProvider(key);
  }, [isDefault, key]);

  return {
    account,
    isDefault,
    isSignedIn,
    toggleAuth   : handleToggleAuth,
    toggleDefault: handleToggleDefault,
  };
}
