import {
  Button,
  CheckedOutOutlinedIcon,
  Surface,
  Tooltip,
  styled,
} from '@mtb/ui';
import { CheckoutStatus } from '../../../../CheckoutStatus';
import { useStorageExplorerTable } from '../../hooks';
import { useCheckoutUser } from './hooks';

const CheckoutUserTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }} />
))(() => ({
  '& .mtb-ui-tooltip': {
    marginTop      : 0,
    maxWidth       : 'unset',
    backgroundColor: 'transparent',
  },
}));

export function StorageExplorerTableCheckedOutUserTooltip() {
  const { tableContainerRef } = useStorageExplorerTable();
  const { checkoutUserName, isFetching } = useCheckoutUser();

  if (!checkoutUserName) {
    return null;
  }

  return (
    <>
      <CheckoutUserTooltip
        disableArrow
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name   : 'preventOverflow',
              options: {
                boundary: tableContainerRef?.current,
              },
            },
            {
              name   : 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        }}
        title={
          <Surface
            data-testid="checkout-status-popper"
            sx={{ p: 2 }}>
            <CheckoutStatus
              checkoutUserName={checkoutUserName}
              loading={isFetching} />
          </Surface>
        }>
        <Button
          as="div"
          color="default"
          data-testid="checkout-status-row-action"
          disableRipple
          icon={<CheckedOutOutlinedIcon size="small" />}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          variant="text" />
      </CheckoutUserTooltip>
    </>
  );
}
