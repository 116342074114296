import { createFetchWrapper, FetchWrapper as DefaultFetchWrapper } from '@mtb/utilities';

const FetchWrapper = createFetchWrapper({
  DEFAULT_NUMBER_OF_RETRIES: 1,
  getResponseData(res, type) {
    // 304 is for an eTag match, so this is OK
    if (res.status === 304) {
      return {};
    }
    return DefaultFetchWrapper.getResponseData(res, type);
  },
  responseInterceptors: [
    response => {
      // 304 is for an eTag match, so this is OK
      if (response.status === 304) {
        response.ok = true;
      }
      return response;
    },
  ],
});

export const paddedExpiration = tokenExpiresIn => Date.now() + (tokenExpiresIn - 300) * 1000;

/**
 * @template {import('@mtb/utilities/dist/types/FetchWrapper').HttpVerbs} Verb
 * @template {import('@mtb/utilities/dist/types/FetchWrapper').ResponseBodyTypes} [Type="json"]
 * @typedef RequestConfig
 * @property {string} url
 * @property {Verb} [verb="GET"]
 * @property {import('@mtb/utilities/dist/types/FetchWrapper').RequestOptions<Verb, Type>['body']} [body]
 * @property {Type} [type="json"]
 * @property {boolean} [skipAuthRetry=false]
 * @property {(response: import('@mtb/utilities/dist/types/FetchWrapper').RequestResponseBody<Type>) => import('@mtb/utilities/dist/types/FetchWrapper').RequestResponseBody<Type>} [transform]
 * @property {string} [cacheId]
 * @property {AbortSignal} [signal]
 */

/**
 * Makes an authorized API call using the provided configuration and authentication token.
 * @this {import('./ProviderBase').ProviderBase}
 * @template {import('@mtb/utilities/dist/types/FetchWrapper').HttpVerbs} Verb
 * @template {import('@mtb/utilities/dist/types/FetchWrapper').ResponseBodyTypes} [Type="json"]
 * @param {RequestConfig<Verb, Type>} config
 * @param {import('@').InterceptedAuthResponse} [auth]
 * @returns {Promise<import('@mtb/utilities/dist/types/FetchWrapper').RequestResponseBody<Type>>}
 */
export async function authorizedApiCall(config, { access_token = '', scope = '' } = {}) {
  const headers = { Authorization: `Bearer ${access_token}` };
  // If we have a possible cache, add if check in headers
  const existingItem = this.getCachedItem(config.cacheId);
  if (existingItem?.eTag) {
    headers['If-None-Match'] = existingItem.eTag;
  }

  try {
    const response = await FetchWrapper.request({
      resource    : config.url,
      verb        : config.verb || 'GET',
      headers,
      body        : config.body,
      type        : config.type || 'json',
      fetchOptions: {
        signal: config.signal,
      },
    });

    if (config.signal?.aborted) {
      return null;
    }

    // If eTags match we can return cached item, or if no content, return nothing
    if (response.status === 304) {
      response.data = existingItem;
    } else if (response.status === 204) {
      response.data = undefined;
    } else if (response.status === 202) {
      response.data = response;
    }
    let result = response.data;
    if (typeof config.transform === 'function') {
      result = config.transform(result);
    }

    // When renaming a gdrive project, we must set the cached item as the
    // response object for the new name to update in the file picker immediately.
    // retValue will be the fully transformed item on all other api calls besides rename project
    const gdriveRename = typeof retValue !== 'object' && scope.includes('google') && config.body?.name;
    const itemToCache = gdriveRename ? response.data : result;
    if (typeof itemToCache === 'object') {
      this.setCachedItem(itemToCache);
    }
    return result;
  } catch (err) {
    if (config.signal?.aborted) {
      return null;
    }
    /** @type {Error | import('@mtb/utilities/dist/types/FetchWrapper').RequestResponse<Type>} */
    const error = err;
    if (
      !config.skipAuthRetry &&
      [`${error}`, error.status, error.statusText, error.data?.error, error.data?.error?.code].some(
        reason => reason === 'InvalidAuthenticationToken' || reason === 401 || reason === 403,
      )
    ) {
      throw { needsAuthentication: true }; // eslint-disable-line no-throw-literal
    }
    // Rate limiting for Google
    if (error.data?.error?.errors?.[0]?.reason === 'userRateLimitExceeded') {
      throw { needsTimedRetry: true }; // eslint-disable-line no-throw-literal
    }
    if (error.data?.error?.code === 'nameAlreadyExists') {
      throw new Error('Naming Conflict');
    }
    throw error;
  }
}
