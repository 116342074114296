import { DEFAULT_FIELD_OPTIONS_MAP } from './constants';

/**
 * @typedef MetaProperties
 * @property {boolean} isHidden
 * @property {boolean} isRequired
 * @property {boolean} isReadOnly
 * @property {boolean} [isFieldGroup]
 * @property {boolean} [isFieldSet]
 * @property {import('./keywords/dynamicDefaults').DynamicDefault} [dynamicDefault]
 * @property {import('./keywords/asyncDynamicDefaults').AsyncDynamicDefault} [asyncDynamicDefault]
 */

/** @type {MetaProperties} */
const METAPROPERTIES = {
  isHidden           : false,
  isRequired         : false,
  isReadOnly         : false,
  isFieldGroup       : false,
  isFieldSet         : false,
  dynamicDefault     : undefined,
  asyncDynamicDefault: undefined,
};

/**
 * @typedef FieldSchema
 * @property {import('./constants').KeyOfDefaultFieldOptionsMap} type
 * @property {Record<string, FieldSchema>} [properties]
 * @property {FieldSchema[]} [items]
 * @property {string[]} [required]
 * @property {number} [minItems]
 * @property {boolean} [additionalItems]
 * @property {import('./keywords/dynamicDefaults').DynamicDefault} [dynamicDefaults]
 * @property {import('./keywords/asyncDynamicDefaults').AsyncDynamicDefault} [asyncDynamicDefaults]
 * @property {string} $comment
 */

export class Field {
  /**
   *****************************************************************************
   * Protected Properties
   *****************************************************************************
   */

  /** @type {import('./Schema').Schema|null} */
  _root = null;

  /** @type {FieldSchema} */
  _schema = { type: 'null', $comment: '' };

  /** @type {MetaProperties|null} */
  _metaProperties = null;

  /**
   *****************************************************************************
   * Public Methods
   *****************************************************************************
   */

  /**
   * Creates a new field object
   * @param {import('./constants').KeyOfDefaultFieldOptionsMap} [type]
   * @param {import('./constants').ValueOfDefaultFieldOptionsMap} [options]
   */
  constructor(type = 'object', options = undefined) {
    const fieldOptions = Object.assign({}, DEFAULT_FIELD_OPTIONS_MAP[type], options ?? {});
    const fieldProperties = Object.fromEntries(
      Object.entries(fieldOptions).filter(([key]) => !METAPROPERTIES.hasOwnProperty(key)),
    );
    /** @type {any} */
    const metaProperties = Object.fromEntries(
      Object.entries(fieldOptions).filter(([key]) => METAPROPERTIES.hasOwnProperty(key)),
    );

    this._schema = {
      type,
      ...(type === 'object' && { properties: {}, required: [] }),
      ...(type === 'array' && {
        items          : [new Field('null')._schema],
        minItems       : metaProperties?.isRequired ? 1 : 0,
        additionalItems: false,
      }),
      ...fieldProperties,
      $comment: JSON.stringify(metaProperties),
    };
    this._metaProperties = metaProperties;
  }
}

export default Field;
