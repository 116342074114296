import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEventListener, usePrevious } from '@mtb/ui';
import { debounce } from '@mtb/utilities';
import { DEFAULT_CLOUD_STORAGE_CATEGORY, CLOUD_STORAGE_CATEGORIES } from '../../../constants';
import {
  useCloudStorageStore,
  useAllCloudStorageItems,
  useRecentCloudStorageItems,
  useSharedCloudStorageItems,
  useSessionStorage,
} from '../../../hooks';
import { getIsItemRoot } from '../../../utils';
import { useBreadcrumbTrail } from './useBreadcrumbTrail';

/**
 * Custom hook that provides state and functions for managing storage explorer items.
 * @param {Object} params - The parameters for the hook.
 * @param {string} id - The unique identifier for the storage explorer items.
 * @param {import('../../../constants').CloudStorageCategories} defaultCategory - The default category for storage explorer items.
 * @param {boolean} disableCache - Flag to disable caching data to session storage.
 * @param {import('@').StorageProviderItem} defaultFolder - The default folder for storage explorer items.
 * @param {string[]} filter - An array of filters to apply to the items.
 * @returns {import('@').UseStorageExplorerItems} An object containing state and functions for managing storage explorer items.
 */
export const useStorageExplorerItems = ({
  id,
  defaultCategory = DEFAULT_CLOUD_STORAGE_CATEGORY,
  disableCache = false,
  defaultFolder,
  filter = [],
} = {}) => {
  // Cloud Storage State
  const cloudStorage = useCloudStorageStore();
  const [category, setCategory] = useSessionStorage({
    noun        : 'category',
    id,
    initialValue: /** @type {DefaultCategory} */ defaultCategory,
    disableCache,
  });
  const [folder, setFolder] = useSessionStorage({
    noun        : `${cloudStorage.type || 'local'}-folder`,
    id,
    initialValue: defaultFolder,
    disableCache,
  });
  const allItems = useAllCloudStorageItems({ id, filter, disableCache });
  const recentItems = useRecentCloudStorageItems({ id, filter, disableCache });
  const sharedItems = useSharedCloudStorageItems({ id, filter, disableCache });
  // Local State
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const breadcrumbTrail = useBreadcrumbTrail(id, folder, disableCache);
  // Previous values
  const prevType = usePrevious(cloudStorage.type);
  const prevDefaultFolder = usePrevious(defaultFolder);
  // Combine all the items into categories.
  const categories = useMemo(
    () => ({
      [CLOUD_STORAGE_CATEGORIES.ALL]   : allItems,
      [CLOUD_STORAGE_CATEGORIES.RECENT]: recentItems,
      [CLOUD_STORAGE_CATEGORIES.SHARED]: sharedItems,
    }),
    [allItems, recentItems, sharedItems],
  );
  // Get the items for the selected category.
  const items = useMemo(() => categories[category].items, [categories, category]);

  /**
   * Handles setting the selected item.
   * @param {boolean} selected - The selected state.
   */
  const handleSetSelected = useCallback(selected => setSelected(selected), []);

  /**
   * Handles setting the loading state.
   * @param {boolean} isLoading - The loading state.
   */
  const handleSetIsLoading = useCallback(isLoading => setIsLoading(isLoading), []);

  /**
   * Shared function to handle loading state, resetting state, and updating items.
   * @param {Object | null} [nextFolder] - The next folder to set.
   * @param {string | null} [nextCategory] - The next category to set.
   * @param {Object} [options={}] - Additional options.
   * @param {boolean} [options.force=false] - Whether to force the update even if the category is the same.
   * @param {boolean} [options.silent=false] - If true, do not update the loading state and update the items silently.
   * @returns {void}
   */
  const handleChangeItems = useCallback(
    async (nextFolder, nextCategory, { force = false, silent = false } = {}) => {
      const folder = getIsItemRoot(nextFolder) ? null : nextFolder;

      if (!folder && (!nextCategory || !categories[nextCategory])) {
        throw new Error(`Unknown category: ${nextCategory}`);
      }

      // If the category is the same and there is no folder, we don't need to update,
      // unless the force flag is set.
      if (!force && nextCategory === category && !folder) {
        handleSetIsLoading(false);
        return;
      }

      if (!silent) {
        handleSetIsLoading(true);
      }

      setSelected(null);
      setFolder(folder);
      setCategory(nextCategory);

      categories[category].cancel();

      if (folder) {
        await categories[nextCategory].fetch(folder);
      } else {
        await categories[nextCategory].fetch();
      }

      handleSetIsLoading(false);
    },
    [categories, category, handleSetIsLoading, setCategory, setFolder],
  );

  /**
   * Handles setting the category and loading items based on the selected category.
   * @param {string | null} nextCategory - The next category to set.
   * @param {Object} [options={}] - Additional options passed to handleChangeItems.
   * @param {boolean} [options.force=false] - Whether to force the update even if the category is the same.
   * @param {boolean} [options.silent=false] - If true, do not update the loading state and update the items silently.
   * @returns {void}
   */
  const handleSetCategory = useCallback(
    async (nextCategory, options = {}) => {
      await handleChangeItems(null, nextCategory, options);
    },
    [handleChangeItems],
  );

  /**
   * Handles setting the folder and loading items based on the selected folder.
   * @param {Object | null} nextFolder - The next folder to set.
   * @param {Object} [options={}] - Additional options passed to handleChangeItems.
   * @param {boolean} [options.force=false] - Whether to force the update even if the category is the same.
   * @param {boolean} [options.silent=false] - If true, do not update the loading state and update the items silently.
   * @returns {void}
   */
  const handleSetFolder = useCallback(
    async nextFolder => {
      await handleChangeItems(nextFolder, category ?? defaultCategory, { force: true });
    },
    [category, defaultCategory, handleChangeItems],
  );

  /**
   * Handles refreshing the current set of items.
   * @param {Object} [options={}] - Options for refreshing.
   * @param {boolean} [options.force=true] - Whether to force the update.
   * @param {boolean} [options.silent=false] - If true, do not update the loading state and update the items silently.
   * @returns {void}
   */
  const handleRefresh = useCallback(
    async ({ force = true, silent = false } = {}) => {
      if (!silent) {
        handleSetIsLoading(true);
      }

      await cloudStorage.verifyAutoSaveFolder();
      await handleChangeItems(folder ?? defaultFolder, category ?? defaultCategory, { force, silent });

      if (!silent) {
        handleSetIsLoading(false);
      }
    },
    [category, cloudStorage, defaultCategory, defaultFolder, folder, handleChangeItems, handleSetIsLoading],
  );

  /**
   * Handles navigating back to the previous folder.
   * @returns {void}
   */
  const handleBack = useCallback(() => {
    // To determine the previous folder, we need to look at the second-to-last breadcrumb.
    // because the last breadcrumb is the current folder, and the second-to-last breadcrumb
    // is the previous folder we want to navigate to.
    const prevFolder = breadcrumbTrail.at(-2);
    if (prevFolder?.name !== 'root') {
      handleSetFolder(prevFolder);
    } else {
      handleSetFolder(null);
    }
  }, [breadcrumbTrail, handleSetFolder]);

  // Effect to handle updating items when the default folder changes.
  useEffect(() => {
    if (defaultFolder && defaultFolder?.id !== prevDefaultFolder?.id) {
      handleChangeItems(defaultFolder, category ?? defaultCategory, { force: true });
    }
  });

  // Effect to handle updating items when the provider type changes.
  useEffect(() => {
    if (cloudStorage.type && prevType !== cloudStorage.type) {
      handleChangeItems(folder, category, { force: true, silent: items.length });
    }
  }, [category, cloudStorage.type, folder, handleChangeItems, items.length, prevType]);

  // Effect to handle updating items when the window becomes visible.
  useEventListener(
    'visibilitychange',
    debounce(() => {
      if (document.visibilityState === 'visible') {
        handleRefresh({ silent: Boolean(items.length) });
      }
    }, 250),
  );

  return {
    folder,
    category,
    items,
    selected,
    isLoading,
    breadcrumbTrail,
    setIsLoading,
    setFolder  : handleSetFolder,
    setCategory: handleSetCategory,
    setSelected: handleSetSelected,
    refresh    : handleRefresh,
    back       : handleBack,
  };
};
