import {
  Avatar,
  Button,
  FormControl,
  LoginIcon,
  LogoutIcon,
  Spacer,
  Stack,
  Typography,
} from '@mtb/ui';
import { useProvider } from '../../../../hooks';
import { useTranslation } from '../../../../services/i18n';
import { useStorageExplorer } from '../../hooks';

const NotSignedIn = () => {
  const [t] = useTranslation();
  return (
    <Typography variant="title-sm" weight="medium">
      {t('connection.notSignedIn')}
    </Typography>
  );
};

const ProviderAccount = ({ account }) => {
  return (
    <Stack alignItems="center" direction="row" gap={1} mr={1}>
      <Avatar
        alt={account?.name}
        data-testid="provider-account-avatar"
        src={account?.avatar}
      />
      <Stack>
        <Typography data-testid="provider-account-name" variant="title-sm">
          {account?.name}
        </Typography>
        <Typography data-testid="provider-account-email">
          {account?.email}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ProviderAccountSettings = () => {
  const [t] = useTranslation();
  const { provider, disableProviderSettings } = useStorageExplorer();
  const { account, isSignedIn, toggleAuth } = useProvider(provider);

  return (
    <FormControl as="fieldset">
      <Typography as="legend" variant="title-md" weight="semibold">
        {t('connection.account')}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flex="1 0 auto"
        flexWrap="wrap"
        gap={1}
        p={2}>
        {isSignedIn ? <ProviderAccount account={account} /> : <NotSignedIn />}
        <Spacer />
        <Button disabled={disableProviderSettings && isSignedIn} color="default" variant="contained" onClick={toggleAuth}>
          {isSignedIn ? <LogoutIcon /> : <LoginIcon />}
          {t(isSignedIn ? 'connection.signOut' : 'connection.signIn2')}
        </Button>
      </Stack>
    </FormControl>
  );
};

export default ProviderAccountSettings;
