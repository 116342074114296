import layout from './layout';
import planes from './planes';
import snackbar from './snackbar';
import user from './user';

const selectors = {
  ...layout,
  ...planes,
  ...user,
  ...snackbar,
} as const;

export default selectors;
