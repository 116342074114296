// @ts-check
import { createContext } from 'react';
import { DEFAULT_PROVIDERS } from '../../constants';

export const initialState = /** @type {import('@').StorageExplorerContext} */ ({
  providers      : DEFAULT_PROVIDERS,
  filter         : [],
  maxSize        : Infinity,
  disableCache   : false,
  folder         : null,
  category       : null,
  items          : [],
  selected       : null,
  isLoading      : false,
  breadcrumbTrail: [],
  dense          : false,
  openItem       : () => Promise.resolve(false),
  openFile       : () => Promise.resolve(),
  setIsLoading   : () => undefined,
  setFolder      : () => undefined,
  setCategory    : () => undefined,
  setSelected    : () => undefined,
  refresh        : () => undefined,
  back           : () => undefined,
});

export const StorageExplorerContext = createContext(initialState);
