import { _, str } from 'ajv/dist/compile/codegen';

/** @type {import('ajv').KeywordErrorDefinition} */
const error = {
  message: ({ schemaCode }) => str`must include string "${schemaCode}"`,
  params : ({ schemaCode }) => _`{includes: ${schemaCode}}`,
};

/**
 * @returns {import('ajv').CodeKeywordDefinition}
 */
export default function stringIncludes() {
  return {
    keyword   : 'includes',
    type      : 'string',
    schemaType: 'string',
    error,
    /**
     * @param {import('ajv').KeywordCxt} cxt
     */
    code(cxt) {
      const { data, schema } = cxt;
      cxt.pass(_`${data}.includes(${schema})`);
    },
  };
}
