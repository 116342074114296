import type { Snackbar } from '../../store/reducers/snackbar/types';
import { platformStore, useSelector } from '../../store';

class SnackbarService {
  /**
   * Opens the snackbar.
   */
  openSnackbar() {
    platformStore.actions.openSnackbar();
  }

  /**
   * Closes the snackbar.
   */
  closeSnackbar() {
    platformStore.actions.closeSnackbar();
  }

  /**
   * Adds a snack (toast) to the snack pack in the store to be displayed in the Platform client.
   * @param snackbar - The snack to add.
   */
  showSnackbar(snackbar: Snackbar) {
    platformStore.actions.addSnack(snackbar);
  }

  /**
   * Removes the current snack.
   */
  removeSnack() {
    platformStore.actions.removeSnack();
  }

  /**
   * Hook to use the snackbar.
   * @returns The snack pack, snack, and whether the snackbar is open.
   */
  useSnackbar() {
    const snackPack = useSelector(platformStore.selectors.snackPack);
    const snack = useSelector(platformStore.selectors.snack);
    const isOpen = useSelector(platformStore.selectors.isSnackbarOpen);
    return {
      snackPack,
      snack,
      isOpen,
    };
  }
}

export default SnackbarService;
