import { getItemTimestamps, getNameParts } from '../../utils';
import { ProviderBase } from '../ProviderBase';

const graphEndpoint = 'https://graph.microsoft.com/v1.0';

const dirTransform = ({ value }) => {
  return value.map(itemTransform);
};

/**
 * @returns {import('@').StorageProviderItem}
 */
const itemTransform = item => {
  const parentReference = item.parentReference || item.remoteItem?.parentReference || {};
  const root = Boolean(item.folder) && item.name === 'root';
  const nameParts = getNameParts(item.name);
  const timestamps = getItemTimestamps(item);
  const webUrl = item.listItem?.webUrl || item.webUrl;

  return {
    ...nameParts,
    ...timestamps,
    id      : item.id,
    driveId : parentReference.driveId,
    parentId: item.parentReference?.path?.endsWith('root:')
      ? 'root'
      : item.parentReference?.id || item.remoteItem?.parentReference?.id,
    parentFolderUrl: Boolean(item.folder) ? webUrl : webUrl.replace(/\/[^/]+$/, ''),
    size           : item.size,
    folder         : Boolean(item.folder),
    createdDateTime: item.createdDateTime,
    createdBy      : { name: item.listItem?.createdBy?.user?.displayName || item.createdBy?.user?.displayName },
    parentReference: {
      id     : parentReference.id,
      driveId: parentReference.driveId,
      siteId : parentReference.siteId,
    },
    mimeType: item.file?.mimeType,
    root,
  };
};

export class OneDriveProviderAPI extends ProviderBase {
  /** @type {ProviderBase['getRootWebUrl']} */
  getRootWebUrl() {
    return 'https://www.onedrive.com';
  }

  /** @type {ProviderBase['_getRootInfo']} */
  async _getRootInfo() {
    const { webUrl } = await this.authorizedApiCall({
      url: `${graphEndpoint}/me/drive`,
    });
    return webUrl;
  }

  /** @type {ProviderBase['_getShareUrl']} */
  async _getShareUrl(id, driveId) {
    const { siteUrl, listId, listItemId } = await this.authorizedApiCall({
      url: `${graphEndpoint}/drives/${driveId}/items/${id}/sharepointIds`,
    });
    return `${siteUrl}/_layouts/15/sharedialog.aspx?listId={${listId}}&listItemId=${listItemId}&clientId=odb&ma=1`;
  }

  /** @type {ProviderBase['renameItem']} */
  renameItem(id, driveId, name) {
    return this.wrappedApiCall({
      url : `${graphEndpoint}/drives/${driveId}/items/${id}?@microsoft.graph.conflictBehavior=rename`,
      verb: 'PATCH',
      body: {
        name,
      },
      transform: item => item.name,
    });
  }

  /** @type {ProviderBase['getBreadcrumbs']} */
  async getBreadcrumbs(id, driveId) {
    let item = await this.getItemById(id, driveId);
    let done = item.parentId === 'root';
    const rootBreadcrumb = { id: 'root', webUrl: await this._getRootInfo() };
    const breadCrumbs = [];

    while (!done) {
      item = await this.getItemById(item.parentId, driveId);
      breadCrumbs.push(item);
      if (item.parentId === 'root' || item.error) {
        done = true;
        break;
      }
    }

    return [...breadCrumbs, rootBreadcrumb].reverse();
  }

  /** @type {ProviderBase['getFolderBreadcrumbs']} */
  async getFolderBreadcrumbs(id, driveId) {
    const rootBreadcrumb = { id: 'root', webUrl: await this._getRootInfo() };
    if (id === 'root') {
      return [rootBreadcrumb];
    }
    let item = await this.getItemById(id, driveId);
    const breadCrumbs = [item];
    while (item.parentId !== 'root') {
      item = await this.getItemById(item.parentId, driveId);
      breadCrumbs.push(item);
    }
    return [...breadCrumbs, rootBreadcrumb].reverse();
  }

  /** @type {ProviderBase['moveItem']} */
  async moveItem(id, driveId, parentId) {
    await this.wrappedApiCall({
      verb: 'PATCH',
      body: {
        parentReference: {
          id: parentId,
        },
      },
      url: `${graphEndpoint}/drives/${driveId}/items/${id}`,
    });
    return true;
  }

  /** @type {ProviderBase['createDefaultItem']} */
  createDefaultItem(name) {
    return this.wrappedApiCall({
      url: `${graphEndpoint}/me/drive/items/${this.getAutoSaveFolder().id}:/${encodeURIComponent(
        name,
      )}:/content?@microsoft.graph.conflictBehavior=rename&expand=listItem`,
      verb     : 'PUT',
      body     : {},
      transform: itemTransform,
    });
  }

  /** @type {ProviderBase['createInFolder']} */
  createInFolder(name, folder, conflictBehavior = 'rename') {
    return this.wrappedApiCall({
      url: `${graphEndpoint}/me/drive/items/${folder.id}:/${encodeURIComponent(
        name,
      )}:/content?@microsoft.graph.conflictBehavior=${conflictBehavior}&expand=listItem`,
      verb     : 'PUT',
      body     : {},
      transform: itemTransform,
    });
  }

  /** @type {ProviderBase['getAccount']} */
  getAccount(skipAuthRetry = false, auth = undefined) {
    return this.authorizedApiCall(
      {
        skipAuthRetry,
        url      : `${graphEndpoint}/me`,
        transform: account => ({
          name   : account.displayName,
          email  : account.mail,
          id     : account.id,
          picture: '',
        }),
      },
      auth,
    );
  }

  /** @type {ProviderBase['getAbout']} */
  getAbout(field = '*') {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/me/drive?select=${field}`,
      transform: fields => (field === '*' ? fields : fields[field]),
    });
  }

  /** @type {ProviderBase['getRootChildren']} */
  getRootChildren(signal) {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/me/drive/root/children?expand=listItem`,
      transform: dirTransform,
      signal,
    });
  }

  /**
   * @type {ProviderBase['getRecent']}
   */
  async getRecent(overrideFilter, signal) {
    const [response, rootResponse] = await Promise.all([
      this.makeApiCall({
        url: `${graphEndpoint}/me/drive/search(q='${
          overrideFilter ? overrideFilter.join(' OR ') : this.filter
        }')?orderby=lastModifiedDateTime%20desc&top=25`,
        signal,
      }),
      this.makeApiCall({
        url: `${graphEndpoint}/me/drive/root?select=id`,
        signal,
      }),
    ]);
    if (signal?.aborted) {
      return null;
    }
    const { id: rootItemId = '' } = rootResponse;
    if (response.error) {
      return response;
    }
    const recentFiles = (Array.isArray(response) ? response : response.value)
      .filter(item => !item.folder)
      .map(item => {
        // If the item doesn't have a parentReference or the parentReference doesn't
        // have a path, add one based on if the parent is the root. `itemTransform`
        // checks if the parentReference's path endsWith 'root:' to set the `parentId`
        item.parentReference ||= {};
        item.parentReference.path ||= rootItemId === item.parentReference.id ? 'root:' : '';
        const transformedItem = itemTransform(item);
        return transformedItem;
      });
    if (signal?.aborted) {
      return null;
    }
    // This search workaround to get recent files takes ~30sec or more to get an updated result
    // Filter or add recently deleted/duplicated files via sessionStorage
    const deletedFiles = JSON.parse(sessionStorage.getItem('deletedFiles'));
    const duplicatedFiles = JSON.parse(sessionStorage.getItem('duplicatedFiles'));
    const recentItems = recentFiles.filter(item => !deletedFiles?.includes(item.id));
    if (duplicatedFiles?.length) {
      duplicatedFiles.forEach(file => {
        if (!recentItems.some(item => item.id === file.id) && !deletedFiles?.includes(file.id)) {
          recentItems.push(file);
        }
      });
    }
    return recentItems;
  }

  /** @type {ProviderBase['getShared']} */
  async getShared(signal) {
    const response = await this.makeApiCall({
      url: `${graphEndpoint}/me/drive/sharedWithMe`,
      signal,
    });
    if (signal?.aborted) {
      return null;
    }
    const sharedFiles = (Array.isArray(response) ? response : response.value).map(item => {
      // If the item doesn't have a parentReference or the parentReference doesn't
      // have a path, add one based on if the parent is the root. `itemTransform`
      // checks if the parentReference's path endsWith 'root:' to set the `parentId`
      item.parentReference = item.remoteItem?.parentReference || item.parentReference || {};
      item.parentReference.path ||= '';
      item.createdBy ||= item.remoteItem?.shared?.sharedBy;
      const transformedItem = itemTransform(item);
      return transformedItem;
    });
    if (signal?.aborted) {
      return null;
    }
    return sharedFiles;
  }

  /** @type {ProviderBase['getFolderChildren']} */
  getFolderChildren(folderId, driveId, signal) {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/drives/${driveId}/items/${folderId}/children?expand=listItem`,
      transform: dirTransform,
      signal,
    });
  }

  /** @type {ProviderBase['getItemById']} */
  getItemById(id, driveId, useCache = true, params = '?expand=listItem') {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/drives/${driveId}/items/${id}${params}`,
      transform: itemTransform,
      cacheId  : useCache && id,
    });
  }

  /** @type {ProviderBase['searchItem']} */
  searchItem(text, pageSize = 25, options = '') {
    return this.wrappedApiCall({
      url      : `${graphEndpoint}/me/drive/search(q='${text}')?$top=${pageSize}&${options}`,
      transform: dirTransform,
    });
  }

  /** @type {ProviderBase['getUser']} */
  getUser(principalName, params = '') {
    return this.wrappedApiCall({ url: `${graphEndpoint}/users/${principalName}?${params}` });
  }

  /** @type {ProviderBase['getUserPhoto']} */
  getUserPhoto(principalName) {
    return this.wrappedApiCall({ url: `${graphEndpoint}/users/${principalName}/photo/$value`, type: 'blob' });
  }

  /** @type {ProviderBase['getCheckoutUser']} */
  async getCheckoutUser({ id, driveId }) {
    if (!id || !driveId) {
      return undefined;
    }

    try {
      const { publication } = await this.makeApiCall({
        url: `${graphEndpoint}/drives/${driveId}/items/${id}?select=publication`,
      });

      if (!publication?.checkedOutBy) {
        return undefined;
      }

      const user = await this.getUser(publication.checkedOutBy.user.id);
      const pictureBinary = await this.getUserPhoto(publication.checkedOutBy.user.id);
      return { ...user, email: user.mail, photo: pictureBinary && URL.createObjectURL(pictureBinary) };
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(error);
      }
      return undefined;
    }
  }

  /** @type {ProviderBase['duplicateItem']} */
  async duplicateItem(item, name) {
    const response = await this.authorizedApiCall({
      url : `${graphEndpoint}/drives/${item.driveId}/items/${item.id}/copy?@microsoft.graph.conflictBehavior=rename`,
      verb: 'POST',
      body: {
        parentReference: item.parentReference,
        name           : name ?? item.name,
      },
    });
    if (response.error) {
      return response;
    }
    const location = response.headers.get('location');
    let progress;
    do {
      progress = await fetch(location).then(response => {
        return response.json();
      });
    } while (!progress?.resourceId);
    const duplicateItem = await this.getItemById(progress.resourceId, item.driveId);

    if (item.parentId) {
      // Save duplicated file in session storage to add to files list immediately after refresh
      const duplicatedFiles = JSON.parse(sessionStorage.getItem('duplicatedFiles'));
      sessionStorage.setItem(
        'duplicatedFiles',
        JSON.stringify(duplicatedFiles ? [...duplicatedFiles, duplicateItem] : [duplicateItem]),
      );
    }
    return duplicateItem;
  }

  /** @type {ProviderBase['deleteItem']} */
  deleteItem(item) {
    // Save deleted file in session storage to filter from file list after refresh
    const deletedFiles = JSON.parse(sessionStorage.getItem('deletedFiles'));
    sessionStorage.setItem('deletedFiles', JSON.stringify(deletedFiles ? [...deletedFiles, item.id] : [item.id]));
    return this.wrappedApiCall({
      url : `${graphEndpoint}/drives/${item.driveId}/items/${item.id}`,
      verb: 'DELETE',
    });
  }

  /** @type {ProviderBase['checkInItem']} */
  checkInItem(item) {
    return this.wrappedApiCall({
      url : `${graphEndpoint}/drives/${item.driveId}/items/${item.id}/checkin`,
      verb: 'POST',
    });
  }

  /** @type {ProviderBase['getRemainingStorageSpace']} */
  async getRemainingStorageSpace() {
    const storage = await this.getAbout();
    return storage.quota?.remaining;
  }

  async getOneDriveItemFromShareURL(url) {
    return await this.wrappedApiCall({ url, transform: itemTransform });
  }
}
