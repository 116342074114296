// Used to match property names within property paths.
const rePropName = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g;
// Used to match backslashes in property paths.
const reEscapeChar = /\\(\\)?/g;

/**
 * @template {string | string[]} Path
 * @param {Path} path
 * @returns {Path extends string ? import('./types').ToPath<Path> : Path}
 */
export const toPath = path => {
  if (Array.isArray(path)) {
    return /** @type {Path extends string ? never : Path} */ (path);
  }
  const result = [];
  if (path.charCodeAt(0) === 46) {
    result.push('');
  }
  // @ts-ignore
  path.replace(rePropName, (match, number, quote, subString) => {
    result.push(quote ? subString.replace(reEscapeChar, '$1') : (number || match));
  });
  return /** @type {Path extends string ? import('./types').ToPath<Path> : Path} */ (result);
};

export default toPath;
