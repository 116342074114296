import { _, str } from 'ajv/dist/compile/codegen';

/** @type {import('ajv').KeywordErrorDefinition} */
const error = {
  message: ({ schema }) => str`must be ${schema}`,
  params : ({ parentSchema, schemaValue }) =>
    typeof parentSchema?.type === 'string' ? _`{type: ${parentSchema?.type}}` : _`{type: ${schemaValue}}`,
};

/**
 * @returns {import('ajv').CodeKeywordDefinition}
 */
export default function strictNumeric() {
  return {
    keyword   : 'strictNumeric',
    type      : 'number',
    schemaType: 'boolean',
    error,
    /**
     * @param {import('ajv').KeywordCxt} cxt
     */
    code(cxt) {
      const { data, schema } = cxt;
      if (schema === false) {
        cxt.pass(_`true`);
      } else {
        cxt.pass(_`Number.isFinite(${data}) && !Number.isNaN(${data})`);
      }
    },
  };
}
