import type ModuleConfig from '../../module-config';
import type Events from '../../module-config/Events';
import type { ModuleConfigKey } from '../modules/types';
import type { Plane } from '../store/reducers/planes';
import type { OnOpenFileInfo } from '../types';
import { waitForCondition } from '@mtb/utilities';
import { ModuleClient } from '../../clients';

/**
 * @deprecated Use v3 init-remote-module init api instead.
 * @todo Remove this class once everyone has switched to the v3 init-remote-module api or higher.
 */
export class EventHandler {
  #WAIT_FOR_MODULE_TIMEOUT = 15000;

  #moduleEvents: Map<ModuleConfig['key'], ModuleConfig['events']>;

  #queuedEvents: Map<ModuleConfig['key'], Array<() => Promise<unknown>>>;

  constructor() {
    this.#moduleEvents = new Map();
    this.#queuedEvents = new Map();
  }

  /**
   * Registers events for a module.
   * @param moduleConfig - The module name.
   */
  register(moduleConfig: ModuleConfig) {
    this.#moduleEvents.set(moduleConfig.key, moduleConfig.events);
    this.#flushEvents(moduleConfig.key);
  }

  /**
   * Flushes all queued events.
   */
  async #flushEvents(module: ModuleConfigKey): Promise<void> {
    const queuedEvents = this.#queuedEvents.get(module);
    while (queuedEvents?.length) {
      await queuedEvents.shift()?.();
    }
  }

  /**
   * Events can't garuntee that the module is loaded, so we may need to optionally queue the event
   * @param plane - the plane the event was fired on
   * @param event - the "on" event name
   * @param args - additional arguments to pass to the event
   */
  async #fireOrQueueEvent(plane: Plane, event: keyof Events, ...args: unknown[]): Promise<void> {
    // Use the new ModuleEventManager if module initialized with v3 init api.
    if (ModuleClient.getRemoteModuleConfig(plane.module)) {
      const eventName = event.slice(2).toLowerCase() as 'pulse' | 'cleanup' | 'close' | 'flush' | 'open';
      // @ts-expect-error TS doesn't like the spread args here
      ModuleClient.Events[eventName]?.(plane, ...args);
      return;
    }

    // @ts-expect-error TS doesn't like the spread args here
    const fireEvent = async () => await this.#moduleEvents.get(plane.module)?.[event](plane, ...args);

    if (this.#moduleEvents.has(plane.module)) {
      return await fireEvent();
    }

    let resolve: (result?: unknown) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reject: (reason: any) => void;
    const eventPromise = new Promise<unknown>(async (res, rej) => {
      resolve = res;
      reject = rej;
      await waitForCondition(() => this.#moduleEvents.has(plane.module), this.#WAIT_FOR_MODULE_TIMEOUT);
      if (!this.#moduleEvents.has(plane.module)) {
        rej(new Error('Timed Out'));
      }
    });

    const queueEvent = async () => {
      try {
        const result = await fireEvent();
        resolve(result);
      } catch (e) {
        reject(e);
      }
    };

    const moduleQueue = this.#queuedEvents.get(plane.module);
    if (!moduleQueue) {
      this.#queuedEvents.set(plane.module, [queueEvent]);
    } else {
      moduleQueue.push(queueEvent);
    }

    await eventPromise;
  }

  async pulse(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onPulse');
  }

  async cleanup(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onCleanup');
  }

  async close(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onClose');
  }

  async flush(plane: Plane) {
    return await this.#fireOrQueueEvent(plane, 'onFlush');
  }

  async open(plane: Plane, fileInfo: OnOpenFileInfo) {
    return await this.#fireOrQueueEvent(plane, 'onOpen', fileInfo);
  }
}

export default EventHandler;
