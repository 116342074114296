import { useContext } from 'react';
import { StorageExplorerContext } from '../context';

/**
 * Hook for accessing the StorageExplorerContext values.
 * @returns {import('react').Context<import('@').StorageExplorerContext>} The context values.
 */
export const useStorageExplorer = () => {
  const context = useContext(StorageExplorerContext);
  if (!context) {
    throw new Error('useStorageExplorer must be used within CloudStorageExplorer');
  }
  return context;
};
