import React, { Fragment } from 'react';
import { Skeleton } from '@mtb/ui';
import { useStorageExplorerTable } from '../../hooks';
import { useStorageExplorerTableRow } from '../../StorageExplorerTableRow';

export function StorageExplorerTableRowCellSkeleton({
  disableSkeleton = false,
  ...other
}) {
  const row = useStorageExplorerTableRow();
  const { isLoading } = useStorageExplorerTable();

  return isLoading && !disableSkeleton ? (
    <Skeleton
      data-testid={`storage-explorer-table-row-skeleton-${row.id}`}
      variant="rect"
      {...other} />
  ) : (
    <Fragment {...other} />
  );
}

export default StorageExplorerTableRowCellSkeleton;
