import type { MtbPlatform } from '../../types';
import type { PropsWithChildren } from 'react';
import { PureComponent, createElement, createRef } from 'react';

/**
 * This factory allows us to have remote modules that are not using React,
 * it won't be a perfect 1-to-1 for state updates, but that is why we also
 * run an `init` function from the module. It gives it a chance to subscribe
 * to the Redux store and/or do any set up prior to us mounting.
 * @param module
 */
export const CreateComponent = ({ as: getAs, mount, update, unmount }: MtbPlatform.RemoteModuleInterface) =>
  class RemoteModuleComponent extends PureComponent {
    ref: React.RefObject<HTMLElement>;
    as: string;
    mount: MtbPlatform.RemoteModuleInterface['mount'];
    update: MtbPlatform.RemoteModuleInterface['update'];
    unmount: MtbPlatform.RemoteModuleInterface['unmount'];
    props: PropsWithChildren<MtbPlatform.PlatformModuleProps>;

    constructor(props: MtbPlatform.PlatformModuleProps) {
      super(props);
      this.props = props;
      this.ref = createRef();
      this.as = getAs?.() ?? 'div';
      this.mount = mount ?? (() => undefined);
      this.update = update ?? (() => undefined);
      this.unmount = unmount ?? (() => undefined);
    }

    componentDidMount() {
      this.mount?.(this.ref.current as HTMLElement, this.props);
    }

    componentDidUpdate() {
      this.update?.(this.ref.current as HTMLElement, this.props);
    }

    componentWillUnmount() {
      this.unmount?.(this.ref.current as HTMLElement);
    }

    render() {
      return createElement(this.as, { ref: this.ref });
    }
  };

export default CreateComponent;
