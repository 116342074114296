import { useMemo } from 'react';
import { useEventCallback } from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../constants';
import { filterItems } from '../utils';
import { useCloudStorageStore } from './useCloudStorageStore';
import { useLatestFetch } from './useLatestFetch';

const DEFAULT_FILTER = [];

/**
 * Custom hook for getting shared cloud storage items.
 * @param {import('@').UseCloudStorageItemsParams} params - The parameters for the hook.
 * @returns {import('@').UseCloudStorageItemsResult}
 */
export function useSharedCloudStorageItems({ id, filter = DEFAULT_FILTER, disableCache = false } = {}) {
  const cloudStorage = useCloudStorageStore();
  const fetchFn = useEventCallback(async (...args) => await cloudStorage.getShared(...args));
  const { data, fetch, clear, cancel, type, isFetching, hasFetched } = useLatestFetch({
    id,
    noun        : `${CLOUD_STORAGE_CATEGORIES.SHARED}-items`,
    fetchFn,
    initialValue: [],
    disableCache,
  });
  const items = useMemo(() => filterItems(data, filter), [data, filter]);
  return { fetch, clear, cancel, items, type, isFetching, hasFetched };
}
