import type { ClaimsState, UserSettings, UserState } from './types';
import type { WritableDraft } from 'immer';
import platformCore from '../../..';
import { createAsyncReducerCases } from '../../redux-helpers/create-async-reducer-cases';
import { createAsyncThunk } from '../../redux-helpers/create-async-thunk';
import { createSlice } from '../../redux-helpers/create-slice';
import { parseIfJson } from '../../redux-helpers/parse-if-json';
import { fetchSubscriptions, fetchSubscriptionsReducer } from './fetch-subscriptions';

const initialState: UserState = {
  claims: {
    loading         : { fetchClaims: 'idle', validateClaims: 'idle', logout: 'idle' },
    currentRequestId: { fetchClaims: '', validateClaims: '' },
    valid           : false,
  },
  subscriptions: [],
  settings     : {
    autoSave: false,
    locale  : {
      code              : 'en',
      region            : 'United States',
      currency          : 'USD',
      language          : 'English',
      translation       : 'en-US',
      translationLang   : 'en',
      translationCountry: 'US',
      locale            : 'en-US',
      localeLang        : 'en',
      localeCountry     : 'US',
      decimalSeparator  : '.',
      listSeparator     : ',',
      groupSeparator    : ',',
      timezone          : 'America/New_York',
      units             : 'inches',
    },
  },
};

const fetchClaimsPropsMap: Record<string, string> = {
  id                 : 'id',
  name               : 'name',
  firstname          : 'givenName',
  lastname           : 'surname',
  email              : 'emailAddress',
  features           : 'features',
  roles              : 'roles',
  issuingauthority   : 'issuingAuthority',
  status             : 'status',
  identifyingparty   : 'identifyingParty',
  subscriptionid     : 'subscriptionId',
  productid          : 'productId',
  seatid             : 'seatId',
  subscriptionname   : 'subscriptionName',
  subscriptionenddate: 'subscriptionEndDate',
  subscriptionmodel  : 'subscriptionModel',
  issubscriptionla   : 'isSubscriptionLA',
  deployurl          : 'deployUrl',
  appSettings        : 'appSettings',
};

type ReverseMap<T extends Record<string, string | number>> = {
  [V in T[keyof T]]: { [K in keyof T]: T[K] extends V ? K : never }[keyof T];
};

const fetchClaimsPropsReverseMap: ReverseMap<typeof fetchClaimsPropsMap> = Object.fromEntries(
  Object.entries(fetchClaimsPropsMap).map(([k, v]) => [v, k]),
) as ReverseMap<typeof fetchClaimsPropsMap>;

const fetchClaimsProps = [
  'id',
  'name',
  'firstname',
  'lastname',
  'email',
  'features',
  'roles',
  'identifyingparty',
  'subscriptionid',
  'productid',
  'seatid',
  'appSettings',
] as const;

type AsyncUserThunkConfig = {
  state: { user: UserState };
};

const asyncActions = {
  fetchSubscriptions,
  fetchClaims: createAsyncThunk.withTypes<AsyncUserThunkConfig>()(
    'user/fetchClaims',
    async (arg: void, { getState, requestId }) => {
      const { currentRequestId, loading } = getState().user.claims;
      if (loading.fetchClaims !== 'pending' || requestId !== currentRequestId.fetchClaims) {
        return;
      }

      const data = parseIfJson(await platformCore.AuthenticationClient.getClaims());
      const mappedProps = fetchClaimsProps.map(k => fetchClaimsPropsMap[k]);
      for (const key in data) {
        if (!mappedProps.includes(key)) {
          delete data[key as keyof typeof data];
        } else {
          // @ts-expect-error TS really doesn't like this, but we know this is valid
          data[fetchClaimsPropsReverseMap[key]] = data[key];
          if (fetchClaimsPropsReverseMap[key as keyof typeof fetchClaimsPropsReverseMap] !== key) {
            delete data[key as keyof typeof data];
          }
        }
      }
      return data as Pick<ClaimsState, (typeof fetchClaimsProps)[number]>;
    },
  ),
  validateClaims: createAsyncThunk.withTypes<AsyncUserThunkConfig>()(
    'user/validateClaims',
    async (arg: void, { getState, requestId }) => {
      const { currentRequestId, loading } = getState().user.claims;
      if (loading.validateClaims !== 'pending' || requestId !== currentRequestId.validateClaims) {
        return;
      }

      const valid = (await platformCore.AuthenticationClient.areCurrentClaimsValid(getState)) as boolean;
      return { valid };
    },
  ),
  logout: createAsyncThunk.withTypes<AsyncUserThunkConfig>()('user/logout', async () => {
    platformCore.actions.closeAllPlanes();
    platformCore.AuthenticationClient.logout();
  }),
};

const user = createSlice({
  name    : 'user',
  initialState,
  reducers: {
    setAutoSave: {
      reducer: (state: UserState, action: { payload: { enabled: UserSettings['autoSave'] } }) => {
        state.settings.autoSave = action.payload.enabled;
      },
      prepare: (enabled: UserSettings['autoSave']) => {
        return { payload: { enabled } };
      },
    },
    toggleAutoSave: {
      reducer: (state: UserState) => {
        state.settings.autoSave = !state.settings.autoSave;
      },
      prepare: () => {
        return { payload: {} };
      },
    },
    updateLocale: {
      reducer: (state: UserState, action: { payload: { locale: Partial<UserSettings['locale']> } }) => {
        state.settings.locale = {
          ...state.settings.locale,
          ...platformCore.I18n.getLocale(
            action.payload.locale.translation || action.payload.locale.language || state.settings.locale.translation,
            'language',
          ),
          ...platformCore.I18n.getLocale(
            action.payload.locale.locale || action.payload.locale.region || state.settings.locale.locale,
            'region',
          ),
          ...action.payload.locale,
        };
        localStorage.setItem(platformCore.I18n.localePreferenceKey, JSON.stringify(state.settings.locale));
        platformCore.I18n.changeLanguage(state.settings.locale.code);
      },
      prepare: (locale: Partial<UserSettings['locale']>) => {
        return { payload: { locale } };
      },
    },
  },
  extraReducers: builder => {
    fetchSubscriptionsReducer(builder),
    createAsyncReducerCases(
      builder,
      asyncActions.fetchClaims,
      'claims.loading.fetchClaims',
      'claims.currentRequestId.fetchClaims',
      'claims.error',
      {
        fulfilled: (state, action) => {
          if (action.payload) {
            for (const key of fetchClaimsProps) {
              if (Object.hasOwnProperty.call(action.payload, key)) {
                state.claims[key] = action.payload[key] as
                  (string & string[] & WritableDraft<Record<string, string>>) | undefined;
              }
            }
          }
        },
        rejected: state => {
          for (const key of fetchClaimsProps) {
            if (Object.hasOwnProperty.call(state.claims, key)) {
              delete state.claims[key];
            }
          }
        },
      },
    );
    createAsyncReducerCases(
      builder,
      asyncActions.validateClaims,
      'claims.loading.validateClaims',
      'claims.currentRequestId.validateClaims',
      'claims.error',
      {
        pending: state => {
          state.claims.valid = false;
        },
        fulfilled: (state, action) => {
          state.claims.valid = Boolean(action.payload?.valid);
        },
        rejected: state => {
          state.claims.valid = false;
        },
      },
    );
    builder
      .addCase(asyncActions.logout.pending, (state: UserState) => {
        state.claims.valid = false;
      })
      .addCase(asyncActions.logout.fulfilled, () => {
        /** NO_OP */
      })
      .addCase(asyncActions.logout.rejected, () => {
        /** NO_OP */
      });
  },
});

const { reducer, actions, name, getInitialState } = user;

export { actions, asyncActions, getInitialState, name, reducer };

