import type { Snackbar, SnackbarsState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { DEFAULT_SNACKBAR_PROPS } from './constants';
import { createSnackbarSchema } from './schema';

const initialState: SnackbarsState = {
  snackPack: [],
  snack    : null,
  open     : false,
};

const snackbar = createSlice({
  name    : 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state: SnackbarsState) => {
      state.snack = state.snackPack[0];
      state.snackPack = state.snackPack.slice(1);
      state.open = true;
    },
    closeSnackbar: (state: SnackbarsState) => {
      state.open = false;
    },
    addSnack: {
      reducer: (state: SnackbarsState, action: PayloadAction<Snackbar>) => {
        state.snackPack.push(action.payload);
      },
      prepare: (snackbar): { payload: Snackbar } => {
        const id = uuid();
        const planeId = snackbar.planeId || null;
        const severity = snackbar.severity || DEFAULT_SNACKBAR_PROPS.severity;
        const icon = snackbar.icon || DEFAULT_SNACKBAR_PROPS.icon;
        const autoHideDuration = snackbar.autoHideDuration || DEFAULT_SNACKBAR_PROPS.autoHideDuration;
        const anchorOrigin = snackbar.anchorOrigin || DEFAULT_SNACKBAR_PROPS.anchorOrigin;
        const payload = {
          id,
          planeId,
          title  : snackbar.title,
          content: snackbar.content,
          icon,
          severity,
          autoHideDuration,
          anchorOrigin,
        };
        const { valid, errors } = createSnackbarSchema().validate(payload);
        if (!valid) {
          throw new Error(
            [
              'addSnack validation failed with errors:',
              ...errors.map(e => `snackbar${e.instancePath} ${e.message}`),
            ].join('\n  '),
          );
        }
        return { payload };
      },
    },
    removeSnack: (state: SnackbarsState) => {
      state.snack = null;
    },
  },
});

const serializableCheck = {
  ignoredActions: [snackbar.actions.openSnackbar.type, snackbar.actions.addSnack.type],
  ignoredPaths  : ['snackbar'],
};

// Export actions
const { reducer, actions, name, getInitialState } = snackbar;
export { actions, getInitialState, name, reducer, serializableCheck };

