import { getStoredConfig } from './stored-config';

export type Config = {
  // Platform Values
  version: string;
  session_timeout: number;
  session_warning: number;
  dev_tools_enabled: boolean;
  // Feature Flags
  feature_flag_data_center: boolean;
  feature_flag_reporting_tool: boolean;
  feature_flag_msso_root: boolean;
  feature_flag_brainstorm: boolean;
  feature_flag_wso: boolean;
  feature_flag_ui_v2: boolean;
  feature_flag_cs_store_v2: boolean;
  feature_flag_edit_readonly_projects: boolean;
  feature_flag_enable_all_modules: boolean;
  // Azure Values
  search_endpoint: string;
  search_api_key: string;
  application_insights_instrumentation_key: string;
  // Cloud Storage Values
  gdrive_clientid: string;
  msgraph_clientid: string;
  cloud_storage_token_url: string;
  cloud_storage_code_url: string;
  // URLs
  connect_url: string;
  rtspc_url: string;
  engage_url: string;
  modeler_url: string;
  eduhub_url: string;
  support_url: string;
  dialogs_url: string;
  choosers_url: string;
  td_url: string;
};

const baseConfig = (
  process.env.NODE_ENV === 'test' && '__test_config__' in global ? global.__test_config__ : {}
) as Readonly<Config>;

const config = new Proxy(baseConfig, {
  get: (target, property) => {
    if (property === '__defaults__') {
      return { ...target };
    }
    return Reflect.get({ ...target, ...getStoredConfig() }, property);
  },
  set: (target, property, value) => {
    return Reflect.set(target, property, value);
  },
}) as Readonly<Config> & { __defaults__: Readonly<Config> };

export default config;
