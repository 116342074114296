import type { InterceptedAuth } from './types';
import type { StorageProviderKey } from '../../store/providers/types';
import { STORAGE_PROVIDER_KEYS } from '../../constants';
import ProviderStore from '../../store/providers';
import { getProviderServiceByType } from '../../utils';
import { updateLoginCounter } from '../store-utils';

class ProviderService {
  signIn(key: StorageProviderKey): void {
    const service = getProviderServiceByType(key);
    service?.login();
  }

  async handleInterceptedAuth({ state, response }: InterceptedAuth) {
    const type = state.type;
    if (type === STORAGE_PROVIDER_KEYS.ONE_DRIVE) {
      updateLoginCounter();
    }

    const service = getProviderServiceByType(type);
    const account = await service?.api.getAccount(false, response);
    if (!account) {
      return;
    }

    ProviderStore.setProvider(type, {
      type,
      account: {
        id    : account.id,
        email : account.email as string,
        name  : account.name as string,
        avatar: account?.picture,
      },
      tokens: {
        accessToken : response.access_token,
        refreshToken: response.refresh_token,
        expiresAt   : response.expires_in,
      },
    });

    if (ProviderStore.getDefaultProvider() === null) {
      ProviderStore.setDefaultProvider(type);
    }
  }

  async signOut(key: StorageProviderKey) {
    const service = getProviderServiceByType(key);
    await service?.logout();
    ProviderStore.removeProvider(key);
    if (ProviderStore.getDefaultProvider() === key) {
      ProviderStore.removeDefaultProvider();
    }
  }

  toggleDefaultProvider(key: StorageProviderKey): void {
    const defaultProvider = ProviderStore.getDefaultProvider();
    if (defaultProvider === key) {
      ProviderStore.removeDefaultProvider();
    } else {
      ProviderStore.setDefaultProvider(key);
    }
  }

  isSignedIn(key: StorageProviderKey): boolean {
    const provider = ProviderStore.getProvider(key);
    return !!provider;
  }
}

const providerService = new ProviderService();

export default providerService;
