import { createElement } from 'react';
import CenteredCircularProgress from '../../utils/centered-circular-progress';
import withErrorBoundary from '../../utils/error-boundary';
import lazy from '../../utils/lazy';
import { createStaticRemoteModuleLoader } from '../../utils/load-remote-module';
import remoteModuleUrlConfigs from '../../utils/remote-url-configs';
import withSuspense from '../../utils/with-suspense';

const loadRemoteDiscover = createStaticRemoteModuleLoader(
  'mtb_platformContent',
  'DISCOVER',
  remoteModuleUrlConfigs.DISCOVER,
);

const LazyRemoteDiscover = lazy(loadRemoteDiscover);

const RemoteDiscover = withErrorBoundary(
  withSuspense(LazyRemoteDiscover, () => createElement(CenteredCircularProgress)),
  'RemoteDiscover',
);

export { RemoteDiscover, loadRemoteDiscover };
