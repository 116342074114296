import type { Plane } from '../store/reducers/planes';
import type { PropsOf } from '../types';
import type { Theme } from '@mtb/ui/types/core/theme';
import type { ComponentType, ReactNode } from 'react';
import { Component, createElement } from 'react';
import { Box, Stack, Typography, ErrorOutlinedIcon } from '@mtb/ui';
import config from '../../config';
import { PLANE_STATUS_TYPES } from '../../module-config';

const defaultProps = {
  name: 'Error Boundary',
};

type DefaultErrorFallbackProps = {
  name: string;
  message: string;
  stack?: string;
};

const DefaultErrorFallback = ({ name, message, stack }: DefaultErrorFallbackProps) => {
  return createElement(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TSC is seeing an invalid error here
    Stack,
    {
      align  : 'center',
      justify: 'center',
      spacing: 2,
      sx     : {
        width       : '100%',
        height      : '100%',
        paddingLeft : 5,
        paddingRight: 5,
        border      : (t: Theme) => `1px solid ${t.palette.error.main}`,
        borderRadius: 1,
      },
    },
    createElement(
      Stack,
      {
        align: 'center',
        sx   : { height: 70 },
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TSC is seeing an invalid error here
      createElement(ErrorOutlinedIcon, { sx: { fontSize: 70, color: (t: Theme) => t.palette.error.main } }),
    ),
    !!name &&
      createElement(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TSC is seeing an invalid error here
        Typography,
        { paragraph: true, sx: { color: (t: Theme) => t.palette.error.main as string }, variant: 'title-md' },
        name,
      ),
    createElement(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TSC is seeing an invalid error here
      Typography,
      { paragraph: true, sx: { color: (t: Theme) => t.palette.error.main as string }, variant: 'body-md' },
      message,
    ),
    createElement(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TSC is seeing an invalid error here
      Box,
      {
        as: 'span',
        sx: {
          display     : 'block',
          width       : '100%',
          overflow    : 'auto',
          padding     : 1,
          margin      : 1,
          border      : '1px solid',
          borderRadius: 1,
        },
      },
      typeof stack === 'string' &&
        createElement(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TSC is seeing an invalid error here
          Typography,
          { as: 'span', sx: { textWrap: 'nowrap' }, variant: 'body-md' },
          stack
            .split('\n')
            .map(ln => [ln, createElement('br', { key: Math.random().toString() })])
            .flat(),
        ),
    ),
  );
};

type ErrorBoundaryProps = {
  children?: ReactNode;
  name: string;
  plane?: Plane;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error?: Error;
  errorInfo?: unknown;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static get defaultProps() {
    return defaultProps;
  }
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: /** @type {Error | undefined} */ undefined };
  }
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }
  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    this.setState({ hasError: true, error, errorInfo });
  }
  render() {
    const { children, name, plane } = this.props;
    let planeError = plane?.error || plane?.status === PLANE_STATUS_TYPES.ERROR;
    if (planeError && typeof planeError === 'boolean') {
      planeError = new Error('Unknown Plane error');
    }

    if (planeError && config.dev_tools_enabled) {
      return createElement(DefaultErrorFallback, {
        message: `${planeError?.name}::${planeError?.message}`,
        name,
        stack  : planeError?.stack,
      });
    }
    const { hasError, error } = this.state;
    if (hasError && config.dev_tools_enabled) {
      return createElement(DefaultErrorFallback, {
        message: `${error?.name}::${error?.message}`,
        name,
        stack  : error?.stack,
      });
    }
    if (hasError || planeError) {
      return null;
    }
    // Normal UI rendering
    return children;
  }
}

function withErrorBoundary<T extends ComponentType<any>>(Component: T, name = 'Error Boundary') {
  return (props: PropsOf<T>) =>
    createElement(ErrorBoundary, { name, plane: props?.plane } as ErrorBoundaryProps, createElement(Component, props));
}

export default withErrorBoundary;
export { withErrorBoundary, ErrorBoundary };
