import type { PlatformModule } from './types';
import type { I18nLocale } from '../../clients';
import type { UserSettings } from '../store/reducers/user/types';
import { useMemo } from 'react';
import PlatformCore from '..';

/**
 * PlatformModule is the interface that is given to a remote module when we go to render their remote content.
 * It represents a fully configured module instance that has the ability and access to interact with Platform.
 * This class serves a centralize location to expose a public API for all remote module interactions where we can
 * control and manage what interactions we expose to them.
 */
export class StaticPlatformModule implements PlatformModule {
  /**
   * Get user settings.
   */
  get settings(): UserSettings {
    return PlatformCore.selectors.settings(PlatformCore.getState());
  }

  /**
   * Get user locale.
   */
  get locale(): I18nLocale {
    return PlatformCore.selectors.locale(PlatformCore.getState());
  }

  /**
   * Uses a selector for claims.
   */
  useClaims(): unknown {
    const claims = PlatformCore.useSelector(state => PlatformCore.selectors.claims(state));
    const transformedClaims = useMemo(() => claims, [claims]);
    return transformedClaims;
  }

  /**
   * Uses a selector to get the user's locale settings.
   * @returns The locale settings.
   */
  useLocale(): I18nLocale | undefined {
    return PlatformCore.useSelector(state => PlatformCore.selectors.locale(state));
  }
}

export const staticPlatformModule = new StaticPlatformModule();
export default staticPlatformModule;
