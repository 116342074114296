import type { ComponentType, PropsWithChildren } from 'react';
import { createElement } from 'react';
import { IntegratedPlatformModule } from '../platform-module';

export type WithPlatformProps = { planeId: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withIntegratedPlatformModule = (Component: ComponentType<any>) => {
  return (props: PropsWithChildren<WithPlatformProps>) => {
    const platformModule = new IntegratedPlatformModule(props.planeId);
    return createElement(Component, {
      ...props,
      // TODO: Remove this once we update reporting tool
      plane   : platformModule,
      platform: platformModule,
    } as React.Attributes);
  };
};

export default withIntegratedPlatformModule;
