import type { TelemetryAppInsightsClient, TelemetryClients } from './types';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import config from '../../config';
import Logger from '../logger';
import { FakeApplicationInsights } from './stub';

const TELEMETRY_ROLE = 'client';

class TelemetryClient {
  /**
   * The core telemetry client.
   */
  #coreClient: TelemetryAppInsightsClient;

  /**
   * The telemetry clients for remote modules.
   */
  #clients: TelemetryClients = new Map();

  /**
   * Initializes the core telemetry client with auto tracking enabled.
   */
  createCoreClient(): TelemetryAppInsightsClient {
    if (!config.application_insights_instrumentation_key) {
      Logger.log('[TelemetryClient:Debug] Core telemetry client cannot be created. Instrumentation key is missing.');
      return FakeApplicationInsights;
    }

    if (this.#coreClient) {
      return this.#coreClient;
    }

    this.#coreClient = new ApplicationInsights({
      config: {
        instrumentationKey     : config.application_insights_instrumentation_key,
        disableCookiesUsage    : true,
        enableAutoRouteTracking: true,
        disableFetchTracking   : false,
        disableAjaxTracking    : false,
      },
    });

    this.#coreClient.loadAppInsights();
    this.#coreClient.trackPageView();
    this.#coreClient.addTelemetryInitializer(envelope => {
      if (!envelope.tags) {
        envelope.tags = {};
      }
      envelope.tags['ai.cloud.role'] = TELEMETRY_ROLE;
      envelope.tags['ai.cloud.roleInstance'] = 'platform';
    });

    Logger.log(`[TelemetryClient:Debug] Created core telemetry client using instrumentation key: ${config.application_insights_instrumentation_key}.`);
    return this.#coreClient;
  }

  /**
   * Creates a non auto tracking version of the telemetry client for remote modules.
   * @param clientName - The name of the telemetry client.
   * @returns The remote module telemetry client.
   */
  createClient(clientName: string) {
    if (!config.application_insights_instrumentation_key) {
      Logger.log(`[TelemetryClient:Debug] ${clientName} telemetry client cannot be created. Instrumentation key is missing.`);
      return FakeApplicationInsights;
    }

    if (!clientName) {
      Logger.fatal('[TelemetryClient:Debug] createClient: name is required.');
    }

    if (this.#clients.has(clientName)) {
      return this.#clients.get(clientName) as ApplicationInsights;
    }

    const client = new ApplicationInsights({
      config: {
        instrumentationKey     : config.application_insights_instrumentation_key,
        disableCookiesUsage    : true,
        enableAutoRouteTracking: false,
        disableFetchTracking   : true,
        disableAjaxTracking    : true,
      },
    });

    // Needed to ensure we are loading the newly created client.
    client.loadAppInsights();
    client.addTelemetryInitializer(envelope => {
      if (!envelope.tags) {
        envelope.tags = {};
      }
      envelope.tags['ai.cloud.role'] = TELEMETRY_ROLE;
      envelope.tags['ai.cloud.roleInstance'] = clientName;
    });

    this.#clients.set(clientName, client);

    Logger.log(`[TelemetryClient:Debug]: Created ${clientName} telemetry client using instrumentation key: ${config.application_insights_instrumentation_key}.`);
    return client;
  }
}

const telemetryClient = new TelemetryClient();
export default telemetryClient;
