// @ts-nocheck deepIsEqual is a highly-optimized function that ignores a lot of best practices in favor of performance.

const DEFAULT_OPTIONS = {
  strictArrayOrder: true,
  treatNanAsEqual : true,
};

const strictArrayOrderDeepEqual = (a, b, i, options) => deepIsEqual(a[i], b[i], options);
const looseArrayOrderDeepEqual = (a, b, i, options) => b.some(v => deepIsEqual(a[i], v, options));

/**
 * @param {any} a
 * @param {any} b
 * @param {DEFAULT_OPTIONS} options
 * @returns {boolean}
 */
export const deepIsEqual = (a, b, options = {}) => {
  if (a === b) {
    return true;
  }
  if (typeof a != typeof b) {
    return false;
  }
  if (!options._defaultsAssigned) {
    options = Object.assign({ _defaultsAssigned: true }, DEFAULT_OPTIONS, options);
  }
  // eslint-disable-next-line no-self-compare
  if (a !== a || b !== b) {
    // eslint-disable-next-line no-self-compare
    return a !== a && b !== b && options.treatNanAsEqual;
  }
  if (a?.constructor !== b?.constructor || typeof a != 'object') {
    return false;
  }
  if (!options._stack) {
    options._stack = new WeakMap();
    options._arrayEqualityFunction = options.strictArrayOrder ? strictArrayOrderDeepEqual : looseArrayOrderDeepEqual;
  }
  // Protect against circular objects
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
  if (options._stack.has(a) && options._stack.has(b)) {
    return options._stack.get(a) === a && options._stack.get(b) === b;
  }

  if (a instanceof Set) {
    a = Array.from(a);
    b = Array.from(b);
  } else if (a instanceof Map) {
    a = Object.fromEntries(a);
    b = Object.fromEntries(b);
  } else if (a.constructor === RegExp) {
    return a.source === b.source && a.flags === b.flags;
  }

  // eslint-disable-next-line no-var
  var i, length, keys, key;
  if (Array.isArray(a)) {
    length = a.length;
    if (length != b.length) {
      return false;
    }
    options._stack.set(a, a);
    options._stack.set(b, b);

    for (i = length; i-- != 0;) {
      if (a[i] === b[i]) {
        continue;
      }
      if (!options._arrayEqualityFunction(a, b, i, options)) {
        return false;
      }
    }
    return true;
  }

  if (a.toString !== Object.prototype.toString) {
    return a.toString() === b.toString();
  }

  keys = Object.keys(a);
  length = keys.length;
  if (length !== Object.keys(b).length) {
    return false;
  }

  for (i = length; i-- != 0;) {
    if (!Object.prototype.hasOwnProperty.call(b, keys[i])) {
      return false;
    }
  }
  options._stack.set(a, a);
  options._stack.set(b, b);

  for (i = length; i-- != 0;) {
    key = keys[i];
    if (a[key] === b[key]) {
      continue;
    }
    if (!deepIsEqual(a[key], b[key], options)) {
      return false;
    }
  }
  return true;
};

export default deepIsEqual;
