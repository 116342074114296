import toPath from './toPath';

/**
 * @template Obj
 * @template {string} Path
 * @param {Obj} obj
 * @param {Path} path
 * @param {any} [defaultValue]
 * @returns {import('./types').Get<Obj, Path>}
 */
export const get = (obj, path, defaultValue = undefined) => {
  // eslint-disable-next-line no-eq-null
  if (obj == null) {
    return defaultValue;
  }
  if (typeof path !== 'string' && !Array.isArray(path)) {
    return defaultValue;
  }
  const pathArray = toPath(path);
  let index = 0,
    nested = obj;
  // eslint-disable-next-line no-eq-null
  while (nested != null && index < pathArray.length) {
    // @ts-ignore
    nested = nested[pathArray[index++]];
  }
  return index && index == pathArray.length ? (nested !== undefined ? nested : defaultValue) : defaultValue;
};

export default get;
