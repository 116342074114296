import {
  GoogleDriveIcon,
  MicrosoftOneDriveIcon,
  Stack,
  Typography,
} from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { CloudProviderList } from './CloudProviderList';
import { OpenLocal } from './OpenLocalFile';

export const StorageExplorerProviderList = () => {
  const [t] = useTranslation();

  return (
    <Stack sx={{ minWidth: 200, maxWidth: 250, width: '20%', flex: '1 0 auto' }}>
      <Typography
        color="text.secondary"
        noWrap
        sx={{ pt: 3, pb: 1 }}
        variant="title-md"
        weight="semibold">
        {t('connection.repository')}
      </Typography>
      <CloudProviderList
        providerIcon={<MicrosoftOneDriveIcon />}
        providerName={t('connection.oneDrive')}
        provider={STORAGE_PROVIDER_KEYS.ONE_DRIVE} />
      <CloudProviderList
        providerIcon={<GoogleDriveIcon />}
        providerName={t('connection.googleDrive')}
        provider={STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE} />
      <OpenLocal />
    </Stack>
  );
};
