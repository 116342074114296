// @ts-check
import { createContext } from 'react';

export const initialState = /** @type {import('@').StorageExplorerContext} */ ({
  id                     : '',
  disableProviderSettings: false,
  defaultFilter          : [],
  filter                 : [],
  maxSize                : Infinity,
  disableCache           : false,
  disableContextMenus    : false,
  disableRowActions      : false,
  folder                 : null,
  category               : null,
  items                  : [],
  selected               : null,
  isLoading              : false,
  breadcrumbTrail        : [],
  dense                  : false,
  provider               : null,
  openItem               : () => Promise.resolve(false),
  openFile               : () => Promise.resolve(),
  setProvider            : () => undefined,
  setFilter              : () => undefined,
  setIsLoading           : () => undefined,
  setFolder              : () => undefined,
  setCategory            : () => undefined,
  setSelected            : () => undefined,
  refresh                : () => undefined,
  back                   : () => undefined,
  renderOpenInMenuOptions: () => null,
});

export const StorageExplorerContext = createContext(initialState);
