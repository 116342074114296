import type { I18nLocale } from './types';

export const DEFAULT_LOCALE: I18nLocale = {
  code              : 'en',
  region            : 'United States',
  currency          : 'USD',
  language          : 'English',
  translation       : 'en-US',
  translationLang   : 'en',
  translationCountry: 'US',
  locale            : 'en-US',
  localeLang        : 'en',
  localeCountry     : 'US',
  decimalSeparator  : '.',
  listSeparator     : ',',
  groupSeparator    : ',',
  timezone          : 'America/New_York',
  units             : 'inches',
};
