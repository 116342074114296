import type { PlatformStoreType } from '../store';
import { LoggerClient } from '../../clients';
import config from '../../config';

type StoreActionsType = PlatformStoreType['actions'];

const HEALTH_SERVICE_STORAGE_KEY = 'platform_health_service_interval';

export class HealthService {
  #logger = LoggerClient.createNamedLogger('HealthService');
  #disabled = false;
  #actions: StoreActionsType;
  #startTime: number = Date.now();
  #HEALTH_INTERVAL: number = 5 * 60 * 1000; // 5 minutes
  #checkAllPlanesHealthId: number | undefined;
  static #instance: HealthService;
  getHealthInterval?: () => number;
  getRemainingTime?: () => number;
  getIsDisabled?: () => boolean;
  setHealthInterval?: (interval: number) => void;
  disableHealthInterval?: () => void;

  #checkAllPlanesHealth() {
    this.#logger.log('Performing health checks.');
    this.#startTime = Date.now();
    this.#actions.checkAllPlanesHealth();
  }

  #clearAllPlanesHealthCheck() {
    if (this.#checkAllPlanesHealthId) {
      clearInterval(this.#checkAllPlanesHealthId);
      this.#checkAllPlanesHealthId = undefined;
    }
  }

  #resetAllPlanesHealthCheck() {
    this.#clearAllPlanesHealthCheck();
    this.#startAllPlanesHealthCheck();
  }

  #startAllPlanesHealthCheck() {
    if (this.#disabled) {
      return;
    }

    if (!this.#checkAllPlanesHealthId) {
      this.#logger.log('Starting health checks interval...');
      this.#startTime = Date.now();
      this.#checkAllPlanesHealthId = setInterval(
        this.#checkAllPlanesHealth.bind(this),
        this.#HEALTH_INTERVAL,
      ) as unknown as number;
    }
  }

  #getRemainingTime() {
    const elapsed = this.#startTime ? Date.now() - this.#startTime : 0;
    return Math.max(0, Math.floor(this.#HEALTH_INTERVAL - elapsed));
  }

  #getHealthInterval() {
    return this.#HEALTH_INTERVAL;
  }

  #setHealthInterval(interval: number) {
    this.#startTime = Date.now();
    this.#HEALTH_INTERVAL = interval;
    this.#resetAllPlanesHealthCheck();
  }

  #handleVisibilityChange() {
    if (document.visibilityState !== 'visible') {
      this.#logger.log('Stopping health checks interval...');
      this.#clearAllPlanesHealthCheck();
      return;
    }

    if (this.#disabled) {
      return;
    }

    if (!this.#checkAllPlanesHealthId) {
      this.#startAllPlanesHealthCheck();
    }

    this.#checkAllPlanesHealth();
  }

  #addVisibilityChangeListener() {
    document.addEventListener('visibilitychange', this.#handleVisibilityChange.bind(this));
  }

  /**
   * Creates a singleton instance of HealthService. To ensure that only one instance
   * of HealthService is used between all the modules using @mtb/platform.
   **/
  constructor({ actions }: { actions: StoreActionsType }) {
    this.#actions = actions;

    if (HealthService.#instance) {
      return HealthService.#instance;
    }

    // Expose these methods in development for use in the dev tools.
    if (config.dev_tools_enabled) {
      this.getHealthInterval = this.#getHealthInterval.bind(this);
      this.getRemainingTime = this.#getRemainingTime.bind(this);
      this.getIsDisabled = () => this.#disabled;

      this.setHealthInterval = (interval: number) => {
        sessionStorage.setItem(HEALTH_SERVICE_STORAGE_KEY, `${interval}`);
        this.#disabled = false;
        this.#setHealthInterval(interval);
      };

      this.disableHealthInterval = () => {
        sessionStorage.setItem(HEALTH_SERVICE_STORAGE_KEY, `${false}`);
        this.#clearAllPlanesHealthCheck();
      };

      const healthServiceInterval = sessionStorage.getItem(HEALTH_SERVICE_STORAGE_KEY);
      if (healthServiceInterval === 'false') {
        this.#disabled = true;
        this.#logger.log('Platform health interval is disabled! Platform resources will NOT be kept alive.');
      } else {
        this.#HEALTH_INTERVAL = parseInt(healthServiceInterval ?? '0') || this.#HEALTH_INTERVAL;
      }
    }

    if (!this.#disabled) {
      this.#startAllPlanesHealthCheck();
      this.#addVisibilityChangeListener();
    }

    HealthService.#instance = this;
  }
}

export default HealthService;
