import type { PropsOf } from '../types';
import type { ComponentType, ReactElement } from 'react';
import { Suspense, createElement } from 'react';

const withSuspense = <T extends ComponentType<any>>(Component: T, fallback: () => ReactElement) =>
  ((props: PropsOf<T>) => {
    return createElement(
      Suspense,
      { fallback: fallback?.() || createElement('span') },
      createElement(Component, props),
    );
  }) as T;

export default withSuspense;
export { withSuspense };
