import { useCallback, useMemo } from 'react';
import { getStorageItemIcon, isGoogleSheet } from '../../../utils';
import { useStorageExplorer } from './useStorageExplorer';

/**
 * Custom hook for managing storage explorer items.
 *
 * @param {import('@').StorageProviderItem} item - The storage item.
 * @param {import('@').CloudStorageItemOptions} options - Additional options for the hook.
 * @returns {import('@').StorageExplorerItem} An object containing item properties and functions for interaction.
 */
export const useStorageExplorerItem = item => {
  const storageExplorer = useStorageExplorer();
  const createdBy = useMemo(() => item?.createdBy, [item?.createdBy]);
  const isFolder = useMemo(() => Boolean(item?.folder), [item?.folder]);
  const isSelected = useMemo(
    () => storageExplorer.selected?.id === item?.id,
    [storageExplorer.selected?.id, item?.id],
  );
  const icon = useMemo(() => getStorageItemIcon(item), [item]);
  const name = useMemo(() => {
    return isGoogleSheet(item) ? item.displayName : item.name;
  }, [item]);

  /**
   * Sets the current item as selected in the storage explorer.
   * @function
   */
  const handleSetSelected = useCallback(() => {
    storageExplorer.setSelected(item);
  }, [item, storageExplorer]);

  /**
   * Opens the current item using the storage explorer.
   * @function
   */
  const handleOpenItem = useCallback(() => {
    storageExplorer.openItem(item);
  }, [item, storageExplorer]);

  return useMemo(
    () => ({
      ...item,
      name,
      icon,
      isFolder,
      createdBy,
      isSelected,
      select: handleSetSelected,
      open  : handleOpenItem,
    }),
    [createdBy, handleOpenItem, handleSetSelected, icon, isFolder, isSelected, item, name],
  );
};
