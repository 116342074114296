import React, { useMemo } from 'react';
import { Button, KeyboardArrowLeftIcon } from '@mtb/ui';
import { useTranslation } from '../../../services/i18n';
import { useStorageExplorer } from '../hooks';

/**
 * `StorageExplorerBackButton` component.
 *
 * @param {Object} props - Component props.
 * @param {import('react').ReactNode} [props.icon=<KeyboardArrowLeftIcon />] - Icon to display in the button.
 * @param {Object} other - Other props to pass to the button.
 *
 * @returns {import('react').ReactElement} The rendered `StorageExplorerBackButton` component.
 */
export function StorageExplorerBackButton({ icon = <KeyboardArrowLeftIcon />, ...other }) {
  const [t] = useTranslation('connection');
  const { back, breadcrumbTrail } = useStorageExplorer();
  const disabled = useMemo(() => breadcrumbTrail.length < 1, [breadcrumbTrail.length]);

  return <Button
    disabled={disabled}
    icon={icon}
    title={t('back')}
    variant="highlight"
    onClick={back}
    {...other} />;
}

export default StorageExplorerBackButton;
