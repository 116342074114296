const WHITE_SPACE_REGEX = /\s/;
const Z_ANCHOR_REGEX = /[^\\]\\Z/;
const EXPRESSION_CHARACTERS = {
  REGEX_OPEN_REFERENCE : '${',
  REGEX_CLOSE_REFERENCE: '}',
};

/**
 * Parses a regular expression string and returns a function that will take the
 * references as properties in the first argument and evaluate the expression.
 * @param {string} str
 * @returns {{ func(refs?: Record<string, string>): RegExp; refs: string[]; } | null}
 * @example
 * const { func, refs } = parseReferenceRegex('^\\s*?${type}(virtual )?(const )?(\\S+)\\s+((?:.*;|(?:.*(,|\\()(?:${something}\\r\\n|\\n)))+${1})');
 * // refs ==> ['type', 'something', '_1']
 * // func ==> function anonymous({ type, something, _1 }) {
 * //   return RegExp(`^\\s*?${type}(virtual )?(const )?(\\S+)\\s+((?:.*;|(?:.*(,|\\()(?:${something}\\r\\n|\\n)))+${_1})`)
 * //   ^ Note the removal of whitespace from the variables and the prefixing of numerical variables!
 * // }
 * func({
 *   type: 'TYPE',
 *   something: 'SOMETHING',
 *   _1: 'THE_NUMBER_ONE'
 * }) // result: /^\s*?TYPE(virtual )?(const )?(\S+)\s+((?:.*;|(?:.*(,|\()(?:SOMETHING\r\n|\n)))+THE_NUMBER_ONE)/
 */
export const parseReferenceRegex = str => {
  if (Z_ANCHOR_REGEX.test(str)) {
    // Do not allow optional line-break
    return null;
  }

  let expression = str.trim();
  let regexFunction = '';
  const regexReferences = [];

  let variableReferenceOpen = false;
  let currentVariableReference = '';

  while (expression.length) {
    const char = expression[0];
    expression = expression.slice(1);

    // Open variable reference if '${', unless variable reference is open then it's an error
    if (`${char}${expression[0] ?? ''}` === EXPRESSION_CHARACTERS.REGEX_OPEN_REFERENCE) {
      if (variableReferenceOpen) {
        return null;
      }
      currentVariableReference = '';
      variableReferenceOpen = true;
      expression = expression.slice(1);
      continue;
    }
    // Close variable reference if '}' and reference is open
    if (char === EXPRESSION_CHARACTERS.REGEX_CLOSE_REFERENCE && variableReferenceOpen) {
      if (!isNaN(Number(currentVariableReference))) {
        currentVariableReference = `_${currentVariableReference}`;
      }
      regexReferences.push(currentVariableReference);
      regexFunction += `\${${currentVariableReference}}`;
      variableReferenceOpen = false;
      continue;
    }
    if (variableReferenceOpen) {
      // Ignore whitespace inside reference.
      if (WHITE_SPACE_REGEX.test(char)) {
        continue;
      }
      // Reference characters inside a reference are invalid
      if (char === '{' || char === '$') {
        return null;
      }
      // Append characters to current reference string
      currentVariableReference += char;
      continue;
    }
    regexFunction += (char === '\\' ? '\\\\' : char === '`' ? '\\`' : char); // Backslashes and backticks have to be escaped
  }

  // If a variable reference was never closed, it is invalid
  if (variableReferenceOpen) {
    return null;
  }

  // If the expression was empty, it is invalid
  if (!regexFunction.length) {
    return null;
  }

  return {
    // @ts-ignore
    func: Function(regexReferences.length ? `{ ${Array.from(new Set(regexReferences)).join(', ')} }` : '', `return RegExp(\`${regexFunction}\`)`),
    refs: Array.from(new Set(regexReferences)),
  };
};

/** @type {import('ajv').FormatDefinition<string>} */
const referenceRegexDefinition = {
  type    : 'string',
  validate: str => {
    if (typeof str !== 'string') {
      return false;
    }

    try {
      // If we use this library on any server, there is potential for a ReDoS attack. We could mitigate this by using
      // a RegExp parsing library rather than the built-in RegExp parser.
      const result = parseReferenceRegex(str);
      if (!result) {
        return false;
      }
      return Boolean(result.func(result.refs.reduce((acc, ref) => ({ ...acc, [ref]: '.' }), {})).source);
    } catch {
      return false;
    }
  },
};

export default referenceRegexDefinition;
