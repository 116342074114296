import type { ModuleConfig, ModuleConfigKey } from '../../core/modules/types';
import type RemoteModuleConfig from '../../core/remote-module-config';
import { LoggerClient } from '..';
import config from '../../config';
import { ROOT_MODULE_KEY } from '../../core/constants';
import MODULES, { FEATURE_FLAGGED_MODULES } from '../../core/modules';
import ModuleConfigManager from './config';
import { EXTENSION_MODULE_MAP } from './constants';
import ModuleEventManager from './event';

/**
 * ModuleClient is the wrapper around the module-related ecosystem i.e. remote modules, events, configurations, etc.
 * It serves as the orchestrator for managing and interacting with all the configured remote
 * modules within Platform. It acts as a central interface for interacting with the remote modules.
 */
class ModuleClient {
  #logger = LoggerClient.createNamedLogger('ModuleClient');

  constructor() {
    // Expose the modules for testing purposes on the client side
    // to make setup and mocking easier.
    if (process.env.NODE_ENV === 'test') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-next-line - Ignore indexing error.
      this.__MODULES__ = MODULES;
    }
  }

  /**
   * Gets the ModuleEventManager instance.
   * @returns {ModuleEventManager} The ModuleEventManager instance.
   */
  get Events() {
    return ModuleEventManager;
  }

  /**
   * Gets the root module configuration.
   * @returns {ModuleConfig} The root module configuration.
   */
  get ROOT() {
    return MODULES[ROOT_MODULE_KEY];
  }

  /**
   * Gets the platform module configuration.
   * @returns {ModuleConfig} The platform module configuration.
   */
  get PLATFORM() {
    return MODULES.PLATFORM;
  }

  /**
   * Gets the MSSO module configuration.
   * @returns {ModuleConfig} The MSSO module configuration.
   */
  get MSSO() {
    return MODULES.MSSO;
  }

  /**
   * Gets the BRAINSTORM module configuration.
   * @returns {ModuleConfig} The BRAINSTORM module configuration.
   */
  get BRAINSTORM() {
    return MODULES.BRAINSTORM;
  }

  /**
   * Gets the WSO module configuration.
   * @returns {ModuleConfig} The WSO module configuration.
   */
  get WSO() {
    return MODULES.WSO;
  }

  /**
   * Gets the DATACENTER module configuration.
   * @returns {ModuleConfig} The DATACENTER module configuration.
   */
  get DATACENTER() {
    return MODULES.DATACENTER;
  }

  /**
   * Gets the REPORTING_TOOL module configuration.
   * @returns {ModuleConfig} The REPORTING_TOOL module configuration.
   */
  get REPORTING_TOOL() {
    return MODULES.REPORTING_TOOL;
  }

  /**
   * Gets the DISCOVER module configuration.
   * @returns {ModuleConfig} The DISCOVER module configuration.
   */
  get DISCOVER() {
    return MODULES.DISCOVER;
  }

  /**
   * Gets the LEARNING_CENTER module configuration.
   * @returns {ModuleConfig} The LEARNING_CENTER module configuration.
   */
  get LEARNING_CENTER() {
    return MODULES.LEARNING_CENTER;
  }

  /**
   * Formats the module extension based on the given extension and options.
   * @param extension - The extension to format.
   * @param options - The options to format the extension.
   * @param options.includePeriod - Whether to include the period in the extension.
   * @returns The formatted module extension.
   */
  #formatModuleExtension(extension?: string, { includePeriod = false } = {}): string | undefined {
    if (!extension) {
      return undefined;
    }
    const extensionWithPeriod = extension.startsWith('.') ? extension : `.${extension}`;
    const extensionWithoutPeriod = extension.startsWith('.') ? extension.slice(1) : extension;
    return (includePeriod ? extensionWithPeriod : extensionWithoutPeriod).toLowerCase();
  }

  /**
   * Checks if the given module is enabled based on if the module is
   * feature flagged and the feature flag is enabled.
   * @param moduleKey - The key of the module.
   * @returns {boolean} True if the module is enabled, false otherwise.
   */
  isModuleEnabled(moduleKey: ModuleConfigKey) {
    if (MODULES?.[moduleKey] && config.feature_flag_enable_all_modules) {
      return true;
    }

    const isModuleFeatureFlagged = moduleKey in FEATURE_FLAGGED_MODULES;
    const isModuleEnabled = FEATURE_FLAGGED_MODULES[moduleKey];
    return !isModuleFeatureFlagged || (isModuleFeatureFlagged && isModuleEnabled);
  }

  /**
   * Gets the module configuration available in the Platform based on feature flags.
   * @param moduleConfigs - The module configurations to filter.
   */
  getEnabledModules() {
    if (config.feature_flag_enable_all_modules) {
      return Object.values(MODULES);
    }
    return Object.values(MODULES).filter(module => this.isModuleEnabled(module.key));
  }

  /**
   * Gets the module configuration for the given module key.
   * @param moduleConfigKey - The key of the module configuration.
   * @returns The module configuration for the given module key.
   */
  getEnabledModule(moduleConfigKey: ModuleConfigKey) {
    return this.getEnabledModules().find(module => module.key === moduleConfigKey);
  }

  /**
   * Gets the default module configuration based on the given extension.
   * @param extension - The extension to get the default module for.
   * @returns The default module configuration based on the given extension.
   */
  getDefaultModuleByExtension(extension?: string): ModuleConfig | undefined {
    const formattedExtension = this.#formatModuleExtension(extension, { includePeriod: true });
    const defaultModule = formattedExtension ? EXTENSION_MODULE_MAP[formattedExtension]?.defaultModule : undefined;
    if (!defaultModule) {
      return undefined;
    }
    const enabledDefaultModule = this.getEnabledModule(defaultModule?.key);
    return enabledDefaultModule;
  }

  /**
   * Gets the related module configuration using the given extension.
   */
  getModulesByExtension(extension?: string): ModuleConfig[] {
    const formattedExtension = this.#formatModuleExtension(extension, { includePeriod: true });
    const modules = formattedExtension ? EXTENSION_MODULE_MAP[formattedExtension]?.modules : [];
    if (!modules.length) {
      throw new Error(`No modules found for the extension: ${extension}`);
    }

    const enabledModules = modules.reduce((acc, module) => {
      if (!module) {
        return acc;
      }
      const enabledModule = this.getEnabledModule(module.key);
      if (enabledModule) {
        acc.push(enabledModule);
      }
      return acc;
    }, [] as ModuleConfig[]);
    return enabledModules;
  }

  /**
   * @param moduleConfigKey
   * @returns The module configuration for the given planeId.
   */
  getRemoteModuleConfig(moduleConfigKey: ModuleConfigKey) {
    return ModuleConfigManager.getRemoteModuleConfig(moduleConfigKey);
  }

  /**
   * Sets the configured module configuration into the stored module configurations and flushes any
   * events that were waiting for the module to be registered.
   * @param remoteModuleConfig
   */
  registerRemoteModuleConfig(remoteModuleConfig: RemoteModuleConfig) {
    this.#logger.log(
      `Module configuration received from ${remoteModuleConfig.key}, registering...`,
      remoteModuleConfig,
    );
    ModuleConfigManager.setRemoteModuleConfig(remoteModuleConfig);
    ModuleEventManager.flushEvents(remoteModuleConfig.key);
  }

  /**
   * Clears all the module configs from the config manager.
   */
  clearRemoteModuleConfigs() {
    ModuleConfigManager.clearRemoteModuleConfigs();
  }
}

const moduleClient = new ModuleClient();
export default moduleClient;
