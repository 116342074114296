import React from 'react';
import { Stack } from '@mtb/ui';
import { StorageExplorerBreadcrumbs } from '../StorageExplorerBreadcrumbs';
import { StorageExplorerPickerAlert } from '../StorageExplorerPickerAlert';
import { StorageExplorerTabs } from '../StorageExplorerTabs';

export const StorageExplorerToolbar = () => {
  return (
    <Stack
      data-testid="storage-explorer-toolbar"
      gap={1}
      sx={{ flexShrink: 0 }}>
      <StorageExplorerBreadcrumbs />
      <StorageExplorerPickerAlert />
      <Stack direction="row">
        <StorageExplorerTabs />
      </Stack>
    </Stack>
  );
};
