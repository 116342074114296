import { useMemo } from 'react';
import { Stack, Typography } from '@mtb/ui';
import { STORAGE_PROVIDER_KEYS, CLOUD_STORAGE_CATEGORIES } from '../../../constants';
import { useProvider } from '../../../hooks';
import { useTranslation } from '../../../services/i18n';
import { useStorageExplorer } from '../hooks';
import { ProviderAccountSettings } from './ProviderAccountSettings';
import { ProviderSaveSettings } from './ProviderSaveSettings';

const PROVIDER_TITLES = {
  [STORAGE_PROVIDER_KEYS.ONE_DRIVE]   : 'connection.oneDriveSetup',
  [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE]: 'connection.googleDriveSetup',
};

export const StorageExplorerProviderSettings = () => {
  const [t] = useTranslation();
  const { category, provider } = useStorageExplorer();
  const { isSignedIn } = useProvider(provider);
  const showSettings = useMemo(() => category === CLOUD_STORAGE_CATEGORIES.NONE, [category, isSignedIn, provider]);

  const providerTitle = useMemo(() => t(PROVIDER_TITLES[provider]), [t, provider]);

  if (!showSettings) {
    return null;
  }

  return (
    <Stack mt={2}>
      <Typography gutterBottom variant="title-xl" weight="semibold">
        {providerTitle}
      </Typography>
      <Stack gap={2} pl={3} pt={3}>
        <ProviderAccountSettings/>
        {isSignedIn && <ProviderSaveSettings/>}
      </Stack>
    </Stack>
  );
};
