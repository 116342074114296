import type { EnhancedStore, UnknownAction } from '@reduxjs/toolkit';
import type { EqualityFn, ReactReduxContextValue, TypedUseSelectorHook, NoInfer } from 'react-redux';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { createContext, createElement } from 'react';
import { Provider, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import MODULES, { FEATURE_FLAGGED_MODULES } from '../modules';
import { requestIdleCallback } from '../utils/request-idle-callback';
import { actions, asyncActions, reducer } from './reducers';
import { createStore } from './redux-helpers/create-store';
import selectors from './selectors';

const platformStore = createStore(reducer, actions, selectors, asyncActions, store => {
  store.actions.initializePlanes();
  requestIdleCallback(() => FEATURE_FLAGGED_MODULES?.DISCOVER && MODULES.DISCOVER.preloadRemoteModule?.());
  requestIdleCallback(
    () => FEATURE_FLAGGED_MODULES?.LEARNING_CENTER && MODULES.LEARNING_CENTER.preloadRemoteModule?.(),
  );
});

export type PlatformStoreType = typeof platformStore;

export type PlatformState = ReturnType<PlatformStoreType['getState']>;

export type PlatformDispatch = PlatformStoreType['dispatch'];

const PlatformContext = createContext<ReactReduxContextValue<unknown, UnknownAction> | null>(null);

const useStore = createStoreHook(PlatformContext);

const useDispatch: () => PlatformDispatch = createDispatchHook(PlatformContext);

const useSelectorBase: TypedUseSelectorHook<PlatformState> = createSelectorHook(PlatformContext);

const useSelector = <TSelected>(
  selector: (state: PlatformState) => TSelected,
  equalityFn?: EqualityFn<NoInfer<TSelected>>,
): TSelected => useSelectorBase(selector, equalityFn);

/**
 * The PlatformProvider component.
 * Wrap react-redux Provider with some initialization logic for overriding the default state
 */
const PlatformProvider: FunctionComponent<PropsWithChildren<React.ReactNode>> = ({ children }) => {
  return createElement(Provider, {
    context : PlatformContext,
    store   : platformStore as unknown as EnhancedStore<PlatformState>,
    children: createElement(PersistGate, {
      loading  : null,
      persistor: platformStore.persistor,
      children,
    }),
  });
};

export { PlatformProvider, platformStore, useDispatch, useSelector, useStore };
