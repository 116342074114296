const WHITE_SPACE_REGEX = /\s/;
const MATH_SYMBOLS_REGEX = /[\d+\-*^./()%]/;
const MATH_OPERATOR_REGEX = /[+\-*^/%]/;
const EXPRESSION_CHARACTERS = {
  OPEN_EXPRESSION  : '=',
  OPEN_REFERENCE   : '{',
  CLOSE_REFERENCE  : '}',
  OPEN_PARENTHESIS : '(',
  CLOSE_PARENTHESIS: ')',
};

/**
 * Parses a math expression string and returns a function that will take the
 * references as properties in the first argument and evaluate the expression.
 * @param {string} str
 * @returns {{ func(refs?: Record<string, number>): number; refs: string[]; } | null}
 * @example
 * const { func, refs } = parseMathExpression('=((({Target Value} - {Other Value}) * 42 / 64) - (100 + 3000)) % 10');
 * // refs ==> ['TargetValue', 'OtherValue']
 * // func ==> function anonymous({ TargetValue, OtherValue }) {
 * //   return (((TargetValue-OtherValue)*42/64)-(100+3000))%10
 * //   ^ Note the removal of whitespace from the variables!
 * // }
 * func({ TargetValue: 2, OtherValue: 1 }) // result: -9.34375
 */
export const parseMathExpression = str => {
  let expression = str.trim();
  if (!expression.startsWith(EXPRESSION_CHARACTERS.OPEN_EXPRESSION)) {
    return null;
  }

  expression = expression.slice(1); // Remove leading '='
  let mathFunction = '';
  const mathReferences = [];

  let variableReferenceOpen = false;
  let currentVariableReference = '';
  let previousTokenWasOperator = false;
  let parenthesisCounter = 0;

  while (expression.length) {
    const char = expression[0];
    expression = expression.slice(1);

    // Only one '=' allow per expression
    if (char === EXPRESSION_CHARACTERS.OPEN_EXPRESSION) {
      return null;
    }

    // Ignore whitespace inside expression.
    if (WHITE_SPACE_REGEX.test(char)) {
      continue;
    }

    // Open variable reference if '{', unless variable reference is open then it's an error
    if (char === EXPRESSION_CHARACTERS.OPEN_REFERENCE) {
      if (variableReferenceOpen) {
        return null;
      }
      currentVariableReference = '';
      variableReferenceOpen = true;
      previousTokenWasOperator = false;
      continue;
    }
    // Close variable reference if '}', unless variable reference is closed then it's an error
    if (char === EXPRESSION_CHARACTERS.CLOSE_REFERENCE) {
      if (!variableReferenceOpen) {
        return null;
      }
      mathReferences.push(currentVariableReference);
      mathFunction += currentVariableReference;
      variableReferenceOpen = false;
      previousTokenWasOperator = false;
      continue;
    }
    // Ignore anything inside variable reference and append characters to current string
    if (variableReferenceOpen) {
      currentVariableReference += char;
      continue;
    }
    if (!MATH_SYMBOLS_REGEX.test(char)) {
      return null;
    }

    if (MATH_OPERATOR_REGEX.test(char)) {
      if (previousTokenWasOperator) {
        return null;
      }
      previousTokenWasOperator = true;
    } else {
      previousTokenWasOperator = false;
    }

    if (char === EXPRESSION_CHARACTERS.OPEN_PARENTHESIS) {
      parenthesisCounter++;
    }
    if (char === EXPRESSION_CHARACTERS.CLOSE_PARENTHESIS) {
      parenthesisCounter--;
    }

    mathFunction += char === '^' ? '**' : char; // Convert our '^' to '**' to do exponents
  }

  // If a variable reference was never closed, it is invalid
  if (variableReferenceOpen) {
    return null;
  }

  // If there was an uneven number of parenthesis, it is invalid
  if (parenthesisCounter !== 0) {
    return null;
  }

  // If the expression was empty, it is invalid
  if (!mathFunction.length) {
    return null;
  }

  return {
    // @ts-ignore
    func: Function(
      mathReferences.length ? `{ ${Array.from(new Set(mathReferences)).join(', ')} }` : '',
      `return ${mathFunction}`,
    ),
    refs: Array.from(new Set(mathReferences)),
  };
};

/** @type {import('ajv').FormatDefinition<string>} */
const mathExpressionDefinition = {
  type    : 'string',
  validate: str => {
    if (typeof str !== 'string') {
      return false;
    }
    return Boolean(parseMathExpression(str));
  },
};

export default mathExpressionDefinition;
