import { DEFAULT_FIELD_OPTIONS_MAP, DEFAULT_STRING_OPTIONS, DEFAULT_NUMBER_OPTIONS } from './constants';
import Field from './Field';
import FieldSet from './FieldSet';

export class FieldGroup extends Field {
  /**
   *****************************************************************************
   * Protected Properties
   *****************************************************************************
   */
  /** @type {Map<string, FieldGroup>} */
  _fieldGroups = new Map();

  /** @type {Map<string, FieldSet>} */
  _fieldSets = new Map();

  /**
   *****************************************************************************
   * Public Methods
   *****************************************************************************
   */
  /**
   * Creates a new field group object
   * @param {import('./constants').ValueOfDefaultFieldOptionsMap} [options]
   */
  constructor(options = undefined) {
    const fieldOptions = Object.assign({ isFieldGroup: true }, DEFAULT_FIELD_OPTIONS_MAP['object'], options ?? {});
    super('object', fieldOptions);
  }

  /**
   * Base function for adding any kind of field.
   * @param {string} key
   * @param {import('./constants').KeyOfDefaultFieldOptionsMap} [type]
   * @param {import('./constants').ValueOfDefaultFieldOptionsMap} [options]
   */
  addField(key, type = 'object', options = undefined) {
    const field = new Field(type, options);
    field._root = this._root;
    if (this._schema?.properties && field._schema) {
      this._schema.properties[key] = field._schema;
      if (field._metaProperties?.isRequired && this._schema?.required) {
        this._schema.required.push(key);
      }
      if (field._metaProperties?.dynamicDefault) {
        if (this._root) {
          this._root._hasDynamicDefaults = true;
        }
        if (!this._schema?.dynamicDefaults) {
          // @ts-ignore
          this._schema.dynamicDefaults = {};
        }
        // @ts-ignore
        this._schema.dynamicDefaults[key] = field._metaProperties.dynamicDefault;
      }
      if (field._metaProperties?.asyncDynamicDefault) {
        if (this._root) {
          this._root._hasDynamicDefaults = true;
        }
        if (!this._root?._isAsync) {
          throw new Error(
            'Using async dynamic defaults on a non-async Schema is forbidden!\nPlease use an AsyncSchema instead!',
          );
        }
        if (!this._schema?.asyncDynamicDefaults) {
          // @ts-ignore
          this._schema.asyncDynamicDefaults = {};
        }
        // @ts-ignore
        this._schema.asyncDynamicDefaults[key] = field._metaProperties.asyncDynamicDefault;
      }
    }
    return this;
  }

  /**
   * Add a single string field to the schema
   * @param {string} key
   * @param {import('./constants').DefaultStringFieldOptions} [options]
   */
  addStringField(key, options) {
    return this.addField(key, 'string', Object.assign({}, DEFAULT_STRING_OPTIONS, options));
  }

  /**
   * Add a single numeric field to the schema
   * @param {string} key
   * @param {boolean} [integersOnly]
   * @param {import('./constants').DefaultNumberFieldOptions} [options]
   */
  addNumericField(key, integersOnly, options) {
    return this.addField(key, integersOnly ? 'integer' : 'number', Object.assign({}, DEFAULT_NUMBER_OPTIONS, options));
  }

  /**
   * Add a single date/time field to the schema.
   * Date/Time/DateTime follows [RFC3339](https://www.rfc-editor.org/rfc/rfc3339#section-5.6),
   * e.g., a valid date-time string would be: `2022-11-30 18:45:55`. You can also
   * pass the result of `Date.prototype.toISOString` for a date-time value. Or
   * pass either the date (`Date.prototype.toISOString.split('T')[0]`)
   * or time (`Date.prototype.toISOString.split('T')[1]`).
   * @param {string} key
   * @param {'date'|'date-time'|'time'} [dateType]
   * @param {import('./constants').DefaultStringFieldOptions} [options]
   */
  addDateTimeField(key, dateType = 'date', options) {
    return this.addStringField(key, Object.assign({}, DEFAULT_STRING_OPTIONS, options, { format: dateType }));
  }

  /**
   * Adds a new field group to the schema
   * @param {string} key
   * @param {import('./constants').DefaultFieldOptions} [options]
   */
  addFieldGroup(key, options) {
    if (this._fieldGroups.has(key)) {
      console.warn(`Attempting to add duplicate field group: ${key}, returning reference to existiing field group!`);
      return this;
    }
    const fieldGroup = new FieldGroup(options);
    fieldGroup._root = this._root;
    if (this._schema?.properties && fieldGroup._schema !== null) {
      this._schema.properties[key] = fieldGroup._schema;
      if (fieldGroup._metaProperties?.isRequired && this._schema?.required) {
        this._schema.required.push(key);
      }
      this._fieldGroups.set(key, fieldGroup);
    }
    return this;
  }

  /**
   * Gets a field group by name
   * @param {string} key
   * @returns {FieldGroup|undefined}
   */
  getFieldGroup(key) {
    if (!this._fieldGroups.has(key)) {
      return;
    }
    return this._fieldGroups.get(key);
  }

  /**
   * Adds a new field set to the schema
   * @param {string} key
   * @param {import('./constants').DefaultFieldOptions} [options]
   */
  addFieldSet(key, options) {
    const fieldSet = new FieldSet(options);
    fieldSet._root = this._root;
    if (this._schema?.properties && fieldSet._schema !== null) {
      this._schema.properties[key] = fieldSet._schema;
      if (fieldSet._metaProperties?.isRequired && this._schema?.required) {
        this._schema.required.push(key);
      }
      this._fieldSets.set(key, fieldSet);
    }
    return this;
  }

  /**
   * Gets a field set by name
   * @param {string} key
   * @returns {FieldSet|undefined}
   */
  getFieldSet(key) {
    if (!this._fieldSets.has(key)) {
      return;
    }
    return this._fieldSets.get(key);
  }
}

export default FieldGroup;
