import { React, useCallback } from 'react';
import { Alert, Button, Stack, Typography, Spacer } from '@mtb/ui';
import { useCloudStorageStore } from '../../../hooks/useCloudStorageStore';
import { useTranslation } from '../../../services/i18n';
import { useStorageExplorer } from '../hooks';

const StorageExplorerPickerButton = () => {
  const [t] = useTranslation();
  const store = useCloudStorageStore();
  const { refresh } = useStorageExplorer();

  const openPicker = useCallback(async () => {
    const didPickItems = await store.openPicker();
    if (didPickItems) {
      await refresh();
    }
  }, [refresh, store]);

  return <Button onClick={openPicker}>{t('picker.grantAccess')}</Button>;
};

export const StorageExplorerPickerAlert = ({ variant }) => {
  const [t] = useTranslation();
  const store = useCloudStorageStore();

  if (!store.supportsPicker()) {
    return null;
  }

  return (
    <Alert
      align="center"
      as={Stack}
      direction="row"
      severity="info"
      sx={{ '.mtb-ui-alert-message': { width: '100%' } }}>
      <Stack
        align={variant === 'narrow' ? 'flex-start' : 'center'}
        direction={variant === 'narrow' ? 'column' : 'row'}
        spacing={1}
        width="100%">
        <Typography>{t('picker.whitelistFiles')}</Typography>
        <Spacer />
        <StorageExplorerPickerButton />
      </Stack>
    </Alert>
  );
};
