import type { ModuleConfig } from '../types';
import { RemoteLearningCenter, loadRemoteLearningCenter } from './remote';

const CONFIG: ModuleConfig = {
  key                : 'LEARNING_CENTER',
  title              : 'learn',
  icon               : 'PagesSolidIcon',
  preloadRemoteModule: loadRemoteLearningCenter,
  remoteModule       : RemoteLearningCenter,
};

export default CONFIG;
