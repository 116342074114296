import type { RawPlatformStoreState } from '../reducers';
import { deepIsEqual } from '@mtb/utilities';
import { createSelectorCreator, lruMemoize } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';

const createSelector = createSelectorCreator({
  memoize       : lruMemoize,
  memoizeOptions: {
    equalityCheck      : shallowEqual,
    resultEqualityCheck: deepIsEqual,
    maxSize            : 10,
  },
  argsMemoize       : lruMemoize,
  argsMemoizeOptions: {
    equalityCheck      : shallowEqual,
    resultEqualityCheck: deepIsEqual,
    maxSize            : 10,
  },
  devModeChecks: {
    inputStabilityCheck  : process.env.NODE_ENV !== 'production' ? 'always' : 'never',
    identityFunctionCheck: process.env.NODE_ENV !== 'production' ? 'always' : 'never',
  },
}).withTypes<RawPlatformStoreState>();

export { createSelector };
