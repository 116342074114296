import { createElement } from 'react';
import CenteredCircularProgress from '../../utils/centered-circular-progress';
import withErrorBoundary from '../../utils/error-boundary';
import lazy from '../../utils/lazy';
import createIntegratedRemoteModuleLoader from '../../utils/load-remote-module';
import remoteModuleUrlConfigs from '../../utils/remote-url-configs';
import withSuspense from '../../utils/with-suspense';

const loadRemoteBrainstorm = createIntegratedRemoteModuleLoader(
  'mtb_workspace',
  'BRAINSTORM',
  remoteModuleUrlConfigs.BRAINSTORM,
);

const LazyRemoteBrainstorm = lazy(loadRemoteBrainstorm);

const RemoteBrainstorm = withErrorBoundary(
  withSuspense(LazyRemoteBrainstorm, () => createElement(CenteredCircularProgress)),
  'RemoteBrainstorm',
);

export { RemoteBrainstorm, loadRemoteBrainstorm };
