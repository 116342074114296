import { deepClone } from '@mtb/utilities';

const DEFAULT_SANITIZER = (value: unknown) => value;

/**
 * Class representing a rehydrated state.
 */
class RehydrateState {
  key: string;
  state: Map<string, unknown>;
  sanitizer: (value: unknown) => unknown;

  /**
   * Create a new rehydrated state.
   * @param string key - The key for the state.
   * @param options - Optional settings.
   * @param options.sanitizer - Function to sanitize any non-serializable values before storing them.
   */
  constructor(key: string, { sanitizer = DEFAULT_SANITIZER }: { sanitizer?: (value: unknown) => unknown } = {}) {
    if (process.env.NODE_ENV !== 'production') {
      // @ts-expect-error TODO: Fix window types?
      if (!window[key]) {
        // @ts-expect-error TODO: Fix window types?
        window[key] = {};
      }

      // @ts-expect-error TODO: Fix window types?
      window[key].RehydrateState = /** @type {RehydrateState} */ this;
    }

    this.key = key;
    this.state = new Map<string, unknown>();
    this.sanitizer = sanitizer;
  }

  /**
   * Get the value of a specific key from the state.
   * @param key - The key to get.
   * @returns The value of the key.
   */
  get(key: string): unknown {
    return this.state.get(key);
  }

  /**
   * Set the value of a specific key in the state.
   * @param key - The key to set.
   * @param value - The value to set.
   */
  set(key: string, value: unknown) {
    const sanitizedValue = this.sanitizer(value);
    this.state.set(key, deepClone(sanitizedValue));
  }

  /**
   * Clear a specific key from the state.
   * @param {string} key - The key to clear.
   */
  clear(key: string) {
    this.state.delete(key);
  }
}

export default RehydrateState;
