import React, { useMemo } from 'react';
import { useMenu } from '@mtb/ui';
import { StorageProjectMenuContext, initialState } from './context';

/**
 * @param {import('@').StorageProjectMenuContext & { children?: import('react').ReactNode }} props
 */
export const StorageProjectMenu = ({
  projectId,
  onFlush,
  onReopenProject,
  onChange,
  children,
}) => {
  const menuProps = useMenu({ id: 'storage-project' });

  const values = useMemo(() => {
    return {
      ...initialState,
      projectId,
      menuProps,
      onFlush,
      onReopenProject,
      onChange,
    };
  }, [projectId, menuProps, onFlush, onReopenProject, onChange]);

  return (
    <StorageProjectMenuContext.Provider value={values}>
      {children}
    </StorageProjectMenuContext.Provider>
  );
};

export default StorageProjectMenu;
