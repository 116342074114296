import type { StackProps } from '@mtb/ui/types/components';
import type { SXCSSProperties } from '@mtb/ui/types/types';
import type { FunctionComponent } from 'react';
import { createElement } from 'react';
import { CircularProgress, Stack } from '@mtb/ui';

const CenteredCircularProgress: FunctionComponent<StackProps & SXCSSProperties> = props => {
  return createElement(
    Stack,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TSC is seeing an invalid error here
    {
      alignItems    : 'center',
      height        : '100%',
      justifyContent: 'center',
      width         : '100%',
      ...props,
    },
    createElement(CircularProgress),
  );
};

CenteredCircularProgress.displayName = 'CenteredCircularProgress';

export default CenteredCircularProgress;
export { CenteredCircularProgress };
