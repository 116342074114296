import React, { Children, useMemo, useCallback } from 'react';
import {
  ListItemButton,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../../constants';
import { useStorageExplorer } from '../../hooks';

export const CloudProviderHeader = ({ children, title, provider }) => {
  const child = Children.only(children);
  const { provider: explorerProvider, setProvider, category: explorerCategory, setCategory } = useStorageExplorer();

  const isSelected = useMemo(
    () => explorerProvider === provider && explorerCategory === CLOUD_STORAGE_CATEGORIES.NONE,
    [explorerProvider, provider, explorerCategory],
  );

  const handleProviderChange = useCallback(
    () => {
      setProvider(provider);
      setCategory(CLOUD_STORAGE_CATEGORIES.NONE);
    },
    [provider, setProvider, setCategory],
  );

  return (
    <ListItemButton
      selected={isSelected}
      sx={{
        '&.mtb-ui-disabled': {
          opacity: 1,
        },
      }}
      onClick={handleProviderChange}>
      <ListItemIcon>
        {child}
      </ListItemIcon>
      <ListItemText>
        <Typography
          noWrap
          variant="title-sm"
          weight="regular">
          {title}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};
