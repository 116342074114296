import { createContext } from 'react';

export const initialState = /** @type {import('@').StorageProjectMenuContext} */ ({
  projectId      : null,
  menuProps      : {},
  onFlush        : () => Promise.resolve(),
  onReopenProject: () => undefined,
  onChange       : () => undefined,
});

export const StorageProjectMenuContext = createContext(initialState);
