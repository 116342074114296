import { createContext } from 'react';

export const StorageExplorerTableContext = createContext({
  isLoading        : false,
  columns          : [],
  tableContainerRef: null,
  rows             : [],
  ascOrder         : true,
  sortBy           : 'recent',
  toggleTableOrder : () => undefined,
  setTableSort     : () => undefined,
});
