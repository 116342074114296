import type { ComponentType, LazyExoticComponent } from 'react';
import { lazy as reactLazy } from 'react';

/**
 * A factory function to lazily load a React component with retry logic.
 *
 * @template T - The type of the React component to be lazy-loaded.
 * @param factory - A function that returns a Promise resolving to the component to be lazy-loaded.
 * @returns The lazy-loaded component.
 */
const lazy = <T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): LazyExoticComponent<T> => {
  const safeFactory = async () => {
    let attempts = 0;
    let error = null;

    while (attempts < 3) {
      try {
        return await factory();
      } catch (e) {
        if (!error) {
          error = e;
        }
        attempts++;
      }
    }
    throw error;
  };

  return reactLazy(safeFactory);
};

export default lazy;
export { lazy };
