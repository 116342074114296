import React, { forwardRef } from 'react';
import { Button } from '@mtb/ui';
import { FileUploader } from '../FileUploader';

export const FileUploadButton = forwardRef(({ children, ...other }, ref) => {
  return (
    <FileUploader>
      <Button
        ref={ref}
        data-testid="file-upload-button"
        {...other}>
        {children}
      </Button>
    </FileUploader>
  );
});
