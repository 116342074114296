/**
 * Convert a string to kebab-case
 * @param input - The camel case string to convert.
 * @returns The kebab case version of the input string.
 */
export function kebabCase(input: string | undefined): string {
  if (!input || typeof input !== 'string') {
    return '';
  }

  return input?.replaceAll('_', '-').toLowerCase();
}
