import type { BoundAsyncThunk, AsyncThunkConfig, GetExtra, GenericAsyncThunk } from './types';
import type { AsyncThunk, GetDispatch, GetState } from '@reduxjs/toolkit';
import type { Dispatch } from 'redux';

function bindAsyncAsyncThunk(
  asyncThunk: AsyncThunk<any, any, any>,
  dispatch: GetDispatch<AsyncThunkConfig>,
  getState: () => GetState<AsyncThunkConfig>,
  extra: GetExtra<AsyncThunkConfig>,
): BoundAsyncThunk<AsyncThunk<any, any, AsyncThunkConfig>> {
  return function (...args) {
    return asyncThunk(...args)(dispatch, getState, extra) as ReturnType<
      BoundAsyncThunk<AsyncThunk<any, any, AsyncThunkConfig>>
    >;
  };
}

function bindAsyncActionCreators(
  asyncThunks: { [key: string]: GenericAsyncThunk },
  dispatch: Dispatch,
  getState: () => GetState<AsyncThunkConfig>,
  extra: GetExtra<AsyncThunkConfig>,
): { [key in keyof typeof asyncThunks]: BoundAsyncThunk<(typeof asyncThunks)[key]> } {
  const boundAsyncThunks: { [key: string]: BoundAsyncThunk<any> } = {};
  for (const key in asyncThunks) {
    const asyncThunk = asyncThunks[key];
    if (typeof asyncThunk === 'function') {
      boundAsyncThunks[key] = bindAsyncAsyncThunk(asyncThunk, dispatch, getState, extra);
    }
  }
  return boundAsyncThunks;
}

export { bindAsyncActionCreators, bindAsyncAsyncThunk };
