import { createSelector } from '../../redux-helpers/create-selector';

const selectors = {
  snackPack: createSelector(
    state => state.snackbar.snackPack,
    snackbar => [...snackbar],
  ),
  snack: createSelector(
    state => state.snackbar.snack,
    snack => ({ ...snack }),
  ),
  isSnackbarOpen: createSelector([state => state.snackbar.open], open => Boolean(open)),
};

export default selectors;
