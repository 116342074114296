
import type { UserState } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { LoggerClient } from '../../../../../clients';
import { fetchSubscriptions } from './thunk';

export const fetchSubscriptionsReducer = (builder: ActionReducerMapBuilder<UserState>) => {
  return builder
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .addCase(fetchSubscriptions.pending, () => {})
    .addCase(
      fetchSubscriptions.fulfilled,
      (state, action) => {
        state.subscriptions = action.payload.subscriptions;
      })
    .addCase(
      fetchSubscriptions.rejected, (_state, action) => {
        const error = action.payload as Error;
        LoggerClient.error('[fetchSubscriptions] Error creating plane...', error);
      });
};
