import { useCallback, useMemo } from 'react';
import { Breadcrumbs, KeyboardArrowRightIcon, Link, Stack, Typography } from '@mtb/ui';
import { useCloudStorageStore } from '../../../hooks';
import { useTranslation } from '../../../services/i18n';
import { getFolderName } from '../../../utils';
import { useStorageExplorer } from '../hooks';

const PATH_TRANSLATIONS = {
  recent: 'connection.recentFiles',
  all   : 'connection.files',
  shared: 'connection.sharedFiles',
};

/**
 * `StorageExplorerBreadcrumbs` component.
 *
 * @param {Object} props - Component props.
 * @param {number} [props.maxItems=5] - Maximum number of breadcrumb items to display.
 * @param {boolean} [props.disabled=false] - Whether the breadcrumbs are disabled.
 * @param {Object} other - Other props to pass to the breadcrumbs.
 *
 * @returns {import('react').ReactElement} The rendered `StorageExplorerBreadcrumbs` component.
 */
export const StorageExplorerBreadcrumbs = ({ maxItems = 5, disabled = false, ...other }) => {
  const [t] = useTranslation();
  const { type } = useCloudStorageStore();
  const { folder, category, setCategory, setFolder, breadcrumbTrail } = useStorageExplorer();
  const underline = useMemo(() => (disabled ? 'none' : 'hover'), [disabled]);
  const tabIndex = useMemo(() => (disabled ? -1 : 0), [disabled]);
  const linkSx = useMemo(() => ({ pointerEvents: disabled ? 'none' : 'auto' }), [disabled]);
  const lastBreadcrumb = useMemo(() => breadcrumbTrail.at(-1), [breadcrumbTrail]);
  const lastBreadcrumbName = useMemo(() => getFolderName(type, lastBreadcrumb), [lastBreadcrumb, type]);

  const handleOnCategoryBreadcrumbClick = useCallback(() => {
    setCategory(category, { force: breadcrumbTrail.length });
  }, [breadcrumbTrail.length, category, setCategory]);

  const handleOnBreadcrumbClick = useCallback(
    breadcrumb => () => {
      if (breadcrumb.id !== folder?.id) {
        setFolder(breadcrumb);
      }
    },
    [folder?.id, setFolder],
  );

  return maxItems <= 1 ? (
    <Link
      color="default"
      data-testid="storage-explorer-breadcrumbs"
      sx={{ ...linkSx, px: 2 }}
      tabIndex={tabIndex}
      underline={underline}
      onClick={handleOnBreadcrumbClick(breadcrumbTrail.at(-1))}
      {...other}>
      <Typography weight="medium">
        {lastBreadcrumbName}
      </Typography>
    </Link>
  ) : (
    <Breadcrumbs
      aria-label="breadcrumb"
      as={Stack}
      data-testid="storage-explorer-breadcrumbs"
      height={30}
      justifyContent="center"
      maxItems={maxItems}
      separator={<KeyboardArrowRightIcon sx={{ width: 16, height: 16 }} />}
      sx={{ px: 2 }}
      {...other}>
      <Link
        color="default"
        sx={linkSx}
        tabIndex={tabIndex}
        underline={underline}
        onClick={handleOnCategoryBreadcrumbClick}>
        <Typography weight="medium">
          {t(PATH_TRANSLATIONS[category])}
        </Typography>
      </Link>
      {breadcrumbTrail.map((breadcrumb, i) => {
        const isLast = i === breadcrumbTrail.length - 1;
        return (
          <Link
            key={breadcrumb.id}
            color="default"
            disabled={isLast}
            sx={linkSx}
            tabIndex={tabIndex}
            underline={isLast ? 'none' : underline}
            onClick={handleOnBreadcrumbClick(breadcrumb)}>
            <Typography
              color={isLast ? 'text.secondary' : 'text.primary'}
              weight="medium">
              {breadcrumb.name}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
