import { TableCell } from '@mtb/ui';
import { useStorageExplorerTable } from '../hooks';
import { StorageExplorerTableRowCellSkeleton } from './StorageExplorerTableRowCellSkeleton';

export function StorageExplorerTableRowCell({ row, column, ...other }) {
  const { isLoading } = useStorageExplorerTable();

  return (
    <TableCell
      key={column.id}
      align={column.align}
      sx={{
        px: 2,
        py: column.paddingY ?? 1.5,
        ...(isLoading && { pointerEvents: 'none' }),
        ...(column.width && { width: column.width }),
      }}
      {...other}>
      <StorageExplorerTableRowCellSkeleton
        disableSkeleton={column.disableSkeleton}>
        {typeof column.render === 'function'
          ? column.render(row)
          : column.render}
      </StorageExplorerTableRowCellSkeleton>
    </TableCell>
  );
}
