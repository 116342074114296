/**
 * @param {() => boolean | Promise<boolean>} condition
 * @param {number} timeout
 * @param {number} interval
 * @returns {Promise<boolean>}
 */
export const waitForCondition = async (condition, timeout = 5000, interval = 50) => {
  if (!condition || typeof condition !== 'function' || (await condition())) {
    return true;
  }
  return new Promise(async res => {
    const startTime = Date.now();
    const looper = async () => {
      if (await condition()) {
        return res(true);
      }
      if (Date.now() >= startTime + timeout) {
        return res(false);
      }
      setTimeout(looper, interval);
    };
    looper();
  });
};

export default waitForCondition;
