import React from 'react';
import { Spacer, Stack, FileUploadIcon, useMediaQuery } from '@mtb/ui';
import { useTranslation } from '../../../services/i18n';
import { FileSearch } from '../../FileSearch';
import { FileUploadButton } from '../../FileUploadButton';
import { SaveToButton } from '../../SaveToButton';
import { SignOutButton } from '../../SignOutButton';
import { StorageProviderLogo } from '../../StorageProviderLogo';

const StorageExplorerHeaderInner = () => {
  const [t] = useTranslation();
  const isSmallScreen = useMediaQuery(t => t.breakpoints.down('md'));

  return (
    <>
      <SaveToButton />
      <Stack
        data-testid="storage-explorer-header"
        direction="row"
        flexWrap="wrap"
        gap={2}>
        <Stack
          alignItems="center"
          data-testid="storage-explorer-header-storage-provider"
          direction="row">
          <StorageProviderLogo />
          <Stack
            direction="row"
            px={1}>
            <SignOutButton />
          </Stack>
        </Stack>
        <Spacer />
        <Stack
          alignItems="center"
          data-testid="storage-explorer-header-file-buttons"
          direction="row"
          gap={2}>
          <FileSearch />
          <FileUploadButton icon={isSmallScreen && <FileUploadIcon />}>
            {!isSmallScreen && (
              <>
                <FileUploadIcon />
                {t('connection.uploadOpen')}
              </>
            )}
          </FileUploadButton>
        </Stack>
      </Stack>
    </>
  );
};

export const StorageExplorerHeader = ({ children }) => {
  return <Stack>{children || <StorageExplorerHeaderInner />}</Stack>;
};
