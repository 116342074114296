/* eslint-disable @typescript-eslint/no-unused-vars */
import type { InitializeI18nOptions, I18nLocale } from './types';
import { DEFAULT_LOCALE } from './constants';

/**
 * I18n Client is a wrapper around the i18n library the client initializes and then calls
 * this client's initialize method to set the i18n library instance, for use on the Platform core side.
 */
class I18nClient {
  localePreferenceKey = 'pref-locale-platform';

  detectLocale(): I18nLocale {
    return { ...DEFAULT_LOCALE };
  }

  getLocale(langOrRegion: string, limit?: 'full' | 'language' | 'region' | undefined): I18nLocale {
    return { ...DEFAULT_LOCALE };
  }

  changeLanguage(language: string): Promise<void> {
    return Promise.resolve();
  }

  translate(key: string, options?: Record<string, unknown>): string {
    return `[${key}]`;
  }

  t(key: string, options?: Record<string, unknown>): string {
    return this.translate(key, options);
  }

  initialize(i18nInstance: InitializeI18nOptions): void {
    this.detectLocale = i18nInstance.detectLocale;
    this.getLocale = i18nInstance.getLocale;
    this.changeLanguage = i18nInstance.changeLanguage;
    this.translate = i18nInstance.translate;
  }
}

const i18nClient = new I18nClient();
export default i18nClient;
