import type { AppRailDrawerIds, Layout } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { PLATFORM_APPRAIL_DRAWER_IDS } from '../../../../module-config';
import { ROOT_MODULE_KEY } from '../../../constants';
import { setCurrentLayout } from './set-current-layout';

const initialState: Layout = {
  loading         : false,
  currentModuleKey: ROOT_MODULE_KEY,
  currentPlaneId  : undefined,
  maxPlanesDialog : false,
  appRailDrawers  : Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(PLATFORM_APPRAIL_DRAWER_IDS).map(([_, id]) => [id, false]),
  ) as Record<AppRailDrawerIds, boolean>,
};

const layout = createSlice({
  name    : 'layout',
  initialState,
  reducers: {
    setCurrentLayout,
    setMaxPlanesDialog: {
      reducer: (state, action: PayloadAction<{ open: Layout['maxPlanesDialog'] }>) => {
        state.maxPlanesDialog = action.payload.open;
      },
      prepare: (open: boolean) => {
        return { payload: { open } };
      },
    },
    setLoading: {
      reducer: (state, action: PayloadAction<{ loading: Layout['loading'] }>) => {
        state.loading = action.payload.loading;
      },
      prepare: (loading: Layout['loading']) => {
        return { payload: { loading } };
      },
    },
    setAppRailDrawerOpen: {
      reducer: (state, action: PayloadAction<Partial<Record<AppRailDrawerIds, boolean>>>) => {
        for (const id in action.payload) {
          if (Object.prototype.hasOwnProperty.call(state.appRailDrawers, id)) {
            state.appRailDrawers[id as AppRailDrawerIds] = action.payload[id as AppRailDrawerIds] ?? false;
          } else {
            throw new Error(`Invalid app rail drawer id: ${id}`);
          }
        }
      },
      prepare: (id: AppRailDrawerIds, open: boolean) => {
        return { payload: { [id]: open } };
      },
    },
  },
});

const { reducer, actions, name, getInitialState } = layout;

export * from './types';
export { actions, getInitialState, name, reducer };

