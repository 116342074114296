import config from './config';

const xsrfCookieName = 'MTB-XSRF';
const xsrfHeaderName = 'MTB-XSRF';

function getCookie(cname: string) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return '';
}

await (async () => {
  try {
    const response = await fetch('/api/v1/config', {
      headers : { [xsrfHeaderName]: getCookie(xsrfCookieName) },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Global definition for fetch options doesn't have 'priority'
      priority: 'high',
    });
    if (!response.ok) {
      throw response;
    }
    const newConfig = await response.json();
    for (const key of Object.keys(config)) {
      delete config[key as keyof typeof config];
    }
    for (const key of Object.keys(newConfig)) {
      // @ts-expect-error We're setting the values for the first time, so bypass the readonly-nature of the type
      config[key as keyof typeof config] = newConfig[key];
    }
  } catch (e) {
    // @ts-expect-error In the event of a failure to fetch the config, we need to ensure this flag is true so we don't show any content we shouldn't be
    config.feature_flag_msso_root = true;
    if (e && typeof e === 'object' && 'status' in e && (e.status === 401 || e.status === 403)) {
      // Ignore any auth errors since this fetch isn't responsible for those
      return;
    }
    console.error('Failed to fetch config: ', e);
  }
})();
