import type { ModuleConfig } from '../types';
import { RemoteDiscover, loadRemoteDiscover } from './remote';

const CONFIG: ModuleConfig = {
  key                : 'DISCOVER',
  title              : 'discover',
  icon               : 'RocketSolidIcon',
  preloadRemoteModule: loadRemoteDiscover,
  remoteModule       : RemoteDiscover,
};

export default CONFIG;
