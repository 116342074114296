import type { GenericAsyncThunk, GetPath } from './types';
import type { ActionReducerMapBuilder, Draft, PayloadAction } from '@reduxjs/toolkit';
import { get, set } from '@mtb/utilities';

type Callbacks<State, Thunk extends GenericAsyncThunk> = {
  beforeAction?: (state: Draft<State>, action: PayloadAction<unknown>) => void;
  pending?: (state: Draft<State>, action: ReturnType<Thunk['pending']>) => void;
  fulfilled?: (state: Draft<State>, action: ReturnType<Thunk['fulfilled']>) => void;
  rejected?: (state: Draft<State>, action: ReturnType<Thunk['rejected']>) => void;
};

function createAsyncReducerCases<State, Thunk extends GenericAsyncThunk>(
  builder: ActionReducerMapBuilder<State>,
  asyncAction: Thunk,
  loadingPath: GetPath<State>,
  requestIdPath: GetPath<State>,
  errorPath: GetPath<State>,
  callbacks: Callbacks<State, Thunk> = {},
) {
  builder
    .addCase(asyncAction.pending, (state: Draft<State>, action: ReturnType<Thunk['pending']>) => {
      callbacks.beforeAction?.(state, action);
      const resolvedLoadingPath = typeof loadingPath === 'function' ? loadingPath(state, action) : loadingPath;
      const resolvedRequestIdPath = typeof requestIdPath === 'function' ? requestIdPath(state, action) : requestIdPath;
      const resolvedErrorPath = typeof errorPath === 'function' ? errorPath(state, action) : errorPath;
      if (get(state, resolvedLoadingPath) === 'idle') {
        set(state, resolvedLoadingPath, 'pending');
        set(state, resolvedRequestIdPath, action.meta.requestId);
        set(state, resolvedErrorPath, undefined);
        return callbacks.pending?.(state, action);
      }
    })
    .addCase(asyncAction.fulfilled, (state: Draft<State>, action: ReturnType<Thunk['fulfilled']>) => {
      callbacks.beforeAction?.(state, action);
      const resolvedLoadingPath = typeof loadingPath === 'function' ? loadingPath(state, action) : loadingPath;
      const resolvedRequestIdPath = typeof requestIdPath === 'function' ? requestIdPath(state, action) : requestIdPath;
      const resolvedErrorPath = typeof errorPath === 'function' ? errorPath(state, action) : errorPath;
      if (
        get(state, resolvedLoadingPath) === 'pending' &&
        get(state, resolvedRequestIdPath) === action.meta.requestId
      ) {
        set(state, resolvedLoadingPath, 'idle');
        set(state, resolvedRequestIdPath, undefined);
        set(state, resolvedErrorPath, undefined);
        return callbacks.fulfilled?.(state, action);
      }
    })
    .addCase(asyncAction.rejected, (state: Draft<State>, action: ReturnType<Thunk['rejected']>) => {
      callbacks.beforeAction?.(state, action);
      const resolvedLoadingPath = typeof loadingPath === 'function' ? loadingPath(state, action) : loadingPath;
      const resolvedRequestIdPath = typeof requestIdPath === 'function' ? requestIdPath(state, action) : requestIdPath;
      const resolvedErrorPath = typeof errorPath === 'function' ? errorPath(state, action) : errorPath;
      if (
        get(state, resolvedLoadingPath) === 'pending' &&
        get(state, resolvedRequestIdPath) === action.meta.requestId
      ) {
        set(state, resolvedLoadingPath, 'idle');
        set(state, resolvedRequestIdPath, undefined);
        set(state, resolvedErrorPath, action.error);
        return callbacks.rejected?.(state, action);
      }
    });
}

export { createAsyncReducerCases };
