import { ONE_DRIVE, STORAGE_PROVIDER_KEYS } from '../../constants';
import configStore from '../../services/config';
import { OneDriveProviderAPI } from './OneDriveProviderAPI';

const msGraphApi = new OneDriveProviderAPI({
  connectionType: STORAGE_PROVIDER_KEYS.ONE_DRIVE,
  storageKey    : ONE_DRIVE.STORAGE_KEY,
  login,
});

/** @type {import('@').LoginFunction} */
function login(state) {
  if (window.safari) {
    window._cloudExplorerAuthRedirecting = true;
  }
  // If a user has logged in more than 5 times within 10 minutes, we change the prompt to 'login'
  // This will prevent the user from running into the Microsoft login loop - more info in useCloudService storeSessionQueryParams IIFE
  const loginCounter = JSON.parse(localStorage.getItem('mslogin-counter') || '{}');
  // Because an application may actually be using the URL on the login action we should redirect back to where the user came from, to continue
  // the user journey (e.g. to the home page, opened project, etc).
  const returnUrl = window.location.href;
  window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${new URLSearchParams({
    scope: ONE_DRIVE.SCOPES,
    state: JSON.stringify({
      type       : STORAGE_PROVIDER_KEYS.ONE_DRIVE,
      action     : 'filepicker',
      redirectUri: configStore.config.cloud_storage_code_url,
      returnUrl,
      ...state,
    }),
    redirect_uri : configStore.config.cloud_storage_code_url,
    response_type: 'code',
    response_mode: 'query',
    client_id    : configStore.config.msgraph_clientid,
    ...(loginCounter.count > 5 ? { prompt: 'login' } : {}),
  })}`;
}

/** @type {import('@').LogoutFunction} */
function logout() {
  localStorage.setItem('lastLogOut', Date.now());
  msGraphApi.clearCache();
}

let msGraphShareWindow = null;

/** @type {import('@').ShowShareFileDialogFunction} */
async function showShareFileDialog(id, driveId) {
  const url = await msGraphApi._getShareUrl(id, driveId);
  const h = 540;
  const w = 480;
  const y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
  const x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
  if (
    msGraphShareWindow?.windowRef &&
    !msGraphShareWindow?.windowRef?.closed &&
    msGraphShareWindow?.id === id &&
    msGraphShareWindow?.driveId === driveId
  ) {
    msGraphShareWindow.windowRef.focus();
  } else {
    msGraphShareWindow?.windowRef && msGraphShareWindow.windowRef.close();
    const windowRef = window.open(url, '_blank', `height=${h},width=${w},top=${y},left=${x}`);
    msGraphShareWindow = {
      windowRef,
      id,
      driveId,
    };
    window.addEventListener('beforeunload', () => {
      msGraphShareWindow?.windowRef && msGraphShareWindow.windowRef.close();
    });
  }
}

/** @type {import('@').ConnectionService<"msgraph">} */
export const msGraphService = {
  type: STORAGE_PROVIDER_KEYS.ONE_DRIVE,
  api : msGraphApi,
  login,
  logout,
  showShareFileDialog,
};
