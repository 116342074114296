import { useMemo, useState, useCallback } from 'react';
import i18n from '../../../services/i18n';

/**
 * Sorts items by date.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByDate = (items, asc) =>
  items
    .sort((a, b) =>
      // reversed since we want to sort by the delta of lastAccessed
      new Date(a.lastModifiedDateTime) < new Date(b.lastModifiedDateTime) ? (asc ? 1 : -1) : asc ? -1 : 1,
    )
    .sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by name.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByName = (items, asc) =>
  (asc
    ? items.sort((a, b) => a.name.localeCompare(b.name, i18n.language, { numeric: true }))
    : items.sort((a, b) => -a.name.localeCompare(b.name, i18n.language, { numeric: true }))
  ).sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by file type.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByFileType = (folders, nonFolders, asc) => {
  const sortedNonFolders = nonFolders.sort((a, b) => (a.extension < b.extension ? (asc ? -1 : 1) : asc ? 1 : -1));
  return folders.concat(sortedNonFolders);
};

/**
 * Sorts items by owner.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByOwner = (items, asc) =>
  asc
    ? items
      .sort((a, b) =>
        a.createdBy?.name.localeCompare(b.createdBy?.name, i18n.language, {
          numeric          : true,
          ignorePunctuation: true,
        }),
      )
      .sort((a, b) => (a.folder && !b.folder ? -1 : 1))
    : items
      .sort(
        (a, b) =>
          -a.createdBy?.name.localeCompare(b.createdBy?.name, i18n.language, {
            numeric          : true,
            ignorePunctuation: true,
          }),
      )
      .sort((a, b) => (a.folder && !b.folder ? -1 : 1));

/**
 * Sorts items by checked-out status.
 * @type {import('@').StorageExplorerTableSort}
 */
const sortByCheckedOut = (folders, nonFolders, asc) => {
  const sortedNonFolders = nonFolders.sort((a, b) => (a.checkedOut && !b.checkedOut ? (asc ? -1 : 1) : asc ? 1 : -1));
  return folders.concat(sortedNonFolders);
};

/**
 * @type {import('@').UseTableSort}
 */
export const useTableSort = (items, defaultSortBy = 'lastAccessed', defaultAscOrder = true) => {
  const [sort, setSort] = useState({ sortBy: defaultSortBy, ascOrder: defaultAscOrder });

  /**
   * @type {import('@').StorageExplorerTableSetTableSort}
   */
  const handleSetTableSort = useCallback(
    ({ sortBy = 'lastAccessed', ascOrder = true } = {}) => setSort({ sortBy, ascOrder }),
    [],
  );

  /**
   * Callback to toggle the sorting order.
   * @type {import('@').StorageExplorerTableToggleTableOrder}
   */
  const handleToggleTableOrder = useCallback(() => {
    setSort(({ sortBy, ascOrder }) => ({ sortBy, ascOrder: !ascOrder }));
  }, []);

  /**
   * Memoized sorted array of items based on the sorting configuration.
   * @type {import('@').StorageExplorerTableSortedRows}
   */
  const sortedRows = useMemo(() => {
    const folders = items.filter(i => i.folder);
    const nonFolders = items.filter(i => !i.folder);

    switch (sort.sortBy) {
      case 'lastAccessed':
        return sortByDate(items.slice(), sort.ascOrder);
      case 'fileType':
        return sortByFileType(folders, nonFolders, sort.ascOrder);
      case 'owner':
        return sortByOwner(items.slice(), sort.ascOrder);
      case 'checkedOut':
        return sortByCheckedOut(folders, nonFolders, sort.ascOrder);
      default:
      case 'name':
        return sortByName(items.slice(), sort.ascOrder);
    }
  }, [items, sort.sortBy, sort.ascOrder]);

  return /** @type {import('@').StorageExplorerTableContext}*/ {
    rows            : sortedRows,
    ascOrder        : sort.ascOrder,
    sortBy          : sort.sortBy,
    toggleTableOrder: handleToggleTableOrder,
    setTableSort    : handleSetTableSort,
  };
};
