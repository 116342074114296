import type { Implementation, StorageImplementation } from './types';
import { storage } from '@mtb/ui';

const VERSION = 0;
const DEFAULT_IMPLEMENTATION: Implementation = 'localStorage';

export class Storage {
  #storage: StorageImplementation;

  constructor(implementation: Implementation = DEFAULT_IMPLEMENTATION) {
    this.#storage = storage[implementation];
  }

  getItem<T>(key: string): T | null {
    return this.#storage.getItem<T>(`${key}-${VERSION}`) || null;
  }

  setItem<T>(key: string, value: T): void {
    this.#storage.setItem<T>(`${key}-${VERSION}`, value);
  }

  removeItem(key: string): void {
    this.#storage.removeItem(`${key}-${VERSION}`);
  }

  hasItem(key: string): boolean {
    try {
      return this.getItem(key) !== null;
    } catch {}
    return false;
  }
}

export default Storage;
