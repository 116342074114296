// @ts-nocheck deepClone is a highly-optimized function that ignores a lot of best practices in favor of performance.
const DEFAULT_OPTIONS = {
  verbose: false,
};

const TypedArrayRegex = /\w+?\d{1,2}(Clamped)?Array/;

/**
 * @template T
 * @param {T} obj
 * @param {DEFAULT_OPTIONS} options
 * @returns {T}
 */
export const deepClone = (obj, options = {}) => {
  // eslint-disable-next-line no-var
  var typeOfObj, objCtor, objToString, clone, keys;
  typeOfObj = typeof obj;
  if (!options._stack) {
    options = Object.assign({}, DEFAULT_OPTIONS, options);
    options._stack = new WeakMap();
  }
  // Protect against circular objects
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
  if (obj && typeOfObj === 'object' && options._stack.has(obj)) {
    return options._stack.get(obj);
  }

  if (typeOfObj === 'function') {
    if (process.env.NODE_ENV !== 'production' && options.verbose) {
      console.warn(`deepClone cannot clone ${objCtor.name}!
      The result will include a reference to the original object.
      See the next line for the object that failed to be cloned:`);
      console.warn(obj);
    }
    return obj;
  }
  if (typeOfObj === 'symbol') {
    return Symbol(Object(Symbol.prototype.valueOf.call(obj)).description);
  }
  if (!obj || typeOfObj !== 'object') {
    return obj;
  }

  objCtor = obj.constructor;

  if (obj instanceof Date) {
    return new objCtor(obj);
  }
  if (obj instanceof RegExp) {
    clone = new objCtor(obj);
    clone.lastIndex = obj.lastIndex;
    return clone;
  }
  if (obj instanceof Map) {
    clone = new Map();
    if (!options._stack.has(obj)) {
      options._stack.set(obj, clone);
    }
    obj.forEach((value, key) => clone.set(deepClone(key, options), deepClone(value, options)));
    return clone;
  }
  if (obj instanceof Set) {
    clone = new Set();
    if (!options._stack.has(obj)) {
      options._stack.set(obj, clone);
    }
    obj.forEach(value => clone.add(deepClone(value, options)));
    return clone;
  }
  if (Array.isArray(obj)) {
    clone = [];
    if (!options._stack.has(obj)) {
      options._stack.set(obj, clone);
    }
    obj.forEach(value => clone.push(deepClone(value, options)));
    return clone;
  }

  if (objCtor.name === 'ArrayBuffer') {
    clone = new objCtor(obj.byteLength);
    new Uint8Array(clone).set(new Uint8Array(obj));
    return clone;
  }

  if (TypedArrayRegex.test(objCtor.name)) {
    return new objCtor(obj.slice(), obj.byteOffset, obj.length);
  }

  objToString = obj.toString();
  if (objToString !== '[object Object]') {
    if (objToString === 'true') {
      return Object(true);
    }
    if (objToString === 'false') {
      return Object(false);
    }
    try {
      return Object(objCtor(obj));
    } catch {
      if (process.env.NODE_ENV !== 'production' && options.verbose) {
        console.warn(`deepClone cannot clone ${objCtor.name}!
        The result will include a reference to the original object.
        See the next line for the object that failed to be cloned:`);
        console.warn(obj);
      }
      return obj;
    }
  }

  clone = Object.create(Object.getPrototypeOf(obj));
  if (!options._stack.has(obj)) {
    options._stack.set(obj, clone);
  }
  keys = [...Object.getOwnPropertyNames(obj), ...Object.getOwnPropertySymbols(obj)];
  keys.forEach(key => (clone[deepClone(key, options)] = deepClone(obj[key], options)));
  return clone;
};

export default deepClone;
