import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  FileUploadOutlinedIcon,
  useEventCallback,
} from '@mtb/ui';
import { useTranslation } from '../../../../services/i18n';

export const OpenLocal = () => {
  const [t] = useTranslation();
  const handleOnClick = useEventCallback(() => console.log('Open Local File'));

  return (
    <List>
      <ListItemButton
        onClick={handleOnClick}>
        <ListItemIcon>
          <FileUploadOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography
            noWrap
            variant="title-sm"
            weight="regular">
            {t('connection.openLocal')}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </List>
  );
};
