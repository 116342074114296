import type {
  AsyncPlaneThunkState,
  NestedPlaneState,
  Plane,
  PlaneId,
  PlaneState,
  PlaneStatusTypes,
  CreatePlaneArgsV1,
} from './types';
import type { PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { CloudStorage } from '@mtb/cloud-storage';
import { deepClone, deepMerge, waitForCondition } from '@mtb/utilities';
import { miniSerializeError } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import platformCore from '../../..';
import { PLANE_STATUS_TYPES } from '../../../../module-config';
import { PLANE_HEALTH_TYPES } from '../../../constants';
import { createAsyncThunk } from '../../redux-helpers/create-async-thunk';
import { createSlice } from '../../redux-helpers/create-slice';
import { createPlane, createPlaneSchemaV1 } from './create-plane';

const initialState: PlaneState = {};

const getPlaneByRequestId = (requestId: string, planeState: PlaneState): Plane | undefined =>
  Object.values(planeState).find(plane => plane.requestId === requestId);

const asyncActions = {
  initializePlanes: createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
    'planes/initializePlanes',
    async (_, { dispatch, getState }) => {
      const planes = Object.values(getState().planes);
      await Promise.all(planes.map(plane => dispatch(asyncActions.initializePlane(plane))));
    },
  ),
  initializePlane: createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
    'planes/initializePlane',
    async (plane: Plane, { requestId, getState, dispatch }) => {
      const storePlane = getPlaneByRequestId(requestId, getState().planes);
      if (!storePlane || storePlane.status !== PLANE_STATUS_TYPES.PENDING) {
        return;
      }
      await waitForCondition(() => platformCore.getState().user.claims.valid, 10000);
      await platformCore.Modules[storePlane.module]?.preloadRemoteModule?.();
      await dispatch(asyncActions.checkPlaneHealth(storePlane));
    },
  ),
  checkAllPlanesHealth: createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
    'planes/checkAllPlanesHealth',
    async (_: void, { dispatch, getState }) => {
      const planes = Object.values(getState().planes);
      await Promise.all(planes.map(plane => dispatch(asyncActions.checkPlaneHealth(plane))));
    },
  ),
  checkPlaneHealth: createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
    'planes/checkPlaneHealth',
    async (plane: Plane, { requestId, getState }) => {
      const storePlane = getPlaneByRequestId(requestId, getState().planes);
      if (!storePlane) {
        return;
      }

      if (requestId !== storePlane.requestId || storePlane.health === PLANE_HEALTH_TYPES.UNHEALTHY) {
        return storePlane.health;
      }

      const [moduleHealth, storageHealth] = await Promise.all([
        platformCore.Events.pulse(storePlane)
          .then(() => true)
          .catch(() => false),
        CloudStorage.healthCheckProject(storePlane.cloudStorageId).catch(() => false),
      ]);

      if (moduleHealth && storageHealth) {
        return PLANE_HEALTH_TYPES.HEALTHY;
      }

      if (storageHealth) {
        return PLANE_HEALTH_TYPES.DISCONNECTED;
      }

      const isRecoverable = await CloudStorage.getIsProjectRecoverable(storePlane.cloudStorageId);
      if (isRecoverable) {
        return PLANE_HEALTH_TYPES.RECOVERABLE;
      }

      return PLANE_HEALTH_TYPES.UNHEALTHY;
    },
  ),
  // This action expects the plane to have an up to date health status.
  // Ensure you call checkPlaneHealth before calling this action.
  restorePlane: createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
    'planes/restorePlane',
    async (planeId: PlaneId, { requestId, getState, dispatch }) => {
      const storePlane = getPlaneByRequestId(requestId, getState().planes);
      if (!storePlane) {
        return;
      }

      if (
        requestId !== storePlane.requestId ||
        storePlane.health === PLANE_HEALTH_TYPES.HEALTHY ||
        storePlane.health === PLANE_HEALTH_TYPES.UNHEALTHY
      ) {
        return storePlane.health;
      }

      // Attempt to recover the plane using Cloud Storage.
      if (storePlane.health === PLANE_HEALTH_TYPES.RECOVERABLE) {
        const cloudStorageProject = await CloudStorage.recoverProject(storePlane.cloudStorageId);
        if (!cloudStorageProject) {
          return PLANE_HEALTH_TYPES.UNHEALTHY;
        }

        dispatch(
          planes.actions.updatePlane(storePlane.id, {
            cloudStorageId: cloudStorageProject.projectId,
            name          : cloudStorageProject.displayName,
            extension     : cloudStorageProject.extension,
          }),
        );
      }
    },
  ),
};

const planes = createSlice({
  name    : 'planes',
  initialState,
  reducers: {
    createPlane,
    createPlaneV1: {
      reducer: (state: PlaneState, action: PayloadAction<Plane>) => {
        state[action.payload.id] = action.payload;
      },
      prepare: (plane: CreatePlaneArgsV1) => {
        const id = plane?.id || uuid();
        const createdDate = new Date().toISOString();
        const payload: Plane = {
          id,
          module         : plane.module,
          name           : plane.name,
          extension      : plane.extension,
          icon           : plane.icon,
          state          : plane.state || {},
          cloudStorageId : plane.cloudStorageId,
          createdDate,
          lastUpdatedDate: createdDate,
          health         : PLANE_HEALTH_TYPES.HEALTHY,
          status         : PLANE_STATUS_TYPES.LOADING,
          dirty          : plane.dirty,
        };
        const { valid, errors } = createPlaneSchemaV1.validate(payload);
        if (!valid) {
          payload.status = PLANE_STATUS_TYPES.ERROR;
          payload.error = new Error(errors.map(e => e.message).join(', '));
        }
        return { payload };
      },
    },
    setPlaneLoading: {
      reducer: (state: PlaneState, action: PayloadAction<{ planeId: PlaneId; status: 'loading' | 'idle' }>) => {
        if (state[action.payload.planeId]) {
          state[action.payload.planeId].status = action.payload.status;
        }
      },
      prepare: (planeId: PlaneId, loading: boolean) => {
        return {
          payload: {
            planeId,
            status: loading ? PLANE_STATUS_TYPES.LOADING : PLANE_STATUS_TYPES.IDLE,
          },
        };
      },
    },
    setPlaneError: {
      reducer: (
        state: PlaneState,
        action: PayloadAction<{ planeId: PlaneId; status: PlaneStatusTypes; error: SerializedError }>,
      ) => {
        if (state[action.payload.planeId]) {
          state[action.payload.planeId].status = action.payload.status;
          state[action.payload.planeId].error = action.payload.error;
        }
      },
      prepare: (planeId: PlaneId, error: Error) => {
        return {
          payload: {
            planeId,
            status: PLANE_STATUS_TYPES.ERROR,
            error : error instanceof Error ? miniSerializeError(error) : error,
          },
        };
      },
    },
    closeAllPlanes: {
      reducer: (state: PlaneState) => {
        Object.keys(state).forEach(planeId => {
          planes.reducer(state, planes.actions.closePlane(planeId));
        });
      },
      prepare: () => {
        return { payload: {} };
      },
    },
    closePlane: {
      reducer: (state: PlaneState, action: PayloadAction<{ planeId: PlaneId }>) => {
        const storePlane = state[action.payload.planeId];
        if (!storePlane) {
          return;
        }
        platformCore.Events.close(storePlane);
        CloudStorage.closeProject(storePlane.cloudStorageId);
        delete state[action.payload.planeId];
      },
      prepare: (planeId: PlaneId) => {
        return { payload: { planeId } };
      },
    },
    updatePlane: {
      reducer: (state: PlaneState, action: PayloadAction<{ planeId: PlaneId; plane: Partial<Plane> }>) => {
        if (!state[action.payload.planeId]) {
          return;
        }
        state[action.payload.planeId] = deepMerge(state[action.payload.planeId], action.payload.plane) as Plane;
      },
      prepare: (planeId: PlaneId, plane: Partial<Plane>) => {
        return { payload: { planeId, plane } };
      },
    },
    updatePlaneState: {
      reducer: (state: PlaneState, action: PayloadAction<{ planeId: PlaneId; state: NestedPlaneState }>) => {
        if (state[action.payload.planeId]) {
          state[action.payload.planeId].state = deepMerge(state[action.payload.planeId].state, action.payload.state);
        }
      },
      prepare: (planeId: PlaneId, state: NestedPlaneState) => {
        return { payload: { planeId, state } };
      },
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.checkAllPlanesHealth.pending, () => {
        /* NO_OP */
      })
      .addCase(asyncActions.checkAllPlanesHealth.fulfilled, () => {
        /* NO_OP */
      })
      .addCase(asyncActions.checkAllPlanesHealth.rejected, () => {
        /* NO_OP */
      });
    builder
      .addCase(asyncActions.checkPlaneHealth.pending, (state, action) => {
        const { id } = action.meta.arg ?? {};
        if (id && state[id]) {
          state[id].requestId = action.meta.requestId;
        }
      })
      .addCase(asyncActions.checkPlaneHealth.fulfilled, (state, action) => {
        const id = getPlaneByRequestId(action.meta.requestId, state)?.id;
        if (id && state[id]) {
          state[id].health = action.payload;
          delete state[id].requestId;
        }
      })
      .addCase(asyncActions.checkPlaneHealth.rejected, (state, action) => {
        const id = getPlaneByRequestId(action.meta.requestId, state)?.id;
        if (id && state[id]) {
          state[id].health = PLANE_HEALTH_TYPES.UNHEALTHY;
          state[id].status = PLANE_STATUS_TYPES.ERROR;
          state[id].error = action.error;
          delete state[id].requestId;
        }
      });
    builder
      .addCase(asyncActions.restorePlane.pending, (state, action) => {
        const id = action.meta.arg;
        if (id && state[id]) {
          state[id].status = PLANE_STATUS_TYPES.PENDING;
          state[id].requestId = action.meta.requestId;
        }
      })
      .addCase(asyncActions.restorePlane.fulfilled, (state, action) => {
        const id = action.meta.arg;
        if (id && state[id]) {
          // Set the health to the new health status if one is returned, or default to healthy.
          state[id].health = action.payload ?? PLANE_HEALTH_TYPES.HEALTHY;
          state[id].status = PLANE_STATUS_TYPES.LOADING;
          delete state[id].requestId;
        }
      })
      .addCase(asyncActions.restorePlane.rejected, (state, action) => {
        const id = action.meta.arg;
        if (id && state[id]) {
          state[id].health = PLANE_HEALTH_TYPES.UNHEALTHY;
          state[id].status = PLANE_STATUS_TYPES.ERROR;
          state[id].error = action.error;
          delete state[id].requestId;
        }
      });
    builder
      .addCase(asyncActions.initializePlanes.pending, () => {
        /** NO_OP */
      })
      .addCase(asyncActions.initializePlanes.fulfilled, () => {
        /** NO_OP */
      })
      .addCase(asyncActions.initializePlanes.rejected, () => {
        /** NO_OP */
      });
    builder
      .addCase(asyncActions.initializePlane.pending, (state, action) => {
        const { id } = action.meta.arg ?? {};
        if (id && state[id]) {
          state[id].health = PLANE_HEALTH_TYPES.UNKNOWN;
          state[id].status = PLANE_STATUS_TYPES.PENDING;
          state[id].requestId = action.meta.requestId;
        }
      })
      .addCase(asyncActions.initializePlane.fulfilled, (state, action) => {
        const id = getPlaneByRequestId(action.meta.requestId, state)?.id;
        if (id && state[id]) {
          state[id] = {
            ...state[id],
            status: PLANE_STATUS_TYPES.IDLE,
          };
          delete state[id].requestId;
          deepClone(state[id]);
        }
      })
      .addCase(asyncActions.initializePlane.rejected, (state, action) => {
        const id = getPlaneByRequestId(action.meta.requestId, state)?.id;
        if (id && state[id]) {
          state[id] = {
            ...state[id],
            status: PLANE_STATUS_TYPES.ERROR,
            error : action.error,
          };
          delete state[id].requestId;
          action.payload = deepClone(state[id]);
        }
      });
  },
});

const { reducer, actions, name, getInitialState } = planes;

export * from './types';

export { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES, actions, asyncActions, getInitialState, name, reducer };
