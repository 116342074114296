import config from '../config';

/**
 * Defines the maximum number of planes that can be opened at one time.
 * @deprecated This constant is no longer used, it's stored in PlaneService now.
 */
export const MAX_NUMBER_OF_PLANES = 10;

export const PLANE_HEALTH_TYPES = {
  // The planes remote module and cloud storage project are unknown, we haven't checked yet.
  UNKNOWN     : 'unknown' as const,
  // The planes remote module and cloud storage project are healthy.
  HEALTHY     : 'healthy' as const,
  // The planes remote module is not healthy, but the cloud storage project exists and is recoverable. (Storage has it loaded.)
  DISCONNECTED: 'disconnected' as const,
  // The planes remote module and cloud storage project are unhealthy but we have the project connection info. (Storage doesnt have it but we now how to load it.)
  RECOVERABLE : 'recoverable' as const,
  // The planes remote module cloud storage project are gone.
  UNHEALTHY   : 'unhealthy' as const,
};

/**
 * The module that is considered the "root" and will be the default `currentPlaneId`.
 */
export const ROOT_MODULE_KEY = config.feature_flag_msso_root ? 'MSSO' : 'PLATFORM';
