import { cookies, createFetchWrapper, FetchWrapper as DefaultFetchWrapper } from '@mtb/utilities';

const xsrfCookieName = 'MTB-XSRF';
const xsrfHeaderName = 'MTB-XSRF';

const FetchWrapper = createFetchWrapper({
  getCommonHeaders(headers) {
    return {
      ...DefaultFetchWrapper.getCommonHeaders(headers),
      [xsrfHeaderName]: cookies.getCookie(xsrfCookieName),
    };
  },
});

export default FetchWrapper;
