import type { CreateItemOptions } from './types';
import CloudStorageItem from './cloud-storage-item';
import FileStorageItem from './file-storage-item';

class StorageItemClient {
  /**
   * Create a storage item from a file or cloud storage item.
   * @param options - The options to create the storage item with.
   * @returns - The created storage item.s
   */
  createItem(options: CreateItemOptions = {}) {
    if (!!options.storageItem) {
      return new CloudStorageItem(options.storageItem);
    }

    const fileName = options.name || options.file?.name || '';
    const fileContent = options.file ? [options.file] : [];
    const fileOptions = options.file?.type ? { type: options.file.type } : {};
    const file = new File(fileContent, fileName, fileOptions);
    return new FileStorageItem(file);
  }
}

const storageItem = new StorageItemClient();
export default storageItem;
