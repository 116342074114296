import { _, str, operators } from 'ajv/dist/compile/codegen';

const keywords = /** @type {const} */ ({
  stringMaximum         : { okStr: '<=', ok: operators.LTE, fail: operators.GT },
  stringMinimum         : { okStr: '>=', ok: operators.GTE, fail: operators.LT },
  stringExclusiveMaximum: { okStr: '<', ok: operators.LT, fail: operators.GTE },
  stringExclusiveMinimum: { okStr: '>', ok: operators.GT, fail: operators.LTE },
});

/** @type {import('ajv').KeywordErrorDefinition} */
const error = {
  // @ts-ignore
  message: ({ keyword, schemaCode }) => str`must be ${keywords[keyword].okStr} ${schemaCode}`,
  // @ts-ignore
  params : ({ keyword, schemaCode }) => _`{comparison: ${keywords[keyword].okStr}, limit: ${schemaCode}}`,
};

/**
 * @returns {import('ajv').CodeKeywordDefinition}
 */
export default function stringCompare() {
  return {
    keyword   : Object.keys(keywords),
    type      : 'string',
    schemaType: 'string',
    $data     : true,
    error,
    /**
     * @param {import('ajv').KeywordCxt} cxt
     */
    code(cxt) {
      const { keyword, data, schemaCode } = cxt;
      switch (keyword) {
        case 'stringExclusiveMaximum':
        case 'stringExclusiveMinimum':
        case 'stringMaximum':
        case 'stringMinimum':
          cxt.fail$data(_`${data}.localeCompare(${schemaCode}) ${keywords[keyword].fail} 0`);
          break;
        default:
          cxt.fail$data(_`true`);
          break;
      }
    },
  };
}
