import type { TelemetryAppInsightsClient, TelemetryPlugin } from './types';
import type {
  IDiagnosticLogger,
  INotificationManager,
  IPageViewTelemetry,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import LoggerClient from '../logger';

export const FakeApplicationInsights = {
  trackEvent(event, customProperties) {
    LoggerClient.log({ event, customProperties });
  },
  trackException(exception, customProperties) {
    LoggerClient.error({ exception, customProperties });
  },
  trackTrace(trace, customProperties) {
    LoggerClient.log({ trace, customProperties });
  },
  config   : { disableTelemetry: true },
  addPlugin: (plugin: TelemetryPlugin, replaceExisting: false, doAsync: false) => {
    LoggerClient.log({ plugin, replaceExisting, doAsync });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  addTelemetryInitializer: (telemetryInitializer: (item: ITelemetryItem) => boolean | void) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function, max-len
  loadAppInsights        : (legacyMode: boolean, logger: IDiagnosticLogger, notificationManager: INotificationManager) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  trackPageView          : (pageView: IPageViewTelemetry) => {},
} as TelemetryAppInsightsClient;
