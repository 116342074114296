/**
 * Example: `(args?: {route?: string, data?: string}) => async () => (await GetSomeValueFromApi(route, data));`
 * @callback AsyncDynamicDefaultFunc
 * @param {Record<string, any>} [args]
 * @returns {() => Promise<any>}
 */

/**
 * @typedef PropertyDefaultSchema
 * @property {string} func
 * @property {Record<string, any>} args
 */

/**
 * @typedef {string | PropertyDefaultSchema | undefined} AsyncDynamicDefault
 */

/**
 * @typedef {Record<string, AsyncDynamicDefault>} DefaultSchema
 */

/**
 * @type {Record<string, AsyncDynamicDefaultFunc | undefined>}
 */
const ASYNC_DEFAULTS = {};

/**
 * @type {(() => import('ajv').KeywordDefinition) & { ASYNC_DEFAULTS: typeof ASYNC_DEFAULTS }}
 */
const getDef = Object.assign(_getDef, { ASYNC_DEFAULTS });

/**
 * @returns {import('ajv').KeywordDefinition}
 */
function _getDef() {
  return {
    keyword   : 'asyncDynamicDefaults',
    type      : 'object',
    schemaType: ['string', 'object'],
    modifying : true,
    valid     : true,
    async     : true,
    /**
     * @param {DefaultSchema} schema
     * @param {{}} _parentSchema
     * @param {import('ajv').SchemaCxt} it
     */
    compile(schema, _parentSchema, it) {
      if (!it.opts.useDefaults || it.compositeRule) {
        return async () => true;
      }
      /** @type {Record<string, () => Promise<any>>} */
      const fs = {};
      for (const key in schema) {

        fs[key] = getDefault(schema[key]);
      }
      const empty = it.opts.useDefaults === 'empty';
      return async (/** @type {Record<string, any>} */data) => {
        for (const prop in schema) {
          if (data[prop] === undefined || (empty && (data[prop] === null || data[prop] === ''))) {
            data[prop] = await fs[prop]();
          }
        }
        return true;
      };
    },
    metaSchema: {
      type                : 'object',
      additionalProperties: {
        anyOf: [
          { type: 'string' },
          {
            type                : 'object',
            additionalProperties: false,
            required            : ['func', 'args'],
            properties          : {
              func: { type: 'string' },
              args: { type: 'object' },
            },
          },
        ],
      },
    },
  };
}

/**
 * @param {AsyncDynamicDefault} d
 */
const getDefault = (d) => {
  return typeof d == 'object' ? getObjDefault(d) : getStrDefault(d);
};

/**
 * @param {PropertyDefaultSchema} param0
 */
const getObjDefault = ({ func, args }) => {
  const def = ASYNC_DEFAULTS[func];
  assertDefined(func, def);
  return def(args);
};

/**
 * @param {string} d
 */
const getStrDefault = (d = '') => {
  const def = ASYNC_DEFAULTS[d];
  assertDefined(d, def);
  return def();
};

/**
 * @param {string} name
 * @param {typeof ASYNC_DEFAULTS[keyof typeof ASYNC_DEFAULTS]} def
 * @returns {asserts def is AsyncDynamicDefaultFunc}
 */
const assertDefined = (name, def) => {
  if (!def) {
    throw new Error(`invalid "asyncDynamicDefaults" keyword property value: ${name}`);
  }
};

export default getDef;
