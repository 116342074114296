/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import type { ConfigBase } from './ConfigBase';
import type { Plane } from '../core/store/reducers/planes';
import type { CloudStoragePassthroughItem, CloudStorageProjectItem } from '@mtb/cloud-storage/types';

class Events {
  #protected: ConfigBase;

  /**
   * Creates an instance of a Events.
   * @param configBase - A pointer to the protected config base
   */
  constructor(configBase: ConfigBase) {
    this.#protected = configBase;
  }

  /**
   * Creates an instance of a Events.
   * @param configBase - A pointer to the protected config base
   */
  static Create(configBase: ConfigBase) {
    return new Events(configBase);
  }

  onPulse = (plane: Plane) => Promise.resolve();
  onCleanup = (plane: Plane) => Promise.resolve();
  onClose = (plane: Plane) => Promise.resolve();
  onFlush = (plane: Plane) => Promise.resolve();
  onOpen = (plane: Plane, fileInfo: CloudStoragePassthroughItem | CloudStorageProjectItem) => Promise.resolve();
}

export default Events;
