import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Spacer,
  Stack,
  Typography,
} from '@mtb/ui';
import { useProvider } from '../../../../hooks';
import { useTranslation } from '../../../../services/i18n';
import { useStorageExplorer } from '../../hooks';
import { StorageExplorerPickerAlert } from '../../StorageExplorerPickerAlert';

export const ProviderSaveSettings = () => {
  const [t] = useTranslation();
  const { type, disableProviderSettings } = useStorageExplorer();
  const { isDefault, toggleDefault } = useProvider(type);

  return (
    <FormControl as="fieldset">
      <Typography as="legend" variant="title-md" weight="semibold">
        {t('connection.settings')}
      </Typography>
      <Stack
        alignItems="center"
        data-testid="account-info"
        direction="row"
        flex="1 0 auto"
        flexWrap="wrap"
        gap={1}
        p={2}>
        <StorageExplorerPickerAlert />
        <FormControlLabel
          control={<Checkbox disabled={disableProviderSettings} checked={isDefault} onClick={toggleDefault} />}
          label={
            <>
              <Typography
                color="text.primary"
                variant="title-sm"
                weight="semibold"
              >
                {t('connection.saveNewFilesTo')}
              </Typography>
              <Typography
                color="text.secondary"
                variant="title-sm"
                weight="regular"
              >
                {'Foo/Bar/Baz'}
              </Typography>
            </>
          }
          value="end"
        />
        <Spacer />
        <Button disabled={disableProviderSettings} color="default" variant="contained">
          {t('connection.change')}
        </Button>
      </Stack>
    </FormControl>
  );
};

export default ProviderSaveSettings;
