import React, { useCallback, useState } from 'react';
import { useEventCallback, Dialog, DialogTitle, DialogContent, DialogActions, CloseIcon, Button, Spacer, Tooltip } from '@mtb/ui';
import { LoadingButton } from '../../components';
import { useCloudStorageProject } from '../../hooks';
import { useTranslation } from '../../services/i18n';

/**
 * @param {Object} props
 * @param {import('@').CloudStorageProjectId} props.projectId
 * @param {() => void} props.onReopenProject Callback to reopen the project in the web app
 * @param {boolean} props.open Whether the dialog is open
 * @param {(tryAgain?: boolean) => void} props.onClose Callback to close the dialog
 */
export function ReopenProjectFailedDialog({ projectId, open, onClose, onReopenProject }) {
  const [t] = useTranslation();
  const project = useCloudStorageProject(projectId);
  const [isTryingReopen, setIsTryingReopen] = useState(false);

  const handleOnClose = useCallback(() => onClose(false), [onClose]);
  const handleOnTryAgain = useEventCallback(async () => {
    setIsTryingReopen(true);
    const checkoutUser = await project.getCheckoutUser();
    if (!checkoutUser) {
      onReopenProject();
      onClose(false);
    }
    setIsTryingReopen(false);
  }, [onReopenProject, setIsTryingReopen, onClose]);

  return (
    <Dialog
      data-testid="reopen-project-failed-dialog"
      open={open}
      onClose={handleOnClose}>
      <DialogTitle>
        {t('connection.unableToOpenTheProject')}
        <Spacer />
        <Tooltip
          placement="bottom"
          title={t('connection.close')}>
          <Button
            color="default"
            icon={<CloseIcon />}
            variant="text"
            onClick={handleOnClose} />
        </Tooltip>
      </DialogTitle>
      <DialogContent>{t('connection.unableToOpenTheProjectMessage')}</DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isTryingReopen}
          variant="contained"
          onClick={handleOnTryAgain}>
          {t('connection.tryAgain')}
        </LoadingButton>
        <Button
          color="default"
          variant="contained"
          onClick={handleOnClose}>
          {t('connection.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReopenProjectFailedDialog;
