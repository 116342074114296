import toPath from './toPath';

/** Used to detect unsigned integer values. */
const reIsUint = /^(?:0|[1-9]\d*)$/;

/**
 * @param {any} value
 * @param {number} length
 */
function isIndex(value, length) {
  const type = typeof value;
  // eslint-disable-next-line no-eq-null
  length = length == null ? Number.MAX_SAFE_INTEGER : length;
  return !!length &&
    (type == 'number' ||
      (type != 'symbol' && reIsUint.test(value))) &&
        (value > -1 && value % 1 == 0 && value < length);
}

/**
 * @template Obj
 * @template {string} Path
 * @template Value
 * @param {Obj} obj
 * @param {Path} path
 * @param {Value} value
 * @returns {import('./types').Set<Obj, Path, Value>}
 */
export const set = (obj, path, value) => {
  // eslint-disable-next-line no-eq-null
  if (obj == null || (typeof obj !== 'object' && typeof obj !== 'function')) {
    // @ts-ignore
    return obj;
  }

  const pathArray = toPath(path);
  let index = -1, nested = obj;
  // eslint-disable-next-line no-eq-null
  while (nested != null && ++index < pathArray.length) {
    const key = pathArray[index];
    let newValue = value;
    if (index != pathArray.length - 1) {
      // @ts-ignore
      const objValue = nested[key];
      // @ts-ignore
      newValue = undefined;
      if (newValue === undefined) {
        newValue = typeof objValue === 'object'
          ? objValue
          // @ts-ignore
          : (isIndex(pathArray[index + 1]) ? [] : {});
      }
    }
    // @ts-ignore
    nested[key] = newValue;
    // @ts-ignore
    nested = nested[key];
  }
  // @ts-ignore
  return obj;
};

export default set;
