type IdleRequestCallback = (deadline: IdleDeadline) => void;
type IdleRequestOptions = { timeout: number };

/**
 * Wrapper for [requestIdleCallback](https://developer.mozilla.org/docs/Web/API/Window/requestIdleCallback) that
 * falls back to [`setTimeout`](https://developer.mozilla.org/en-US/docs/Web/API/setTimeout) if it is not available.
 * @param callback - The function to execute when the browser is idle.
 * @param options - Optional parameters for requestIdleCallback.
 * @returns A handle which can be used to cancel the callback.
 */
function requestIdleCallback(callback: IdleRequestCallback, options: IdleRequestOptions = { timeout: 1000 }): number {
  if ('requestIdleCallback' in (window as Window)) {
    return (window as Window).requestIdleCallback(callback, options);
  }
  return (window as Window).setTimeout(callback, options.timeout);
}

/**
 * Wrapper for [cancelIdleCallback](https://developer.mozilla.org/docs/Web/API/Window/cancelIdleCallback) that
 * falls back to [`clearTimeout`](https://developer.mozilla.org/en-US/docs/Web/API/clearTimeout) if it is not available.
 * @param handle - The handle returned by requestIdleCallback or setTimeout.
 */
function cancelIdleCallback(handle: number): void {
  if ('cancelIdleCallback' in (window as Window)) {
    return (window as Window).cancelIdleCallback(handle);
  }
  return (window as Window).clearTimeout(handle);
}

export { requestIdleCallback, cancelIdleCallback };
