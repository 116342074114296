import React, { useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, CloseIcon, Button, Spacer, Tooltip } from '@mtb/ui';
import { useTranslation } from '../../services/i18n';

export function ConfirmDialog({ title, message, open, onClose, cancelButtonText, confirmButtonText }) {
  const [t] = useTranslation();
  const handleOnConfirm = useCallback(() => onClose(true), [onClose]);
  const handleOnCancel = useCallback(() => onClose(false), [onClose]);

  return (
    <Dialog
      data-testid="confirm-dialog"
      open={open}
      onClose={handleOnCancel}>
      <DialogTitle>
        {title}
        <Spacer />
        <Tooltip
          placement="bottom"
          title={t('connection.close')}>
          <Button
            color="default"
            icon={<CloseIcon />}
            variant="text"
            onClick={handleOnCancel} />
        </Tooltip>
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          color="default"
          variant="text"
          onClick={handleOnCancel}>
          {cancelButtonText ?? t('shared.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={handleOnConfirm}>
          {confirmButtonText ?? t('shared.OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
