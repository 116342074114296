import type { ConfigBase } from './ConfigBase';
import type { LocaleSettings, UserSettings } from '../core/store/reducers/user/types';

/**
 * Represents the settings of a platform module.
 */
class Settings {
  #protected: ConfigBase;

  /**
   * Creates an instance of a Settings.
   * @param configBase - A pointer to the protected config base
   */
  constructor(configBase: ConfigBase) {
    this.#protected = configBase;
  }

  /**
   * Creates an instance of a Settings.
   * @param configBase - A pointer to the protected config base
   */
  static Create(configBase: ConfigBase) {
    return new Settings(configBase);
  }

  /**
   * Get user settings.
   */
  get settings(): UserSettings {
    return this.#protected.core.getState().user.settings;
  }

  /**
   * Get user locale.
   */
  get locale(): LocaleSettings {
    return this.settings.locale;
  }

  /**
   * Listens for changes to the locale.
   * @param handler - The event handler for locale changes.
   * @returns The unsubscribe function.
   */
  onLocaleChange = (handler: (locale: LocaleSettings) => void): (() => void) => {
    /**
     * Subscribe to locale changes and invoke the handler.
     * @returns The unsubscribe function.
     */
    return this.#protected.core.subscribe(() => {
      handler(this.locale);
    });
  };
}

export default Settings;
