import Ajv from 'ajv';
import AjvFormats from 'ajv-formats';
import AjvKeywords from 'ajv-keywords';
import { STRING_FORMATS } from './constants';
import dateFormats from './formats/dates';
import mathExpressionDefinition from './formats/mathExpression';
import referenceRegexDefinition from './formats/referenceRegex';
import AsyncDynamicDefaults from './keywords/asyncDynamicDefaults';
import DynamicDefaults from './keywords/dynamicDefaults';
import strictNumeric from './keywords/strictNumeric';
import stringCompare from './keywords/stringCompare';
import stringIncludes from './keywords/stringIncludes';

/**
 * @type {Ajv & { DynamicDefaults: typeof DynamicDefaults, AsyncDynamicDefaults: typeof AsyncDynamicDefaults }}
 */
// @ts-ignore
export const ajv = new Ajv({
  allErrors    : true,
  verbose      : true,
  strict       : true,
  strictTuples : false,
  strictNumbers: false,
  messages     : true,
  coerceTypes  : true,
  useDefaults  : 'empty',
  unicodeRegExp: false,
});

ajv.DynamicDefaults = DynamicDefaults;
ajv.AsyncDynamicDefaults = AsyncDynamicDefaults;

ajv.addKeyword(ajv.DynamicDefaults());
ajv.addKeyword(ajv.AsyncDynamicDefaults());
ajv.addKeyword(stringIncludes());
ajv.addKeyword(stringCompare());
ajv.addKeyword(strictNumeric());

const customStringFormats = [
  STRING_FORMATS.MATH_EXPRESSION,
  STRING_FORMATS.REFERENCE_REGEX,
  STRING_FORMATS.DATE,
  STRING_FORMATS.DATETIME,
  STRING_FORMATS.TIME,
];

// @ts-ignore
AjvFormats(ajv, {
  // @ts-ignore
  formats : Object.values(STRING_FORMATS).filter(format => !customStringFormats.includes(format)),
  keywords: true,
  mode    : 'full',
});

AjvKeywords(ajv, [
  'typeof',
  'range',
  'exclusiveRange',
  'regexp',
  'transform',
  'uniqueItemProperties',
  'allRequired',
  'anyRequired',
  'oneRequired',
  'patternRequired',
  'prohibited',
  'deepProperties',
  'deepRequired',
]);

dateFormats.forEach(([format, definition]) => ajv.addFormat(format, definition));
ajv.addFormat(STRING_FORMATS.MATH_EXPRESSION, mathExpressionDefinition);
ajv.addFormat(STRING_FORMATS.REFERENCE_REGEX, referenceRegexDefinition);

export default ajv;
